import { flow, pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { Eq } from "fp-ts/lib/Eq";
import {
  isSomething,
  groupBy,
  getIsEqualProps,
  getAllIsTrue,
  arraysAreEqual,
  getDistinctWhere,
  groupByIsEqual,
  removeOptionals,
  getDistinct,
  getIdsToRemoveIncludingChildren,
  upsertItem,
  getTextByIdForLangObject,
  tryGetById,
  sortListBy,
  tryGetThingFromRecord,
  getNumberRounded,
  getThingOrNullFromRecord,
} from "./CommonHelperFunctions";
import {
  GetRolesDto,
  RobotStepperModule,
  RecruitmentListItem,
  LangObject,
  SourcingRobotRequirement,
  SourcingRobotRequirementsObject,
  StepperModuleHint,
  SubRoleStepValue,
  GetRoleWithSkillsAndTasksDto,
  ThingToLoad,
  OpennessSettings,
  WantMoreCandidatesTip,
  SearchTagResultDto,
  SkillGroupObject,
  TaskGroupObject,
  ChipListBaseItem,
} from "~/models/types";
import { JobbOfferBaseInfo } from "~/models/JobbOfferBaseInfo";
import { WorkFromHome } from "~/models/WorkFromHome";
import { getSearchTagCacheKey } from "~/services/jobOfferService";

export type StaffIsRequiredValue =
  | { type: "notRequired"; moduleId: string }
  | { type: "notAnswered" }
  | { type: "required"; moduleId: string };

export type TaskGroupReq = {
  taskIds: string[];
  taskGroupIds: string[];
  isRequired: boolean;
};

export type SkillGroupReq = {
  skillIds: string[];
  skillGroupIds: string[];
  isRequired: boolean;
};
export type TaskRequired = {
  taskId: string;
  taskName: string;
  moduleId: string;
  module: RobotStepperModule;
};

export type RobotRequirementProfileDto = {
  minimunYearsOfEducation: number;
  roleSpecificityRoleIds: string[];
  requiredLanguageIds: string[];
  salaryObject: {
    baseSalary: number;
    includeBonus: boolean;
    bonus: number | null;
  };
  yearsExperienceRange: {
    min: number;
    max: number | null;
  };
  selectedSubRoles: { roleId: string; subRoleId: string; moduleId: string }[];
  selectedSkillGroups: SkillGroupReq[];
  selectedPreferredTaskIds: string[];
  selectedPreferredSkillIds: string[];
  selectedRequiredTasksGroups: TaskGroupReq[];
  preferStaffResponsibility: boolean;
  requireStaffResponsibility: boolean | null;
  searchTagCodes: string[];
};

const getActiveModules = (allRoles: GetRolesDto[]) => (
  selectedRoleIds: string[]
) => pipe(selectedRoleIds, A.flatMap(getActiveModulesForRoleId(allRoles)));

export const getSubRoleModulesForRole = (v: {
  roleId: string;
  allRoles: GetRolesDto[];
}): (RobotStepperModule & {
  subRoleIds: string[];
  moduleId: string;
  subTitle: Record<string, string>;
  roleId: string;
})[] =>
  pipe(
    v.roleId,
    getActiveModulesForRoleId(v.allRoles),
    A.map(getSubRoleModule(v.roleId)),
    A.compact
  );

const getSubRoleModule = (roleId: string) => (
  v: RobotStepperModule
): O.Option<
  RobotStepperModule & {
    subRoleIds: string[];
    moduleId: string;
    subTitle: Record<string, string>;
    roleId: string;
  }
> => {
  switch (v.moduleType) {
    case "SubRolePickMultiple":
    case "SubRolePickSingle":
      return O.some({ ...v, roleId });
    case "MultipleSkillGroupsRequired":
    case "SkillGroupRequired":
    case "SkillsPickMultipleRequired":
    case "SkillsPickMultipleInVisualRequired":
    case "SkillsPickMultipleInVisualRequiredV2":
    case "MultipleSkillGroups":
    case "SkillGroup":
    case "SkillsPickMultiple":
    case "RoleSpecificity":
    case "Salary":
    case "StaffResponsibility":
    case "TasksPickMultiple":
    case "TasksPickMultipleInVisual":
    case "TasksPickMultipleInVisualRequired":
    case "TasksPickMultipleRequired":
    case "DomainGroupRequired":
    case "Education":
    case "Exit":
    case "ExperienceYears":
    case "IndustryGroupRequired":
    case "Language":
      return O.none;
  }
};

export const getUnansweredSkillGroups = (
  selectedSkillGroups: SkillGroupReq[],
  allSkillGroups: SkillGroupObject[],
  preferredSkillIds: string[],
  skillGroupGroups: { skillGroupIds: string[] }[],
  lang: string
) =>
  getSkillGroupsFromPreferredSkillIds(
    preferredSkillIds,
    lang,
    allSkillGroups,
    skillGroupGroups
  ).filter(x => !selectedSkillGroups.some(isEqualToSkillGroup(x)));

export const getUnansweredTaskGroups = (
  selectedTaskGroups: TaskGroupReq[],
  allTaskGroups: TaskGroupObject[],
  preferredTaskIds: string[],
  taskGroupGroups: { taskGroupIds: string[] }[],
  lang: string
) =>
  getTaskGroupsFromPreferredTaskIds(
    preferredTaskIds,
    lang,
    allTaskGroups,
    taskGroupGroups
  ).filter(x => !selectedTaskGroups.some(isEqualToTaskGroup(x)));

export const getSkillGroupIsSelected = <T extends { skillIds: string[] }>(
  selectedSubset: T[]
) => (skillGroup: T) =>
  selectedSubset.some(ss => isEqualSkillGroups(ss, skillGroup));

export const getSkillGroupIsSemiSelected = <T extends { skillIds: string[] }>(
  selectedSubset: T[]
) => (skillGroup: T) =>
  selectedSubset.some(ss =>
    ss.skillIds.every(x => skillGroup.skillIds.includes(x))
  );

export const getSelectedGroups = <T extends { skillIds: string[] }>(
  allGroups: T[]
) => (selectedSubSets: T[]): T[] =>
  allGroups.filter(getSkillGroupIsSelected(selectedSubSets));

export const isEqualToSkillGroup = (a: { skillIds: string[] }) => (b: {
  skillIds: string[];
}): boolean => {
  if (a.skillIds.length !== b.skillIds.length) {
    return false;
  }

  if (a.skillIds.every(x => b.skillIds.includes(x))) {
    return true;
  }

  return false;
};

export const isEqualToTaskGroup = (a: { taskIds: string[] }) => (b: {
  taskIds: string[];
}): boolean => {
  if (a.taskIds.length !== b.taskIds.length) {
    return false;
  }

  if (a.taskIds.every(x => b.taskIds.includes(x))) {
    return true;
  }

  return false;
};

const isActiveModule = (m: RobotStepperModule) => !m.isDepricated;

export const filterByModuleType = <
  T extends { moduleType: string },
  U extends T["moduleType"]
>(
  moduleType: U
) => (arr: T[]) => {
  type R = Extract<T, { moduleType: U }>;
  return arr.filter((item): item is R => item.moduleType === moduleType);
};

const filterByReqType = <
  T extends { requirementType: string },
  U extends T["requirementType"]
>(
  requirementType: U
) => (arr: T[]) => {
  type R = Extract<T, { requirementType: U }>;
  return arr.filter(
    (item): item is R => item.requirementType === requirementType
  );
};
const findFirstByReqType = <
  T extends { requirementType: string },
  U extends T["requirementType"]
>(
  requirementType: U
) => (arr: T[]) => {
  type R = Extract<T, { requirementType: U }>;
  return pipe(
    arr,
    A.findFirst((item): item is R => item.requirementType === requirementType)
  );
};

export const getUnansweredModules = (reqs: { moduleId: string }[]) => (
  modules: (RobotStepperModule & { moduleId?: string })[]
): RobotStepperModule[] =>
  modules.filter(m => {
    const isAnswered = reqs.some(x => x.moduleId === m.moduleId);
    return !isAnswered;
  });

const upsertStringToArray = (item: string) =>
  upsertItem<string>((a, b) => a === b)(item);

const getNewStringArray = (oldArr: string[], item: string, add: boolean) =>
  pipe(oldArr, add ? upsertStringToArray(item) : A.filter(x => x !== item));

export const handleRoleSpecificityItemSelected = (
  roleIds: string[],
  item: ChipListBaseItem
): string[] => getNewStringArray(roleIds, item.key, !item.isSelected);

export const getRoleSpecificityRolesAsChipListItems = (
  modules: (RobotStepperModule & { moduleType: "RoleSpecificity" })[],
  roleIds: string[],
  allRoles: GetRolesDto[],
  baseInfoRoleId: string
): ChipListBaseItem[] =>
  pipe(
    [baseInfoRoleId, ...modules.flatMap(x => x.roleIds), ...roleIds],
    getDistinct,
    A.map(tryGetById(allRoles)),
    A.compact,
    A.map(x => {
      return {
        isSelected: roleIds.includes(x.id),
        isSemiSelected: false,
        key: x.id,
        text: x.text,
      };
    })
  );

export const getRoleSpecificityModulesFromSelectedSuRoles = (v: {
  allRoles: GetRolesDto[];
  roleId: string | null;
  selectedSubRoles: {
    subRoleId: string;
    moduleId: string;
    roleId: string;
  }[];
}) =>
  pipe(
    O.fromNullable(v.roleId),
    O.fold(
      () => [],
      roleId =>
        pipe(
          v.selectedSubRoles,
          A.map(x => x.subRoleId),
          A.append(roleId),
          getRoleSpecificityModulesFromRoleIds(v.allRoles)
        )
    )
  );

const getRoleSpecificityModulesFromRoleIds = (allRoles: GetRolesDto[]) => (
  roleIds: string[]
) =>
  pipe(
    roleIds,
    getActiveModules(allRoles),
    filterByModuleType("RoleSpecificity")
  );

export const getRoleSpecificityModulesFromRequirements = (v: {
  allRoles: GetRolesDto[];
  requiements: SourcingRobotRequirement[];
  jobRoleId: string;
}) =>
  pipe(
    v.requiements,
    getSelectedRoleIdsFromRequirements,
    A.append(v.jobRoleId),
    getRoleSpecificityModulesFromRoleIds(v.allRoles)
  );

export const getSelectedRoleIdsFromRequirements = (
  requirements: SourcingRobotRequirement[]
) =>
  pipe(
    requirements,
    filterByReqType("SubRole"),
    A.flatMap(x => x.subRoleIds),
    getDistinct
  );

const sourcingRobotRequirementsAreEqual = (
  a: SourcingRobotRequirement,
  b: SourcingRobotRequirement
): boolean => {
  switch (a.requirementType) {
    case "SkillGroupRequiredNew": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.isRequired),
        () => arraysAreEqual(aCopy.skillIds, bCopy.skillIds, (a, b) => a === b),
      ]);
    }
    case "DomainGroupRequired":
    case "IndustryGroupRequired": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.requirementType),
        compareProps(x => x.moduleId),
      ]);
    }
    case "Education": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([compareProps(x => x.minimumYearsOfEducation)]);
    }
    case "Exit": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([
        compareProps(x => x.feedbackText),
        compareProps(x => x.moduleId),
      ]);
    }
    case "ExperienceYears": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([compareProps(x => x.max), compareProps(x => x.min)]);
    }

    case "Language": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([
        compareProps(x => JSON.stringify(x.languageIdsWithRequired)),
      ]);
    }
    case "RoleSpecificity": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.moduleId),
        compareProps(x => x.roleIsSpecific),
      ]);
    }
    case "RoleSpecificity2": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      return getAllIsTrue([
        () => arraysAreEqual(aCopy.roleIds, bCopy.roleIds, (a, b) => a === b),
      ]);
    }
    case "Salary": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.bonus),
        compareProps(x => x.salary),
      ]);
    }
    case "SkillsPreferred": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      return getAllIsTrue([
        () => arraysAreEqual(aCopy.skillIds, bCopy.skillIds, (a, b) => a === b),
      ]);
    }
    case "TasksPreferred": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      return getAllIsTrue([
        () => arraysAreEqual(aCopy.taskIds, bCopy.taskIds, (a, b) => a === b),
      ]);
    }
    case "StaffResponsibility": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([compareProps(x => x.staffIsRequired)]);
    }
    case "SubRole": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.moduleId),
        compareProps(x => x.roleId),
        () =>
          arraysAreEqual(aCopy.subRoleIds, bCopy.subRoleIds, (a, b) => a === b),
      ]);
    }
    case "TaskGroupRequired": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.isRequired),
        () => arraysAreEqual(aCopy.taskIds, bCopy.taskIds, (a, b) => a === b),
      ]);
    }
    case "SearchTags": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      return getAllIsTrue([
        () =>
          arraysAreEqual(
            aCopy.searchTagCodes,
            bCopy.searchTagCodes,
            (a, b) => a === b
          ),
      ]);
    }
    case "StaffResponsibilityPreferred": {
      return b.requirementType === a.requirementType;
    }
  }
};

export const getDefaultRequirementsObject = (v: {
  recruitmentId: string;
  lang: string;
  skillGroupsObjects: SkillGroupObject[];
  taskGroupObjects: TaskGroupObject[];
}): SourcingRobotRequirementsObject => {
  const defaultReqProfile = getRobotRequirementProfile(
    [],
    v.skillGroupsObjects,
    v.taskGroupObjects,
    v.lang
  );

  const defaultRequirements = getRobotRequirements(defaultReqProfile);

  return {
    isManual: false,
    isStopped: true,
    isBreaked: false,
    recruitmentId: v.recruitmentId,
    requirements: defaultRequirements,
    shouldBeRunInitially: false,
  };
};

export const getIsEqualRobots = (
  oldRobot: SourcingRobotRequirementsObject | null,
  newRobot: SourcingRobotRequirementsObject
): boolean => {
  if (!oldRobot) {
    return false;
  }
  const compareProps = getIsEqualProps(oldRobot)(newRobot);

  return getAllIsTrue([
    compareProps(x => x.isManual),
    compareProps(x => x.isStopped),
    compareProps(x => x.isBreaked),
    compareProps(x => x.recruitmentId),
    compareProps(x => x.shouldBeRunInitially),
    () =>
      arraysAreEqual(
        oldRobot.requirements
          .filter(removeEmptySearchTagsReqs)
          .filter(removeEmptyRoleSpecificityReqs)
          .filter(removeDefaultYearsExperience)
          .filter(removeDefaultEducation),
        newRobot.requirements
          .filter(removeEmptySearchTagsReqs)
          .filter(removeEmptyRoleSpecificityReqs)
          .filter(removeDefaultYearsExperience)
          .filter(removeDefaultEducation),
        sourcingRobotRequirementsAreEqual
      ),
  ]);
};

const removeEmptySearchTagsReqs = (r: SourcingRobotRequirement): boolean =>
  r.requirementType === "SearchTags" ? !!r.searchTagCodes.length : true;

const removeEmptyRoleSpecificityReqs = (r: SourcingRobotRequirement): boolean =>
  r.requirementType === "RoleSpecificity2" ? !!r.roleIds.length : true;

const removeDefaultEducation = (r: SourcingRobotRequirement): boolean =>
  r.requirementType === "Education" ? r.minimumYearsOfEducation > 0 : true;

const removeDefaultYearsExperience = (r: SourcingRobotRequirement): boolean =>
  r.requirementType === "ExperienceYears"
    ? !(r.max === null && r.min === 0)
    : true;

export const getRobotStatus = (v: {
  recListItem?: RecruitmentListItem | null;
}): "Active" | "Paused" | "NotExisting" | "Breaked" => {
  if (!v.recListItem) {
    return "NotExisting";
  }

  if (!v.recListItem.startedDate) {
    return "NotExisting";
  }

  if (v.recListItem.robotIsBreaked) {
    return "Breaked";
  }

  const boolOrNullArr = [
    v.recListItem.newRobotIsActive,
    v.recListItem.manualRobotIsActive,
    v.recListItem.oldRobotIsActive,
  ];

  if (boolOrNullArr.every(x => x === null)) {
    return "NotExisting";
  }

  return boolOrNullArr.includes(true) ? "Active" : "Paused";
};

const getSubRoleRequirementsFromSelectedSubRoles = (
  selectedSubRoles: { roleId: string; subRoleId: string; moduleId: string }[]
) => {
  return groupBy(selectedSubRoles, x => x.roleId).map(srGroup => {
    const req: SourcingRobotRequirement = {
      requirementType: "SubRole",
      roleId: srGroup[0]!.roleId,
      subRoleIds: srGroup.map(x => x.subRoleId),
      moduleId: srGroup[0]!.moduleId,
    };
    return req;
  });
};

export const getRobotIsPublished = (v: {
  allRoles: GetRolesDto[];
  allRecruitments: RecruitmentListItem[];
  recruitmentId: string;
}): boolean =>
  pipe(
    v.recruitmentId,
    tryGetById(v.allRecruitments),
    O.chain(x => O.fromNullable(x.roleId)),
    O.chain(tryGetById(v.allRoles)),
    O.map(x => x.robotIsPublished),
    O.getOrElseW(() => false)
  );

const hasModuleId = <T>(item: T): item is T & { moduleId: string } =>
  !!(item as any).moduleId;

export const getRobotRequirementProfile = (
  reqs: SourcingRobotRequirement[],
  skillGroupsObjects: SkillGroupObject[],
  taskGroupObjects: TaskGroupObject[],
  lang: string
): RobotRequirementProfileDto => {
  const roleSpecificityRequirements = pipe(
    reqs,
    filterByReqType("RoleSpecificity2")
  );

  const result: RobotRequirementProfileDto = {
    minimunYearsOfEducation: pipe(
      reqs,
      findFirstByReqType("Education"),
      O.map(x => x.minimumYearsOfEducation),
      O.getOrElseW(() => 0)
    ),
    roleSpecificityRoleIds: pipe(
      roleSpecificityRequirements.flatMap(x => x.roleIds),
      getDistinct
    ),
    requiredLanguageIds: pipe(
      reqs,
      findFirstByReqType("Language"),
      O.map(x => Object.keys(x.languageIdsWithRequired)),
      O.getOrElseW(() => [])
    ),
    salaryObject: pipe(
      reqs,
      findFirstByReqType("Salary"),
      O.map(x => ({
        baseSalary: x.salary,
        includeBonus: x.bonus > 0,
        bonus: x.bonus === 0 ? null : x.bonus,
      })),
      O.getOrElseW(() => ({
        baseSalary: 0,
        includeBonus: false,
        bonus: 0,
      }))
    ),
    yearsExperienceRange: pipe(
      reqs,
      findFirstByReqType("ExperienceYears"),
      O.getOrElseW(() => ({
        min: 0,
        max: null,
      }))
    ),
    selectedSubRoles: pipe(
      reqs,
      filterByReqType("SubRole"),
      A.flatMap(r =>
        r.subRoleIds.map(subRoleId => ({
          roleId: r.roleId,
          subRoleId,
          moduleId: r.moduleId,
        }))
      )
    ),
    selectedSkillGroups: [
      ...pipe(
        reqs,
        filterByReqType("SkillGroupRequiredNew"),
        A.map(x => {
          const skillGroups = skillGroupsObjects.filter(sg =>
            x.skillGroupIds.includes(sg.id)
          );

          const result: SkillGroupReq = {
            skillIds: x.skillIds,
            skillGroupIds: skillGroups.map(x => x.id),
            isRequired: x.isRequired,
          };

          return result;
        })
      ),
    ],
    selectedRequiredTasksGroups: [
      ...pipe(
        reqs,
        filterByReqType("TaskGroupRequired"),
        A.map(x => {
          const taskGroups = taskGroupObjects.filter(sg =>
            x.taskGroupIds.includes(sg.id)
          );

          const result = {
            taskIds: x.taskIds,
            groupName:
              taskGroups.length === 1
                ? taskGroups[0]!.name[lang] ?? null
                : null,
            taskGroupIds: taskGroups.map(x => x.id),
            isRequired: x.isRequired,
          };

          return result;
        })
      ),
    ],
    requireStaffResponsibility: pipe(
      reqs,
      filterByReqType("StaffResponsibility"),
      A.head,
      O.map(x => x.staffIsRequired),
      O.getOrElseW(() => null)
    ),
    preferStaffResponsibility: pipe(
      reqs,
      filterByReqType("StaffResponsibilityPreferred"),
      A.head,
      O.map(_ => true),
      O.getOrElseW(() => false)
    ),
    searchTagCodes: pipe(
      reqs,
      findFirstByReqType("SearchTags"),
      O.map(x => x.searchTagCodes),
      O.getOrElseW(() => [])
    ),
    selectedPreferredTaskIds: pipe(
      reqs,
      findFirstByReqType("TasksPreferred"),
      O.map(x => x.taskIds),
      O.getOrElseW(() => [])
    ),
    selectedPreferredSkillIds: pipe(
      reqs,
      findFirstByReqType("SkillsPreferred"),
      O.map(x => x.skillIds),
      O.getOrElseW(() => [])
    ),
  };

  return result;
};

export const isActiveRequirement = (allModules: RobotStepperModule[]) => (
  req: SourcingRobotRequirement
): boolean => {
  if (!hasModuleId(req)) {
    return true;
  }

  const moduleIsDepricated = pipe(
    allModules,
    A.filter(hasModuleId),
    A.findFirst(x => x.moduleId === req.moduleId),
    O.chain(x => O.fromNullable(x.isDepricated)),
    O.getOrElseW(() => false)
  );

  return !moduleIsDepricated;
};

export const getRobotRequirements = (
  reqProfile: RobotRequirementProfileDto
): SourcingRobotRequirement[] => {
  const laguageReq: SourcingRobotRequirement | null = !reqProfile
    .requiredLanguageIds.length
    ? null
    : {
        requirementType: "Language",
        languageIdsWithRequired: reqProfile.requiredLanguageIds.reduce(
          (acc: Record<string, boolean>, langId) => {
            return {
              ...acc,
              [langId]: true,
            };
          },
          {}
        ),
      };

  const salaryReq: SourcingRobotRequirement = {
    requirementType: "Salary",
    bonus: reqProfile.salaryObject.includeBonus
      ? reqProfile.salaryObject.bonus ?? 0
      : 0,
    salary: reqProfile.salaryObject.baseSalary,
  };

  return [
    reqProfile.minimunYearsOfEducation > 0
      ? ({
          requirementType: "Education",
          minimumYearsOfEducation: reqProfile.minimunYearsOfEducation,
        } as const)
      : null,
    ...(laguageReq ? [laguageReq] : []),
    salaryReq,
    reqProfile.yearsExperienceRange.max === null &&
    reqProfile.yearsExperienceRange.min === 0
      ? null
      : ({
          requirementType: "ExperienceYears",
          max: reqProfile.yearsExperienceRange.max,
          min: reqProfile.yearsExperienceRange.min,
        } as const),
    ...getSubRoleRequirementsFromSelectedSubRoles(reqProfile.selectedSubRoles),
    ...reqProfile.selectedSkillGroups.map(
      sg =>
        ({
          requirementType: "SkillGroupRequiredNew",
          skillGroupIds: sg.skillGroupIds,
          isRequired: sg.isRequired,
          skillIds: sg.skillIds,
        } as const)
    ),
    ...reqProfile.selectedRequiredTasksGroups.map(
      g =>
        ({
          requirementType: "TaskGroupRequired",
          isRequired: g.isRequired,
          taskGroupIds: g.taskGroupIds,
          taskIds: g.taskIds,
        } as const)
    ),
    reqProfile.roleSpecificityRoleIds.length
      ? ({
          requirementType: "RoleSpecificity2",
          roleIds: reqProfile.roleSpecificityRoleIds,
        } as const)
      : null,
    reqProfile.requireStaffResponsibility !== null
      ? ({
          requirementType: "StaffResponsibility",
          staffIsRequired: reqProfile.requireStaffResponsibility,
        } as const)
      : null,
    reqProfile.preferStaffResponsibility
      ? ({
          requirementType: "StaffResponsibilityPreferred",
        } as const)
      : null,
    reqProfile.searchTagCodes.length
      ? ({
          requirementType: "SearchTags",
          searchTagCodes: reqProfile.searchTagCodes,
        } as const)
      : null,
    reqProfile.selectedPreferredTaskIds.length
      ? ({
          requirementType: "TasksPreferred",
          taskIds: reqProfile.selectedPreferredTaskIds,
        } as const)
      : null,
    reqProfile.selectedPreferredSkillIds.length
      ? ({
          requirementType: "SkillsPreferred",
          skillIds: reqProfile.selectedPreferredSkillIds,
        } as const)
      : null,
  ].filter(isSomething);
};

export const isEqualSkillGroups = <T extends { skillIds: string[] }>(
  a: T,
  b: T
): boolean => {
  return isEqualToSkillGroup(a)(b);
};
export const isEqualTaskGroups = <T extends { taskIds: string[] }>(
  a: T,
  b: T
): boolean => {
  return isEqualToTaskGroup(a)(b);
};

export const upsertTaskGroup = upsertItem(isEqualTaskGroups);

export const upsertSkillGroup = upsertItem(isEqualSkillGroups);

const getSkillGroupsFromSkill = <T extends { skillIds: string[] }>(
  allSkillGroups: T[]
) => (skillId: string) => {
  const matchingSkillGroups = allSkillGroups.filter(sg =>
    sg.skillIds.includes(skillId)
  );

  const result = pipe(
    matchingSkillGroups,
    getSkillGroupClusters,
    A.map(
      flow(
        sortListBy([{ sortBy: x => x.skillIds.length, desc: false }]),
        A.head
      )
    ),
    A.compact
  );

  return result;
};

export const getSkillGroupClusters = <T extends { skillIds: string[] }>(
  skillGroups: T[]
): T[][] => {
  // make groups of skill groups that have the same skills or are subsets of each other
  const result: T[][] = [];
  const sorted = pipe(
    skillGroups,
    sortListBy([
      {
        sortBy: x => x.skillIds.length,
        desc: true,
      },
    ])
  );

  for (const sg of sorted) {
    const foundGroups = result.filter(
      x =>
        x.some(y => sg.skillIds.every(z => y.skillIds.includes(z))) ||
        sg.skillIds.every(z => x.some(y => y.skillIds.includes(z)))
    );

    if (foundGroups.length) {
      foundGroups.forEach(x => x.push(sg));
    } else {
      result.push([sg]);
    }
  }

  return result;
};

export const getBestGroupsFromPreferredIds = <T extends { skillIds: string[] }>(
  groups: T[],
  ids: string[]
): T[] =>
  pipe(
    ids,
    A.flatMap(getSkillGroupsFromSkill(groups)),
    groupByIsEqual(isEqualSkillGroups),
    A.map(A.head),
    A.compact
  );

export const getSkillGroupsFromPreferredSkillIds = (
  preferredSkillIds: string[],
  lang: string,
  allSkillGroups: SkillGroupObject[],
  skillGroupGroups: { skillGroupIds: string[] }[]
) =>
  pipe(
    getBestGroupsFromPreferredIds(allSkillGroups, preferredSkillIds).map(
      sg => ({
        groupName: sg.name?.[lang] ?? null,
        itemIds: sg.skillIds,
        groupId: sg.id,
      })
    ),
    groupByGroupGroups(
      skillGroupGroups.map(x => ({ groupIds: x.skillGroupIds }))
    )
  ).map(x => ({
    skillGroupIds: x.groupIds,
    skillIds: x.itemIds,
  }));

export const groupByGroupGroups = <
  T extends { groupId: string; itemIds: string[] }
>(
  groupGroups: {
    groupIds: string[];
  }[]
) => (groups: T[]): (Omit<T, "groupId"> & { groupIds: string[] })[] => {
  const usedGroups = groupGroups.flatMap(g => {
    const matchingGroups = groups.filter(x => g.groupIds.includes(x.groupId));
    if (matchingGroups.length > 1) {
      return [
        {
          ...matchingGroups[0]!,
          groupIds: matchingGroups.map(x => x.groupId),
          itemIds: matchingGroups.flatMap(x => x.itemIds),
        },
      ];
    }
    return [];
  });

  return groups
    .filter(x => !usedGroups.some(y => y.groupIds.includes(x.groupId)))
    .map(x => ({
      ...x,
      groupIds: [x.groupId],
    }))
    .concat(usedGroups);
};

export type SkillGroupToAnswer = ReturnType<
  typeof getSkillGroupsFromPreferredSkillIds
>[0];

export const getIdForSkillGroupToAnswer = (x: SkillGroupToAnswer) =>
  x.skillIds.join(",");

export const getTaskGroupsFromPreferredTaskIds = (
  preferredTaskIds: string[],
  lang: string,
  allTaskGroups: TaskGroupObject[],
  taskGroupGroups: { taskGroupIds: string[] }[]
) =>
  pipe(
    getBestGroupsFromPreferredIds(
      allTaskGroups.map(x => ({
        ...x,
        skillIds: x.taskIds,
      })),
      preferredTaskIds
    ).map(g => ({
      groupName: g.name?.[lang] ?? null,
      itemIds: g.taskIds,
      groupId: g.id,
    })),
    groupByGroupGroups(taskGroupGroups.map(x => ({ groupIds: x.taskGroupIds })))
  ).map(x => ({
    taskGroupIds: x.groupIds,
    groupName: x.groupIds.length === 1 ? x.groupName : null,
    taskIds: x.itemIds,
  }));

export type TaskGroupToAnswer = ReturnType<
  typeof getTaskGroupsFromPreferredTaskIds
>[0];

export const getIdForTaskGroupToAnswer = (x: TaskGroupToAnswer) =>
  x.taskIds.join(",");

export const getSkillGroupIsRequired = (
  g: { skillIds: string[] },
  selectedSkillGroups: SkillGroupReq[]
) => selectedSkillGroups.find(isEqualToSkillGroup(g))?.isRequired ?? null;

export const getTaskGroupIsRequired = (
  g: { taskIds: string[] },
  selectedRequiredTasksGroups: TaskGroupReq[]
) =>
  selectedRequiredTasksGroups.find(isEqualToTaskGroup(g))?.isRequired ?? null;

export const getNewDefaultAnsweredTaskGroups = (
  selectedRequiredTasksGroups: TaskGroupReq[],
  taskGroups: TaskGroupToAnswer[]
): TaskGroupReq[] =>
  pipe(
    [
      ...taskGroups.map(tg => {
        const isRequired = getTaskGroupIsRequired(
          tg,
          selectedRequiredTasksGroups
        );
        return {
          ...tg,
          isRequired: isRequired ?? false,
        };
      }),
      ...selectedRequiredTasksGroups,
    ],
    getDistinctWhere(isEqualTaskGroups)
  );

export const getNewDefaultAnsweredSkillGroups = (
  selectedRequiredSkillsGroups: SkillGroupReq[],
  skillGroups: SkillGroupToAnswer[]
): SkillGroupReq[] =>
  pipe(
    [
      ...skillGroups.map(sg => {
        const isRequired = getSkillGroupIsRequired(
          sg,
          selectedRequiredSkillsGroups
        );
        return {
          ...sg,
          isRequired: isRequired ?? false,
        };
      }),
      ...selectedRequiredSkillsGroups,
    ],
    getDistinctWhere(isEqualSkillGroups)
  );

export const getNewSelectedTaskGroups = (
  g: TaskGroupToAnswer,
  isRequired: boolean | null,
  selectedTasksGroups: TaskGroupReq[]
) =>
  isRequired === null
    ? selectedTasksGroups.filter(
        x => !isEqualTaskGroups({ taskIds: x.taskIds }, { taskIds: g.taskIds })
      )
    : pipe(
        selectedTasksGroups,
        upsertTaskGroup<TaskGroupReq>({
          taskGroupIds: g.taskGroupIds,
          taskIds: g.taskIds,
          isRequired,
        })
      );

export const getNewSelectedSkillGroups = (
  g: SkillGroupToAnswer,
  isRequired: boolean | null,
  selectedSkillGroups: SkillGroupReq[]
) => {
  return isRequired === null
    ? selectedSkillGroups.filter(
        x =>
          !isEqualSkillGroups(
            { skillIds: x.skillIds },
            { skillIds: g.skillIds }
          )
      )
    : pipe(
        selectedSkillGroups,
        upsertSkillGroup<SkillGroupReq>({
          skillGroupIds: g.skillGroupIds,
          skillIds: g.skillIds,
          isRequired,
        })
      );
};

export const getNameFromTaskIds = (
  taskIds: string[],
  taskNamesById: Record<string, string>
): string => {
  return pipe(
    taskIds,
    A.map(x => O.fromNullable(taskNamesById[x])),
    A.compact
  ).join(", ");
};

export const getNameForSkillGroup = <
  T extends { groupName: string | null; skillIds: string[] }
>(v: {
  skillGroup: T;
  skillsById: Map<string, LangObject | Promise<LangObject>>;
  lang: string;
  andMoreText: string;
}): string | null => {
  const getSkillNameById = getTextByIdForLangObject({
    lang: v.lang,
    langObjectById: v.skillsById,
  });

  return pipe(
    O.fromNullable(v.skillGroup.groupName),
    O.orElse(tryGetNameFromIds(v.skillGroup.skillIds, getSkillNameById)),
    O.getOrElse(
      getNameFromMultipleIds(
        v.skillGroup.skillIds,
        getSkillNameById,
        v.andMoreText
      )
    )
  );
};
export const getNameFromTaskGroup = <
  T extends { groupName: string | null; taskIds: string[] }
>(v: {
  taskGroup: T;
  taskNamesById: Record<string, string>;
  andMoreText: string;
}): string | null => {
  const getTaskNameById = (id: string) => O.fromNullable(v.taskNamesById[id]);

  return pipe(
    O.fromNullable(v.taskGroup.groupName),
    O.orElse(tryGetNameFromIds(v.taskGroup.taskIds, getTaskNameById)),
    O.getOrElse(
      getNameFromMultipleIds(
        v.taskGroup.taskIds,
        getTaskNameById,
        v.andMoreText
      )
    )
  );
};

export const getModulesByRoleIdRecursive = (v: {
  roleId: string;
  passedRoleIds: string[];
  allRoles: GetRolesDto[];
}): RobotStepperModule[] => {
  const role = v.allRoles.find(r => r.id === v.roleId);

  const newPassedRoleIds = [...v.passedRoleIds, v.roleId];

  if (!role) {
    return [];
  }

  return (role.robotStepperModules ?? []).reduce(
    (acc: RobotStepperModule[], m) => {
      if (
        m.moduleType === "SubRolePickSingle" ||
        m.moduleType === "SubRolePickMultiple"
      ) {
        return [
          ...acc,
          m,
          ...m.subRoleIds
            .filter(srid => !newPassedRoleIds.includes(srid))
            .reduce(
              (acc2: RobotStepperModule[], subRoleId) => [
                ...acc2,
                ...getModulesByRoleIdRecursive({
                  allRoles: v.allRoles,
                  roleId: subRoleId,
                  passedRoleIds: newPassedRoleIds,
                }),
              ],
              []
            ),
        ];
      }

      return [...acc, m];
    },
    []
  );
};

const getNameFromMultipleIds = (
  ids: string[],
  tryGetNameById: (skillId: string) => O.Option<string>,
  andMoreText: string
) => {
  return () =>
    pipe(
      ids,
      A.takeLeft(2),
      A.map(tryGetNameById),
      removeOptionals,
      O.fromPredicate(A.isNonEmpty),
      O.map(xs => `${xs.join(", ")} ${andMoreText.trim()}`),
      O.getOrElseW(() => null)
    );
};

const tryGetNameFromIds = (
  ids: string[],
  getNameById: (id: string) => O.Option<string>
) => {
  return () =>
    pipe(
      ids,
      O.fromPredicate(x => x.length === 1),
      O.flatMap(A.head),
      O.flatMap(getNameById)
    );
};

const getActiveModulesForRoleId = (allRoles: GetRolesDto[]) => (
  rid: string
): RobotStepperModule[] => {
  return pipe(
    allRoles,
    A.findFirst(x => x.id === rid),
    O.map(x => x.robotStepperModules?.filter(isActiveModule) ?? []),
    O.getOrElseW(() => [])
  );
};

const removeSubRole = <T extends { subRoleId: string }>(
  subRoleModules: {
    subRoleIds: string[];
    roleId: string;
  }[],
  roleId: string
) => (selectedSubRoles: T[]): T[] => {
  const subRolesModulesMaped = subRoleModules.map(x => {
    return {
      id: x.roleId,
      childrenIds: x.subRoleIds,
    };
  });

  const roleIdsToRemove = getIdsToRemoveIncludingChildren(
    subRolesModulesMaped,
    roleId
  );

  return selectedSubRoles.filter(sr => !roleIdsToRemove.includes(sr.subRoleId));
};

const includesEq = <T>(eq: Eq<T>) => (item: T) => (arr: T[]): boolean =>
  arr.some(x => eq.equals(x, item));

const eqSubRole: Eq<{ subRoleId: string }> = {
  equals: (a, b) => a.subRoleId === b.subRoleId,
};

const includesSubRole = includesEq(eqSubRole);

export const toggleSubRoleSelection = <T extends { subRoleId: string }>(
  subRoleToToggle: T,
  module: { moduleType: string; subRoleIds?: string[] },
  selectedNodes: T[],
  subRoleModules: {
    subRoleIds: string[];
    roleId: string;
  }[]
): T[] => {
  const isRemoval = pipe(selectedNodes, includesSubRole(subRoleToToggle));

  return isRemoval
    ? pipe(
        selectedNodes,
        removeSubRole(subRoleModules, subRoleToToggle.subRoleId)
      )
    : pipe(
        selectedNodes,
        removeSiblingsIfSingleSelect(module, subRoleModules),
        addSubRole(subRoleToToggle)
      );
};

const addSubRole = <T extends { subRoleId: string }>(x: T) =>
  upsertItem<T>((a, b) => a.subRoleId === b.subRoleId)(x);

const removeSiblingsIfSingleSelect = <T extends { subRoleId: string }>(
  module: { moduleType: string; subRoleIds?: string[] | undefined },
  subRoleModules: { subRoleIds: string[]; roleId: string }[]
) => (selectedNodes: T[]): T[] => {
  return module.moduleType === "SubRolePickSingle" && module.subRoleIds?.length
    ? pipe(
        module.subRoleIds,
        A.reduce(selectedNodes, (acc, siblingSubRoleId) =>
          pipe(acc, removeSubRole(subRoleModules, siblingSubRoleId))
        )
      )
    : selectedNodes;
};

const getTextFromDictWithoutAccent = (lang: string) => (
  dict: Record<string, string>
): string | null =>
  pipe(
    dict[lang],
    O.fromNullable,
    O.map(x => x.replaceAll("$ACCENT$", "")),
    O.getOrElseW(() => null)
  );

export const getTextFromHint = (v: {
  hint: StepperModuleHint | null;
  lang: string;
}): string | null => {
  const getText = flow(
    getTextFromDictWithoutAccent(v.lang),
    O.fromNullable,
    O.getOrElseW(() => "")
  );

  return pipe(
    v.hint,
    O.fromNullable,
    O.map(h => getText(h.accentText) + getText(h.mainText)),
    O.chain(O.fromPredicate(x => x.trim().length > 0)),
    O.getOrElseW(() => null)
  );
};

export const getRoleSpecificitySelectedRoles = (
  roleIds: string[],
  allRoles: GetRolesDto[]
) =>
  pipe(
    roleIds,
    getDistinct,
    A.map(tryGetById(allRoles)),
    A.compact,
    A.map(x => ({
      id: x.id,
      name: x.text,
    }))
  );

export const getRoleSpecificityRolesFromRequirements = (
  reqs: SourcingRobotRequirement[],
  allRoles: GetRolesDto[]
) =>
  pipe(
    reqs,
    filterByReqType("RoleSpecificity2"),
    A.flatMap(x => x.roleIds),
    getDistinct,
    A.map(tryGetById(allRoles)),
    A.compact,
    A.map(x => ({
      id: x.id,
      name: x.text,
    }))
  );

export const getInitialRobotObjectWithSubRoles = (v: {
  recruitmentId: string;
  baseInfoRoleId: string | null;
  allModules: RobotStepperModule[];
  lang: string;
  selectedSubRoles: SubRoleStepValue[];
  allRoles: GetRolesDto[];
  skillGroupsObjects: SkillGroupObject[];
  taskGroupObjects: TaskGroupObject[];
}): SourcingRobotRequirementsObject => {
  const defualtReqProfile = getRobotRequirementProfile(
    [],
    v.skillGroupsObjects,
    v.taskGroupObjects,
    v.lang
  );

  const defaultRequirements = getRobotRequirements({
    ...defualtReqProfile,
    selectedSubRoles: v.selectedSubRoles,
  });

  return {
    isManual: false,
    isStopped: true,
    isBreaked: false,
    recruitmentId: v.recruitmentId,
    shouldBeRunInitially: false,
    requirements: defaultRequirements,
  };
};

export const getRecListRobotStatuses = (
  v: Pick<SourcingRobotRequirementsObject, "isStopped" | "isManual">
): {
  newRobotIsActive: boolean;
  manualRobotIsActive: boolean;
} => {
  return {
    newRobotIsActive: !v.isStopped && !v.isManual,
    manualRobotIsActive: !v.isStopped && v.isManual,
  };
};

const getCommonnessScore = (
  id: string,
  top100List: string[],
  map: {
    first: { top: number; score: number };
    second: { top: number; score: number };
    third: { top: number; score: number };
    fourth: { score: number };
  }
): number => {
  const index = top100List.findIndex(x => x === id);

  if (index <= map.first.top) {
    return map.first.score;
  }

  if (index <= map.second.top) {
    return map.second.score;
  }

  if (index <= map.third.top) {
    return map.third.score;
  }

  return map.fourth.score;
};

export const getAverageRoleSpecificityProportion = (
  stepValue: string[],
  allModules: RobotStepperModule[],
  baseInfoRoleId: string | null
): number =>
  pipe(getRoleSpecificityProportion(stepValue, allModules, baseInfoRoleId), x =>
    x === null ? 0 : x
  );

export const getRoleSpecificityProportion = (
  roleIds: string[],
  allModules: RobotStepperModule[],
  baseInfoRoleId: string | null
) => {
  const modules = pipe(allModules, filterByModuleType("RoleSpecificity"));
  if (!modules.length) {
    return null;
  }

  if (baseInfoRoleId === null) {
    return null;
  }

  const possibleRoleIds = pipe(
    [...modules.flatMap(m => m.roleIds), baseInfoRoleId, ...roleIds].filter(
      isSomething
    ),
    getDistinct
  );

  const distinctSelectedRoleIds = pipe(roleIds, getDistinct);

  return getSpecificityProportion(
    distinctSelectedRoleIds.length,
    possibleRoleIds.length
  );
};

export const getSpecificityProportion = (
  selectedIds: number,
  allIds: number
) => {
  if (!allIds) {
    return null;
  }

  if (selectedIds === 0) {
    return null;
  }

  const mostSpecific = 1 - 1 / allIds;
  const result = 1 - selectedIds / allIds;

  return result / mostSpecific;
};

export const getOpennessScore = (v: {
  reqs: SourcingRobotRequirement[];
  baseInfo: JobbOfferBaseInfo | null;
  allModules: RobotStepperModule[];
  settingsFromDb: OpennessSettings | null;
  rolesWithSkillsAndTasksByToleId: Record<
    string,
    ThingToLoad<GetRoleWithSkillsAndTasksDto>
  >;
  allRoles: GetRolesDto[];
  skillGroupsObjects: SkillGroupObject[];
  taskGroupObjects: TaskGroupObject[];
}): number => {
  const reqProfile = getRobotRequirementProfile(
    v.reqs,
    v.skillGroupsObjects,
    v.taskGroupObjects,
    "en"
  );

  const settings: OpennessSettings = v.settingsFromDb ?? {
    experienceYears: {
      weight: 1,
    },
    education: {
      devider: 5,
      weight: 1,
    },
    roleSpecificity: {
      weight: 0.4,
    },
    staff: {
      weight: 0.4,
    },
    tasks: {
      weight: 1,
      first: {
        top: 5,
        score: 0.1,
      },
      second: {
        top: 10,
        score: 0.1,
      },
      third: {
        top: 20,
        score: 0.2,
      },
      fourth: {
        score: 0.4,
      },
    },
    lang: {
      weight: 1,
      power: 1.5,
      en: 0,
      sv: 0.1,
      other: 1,
    },
    skills: {
      weight: 1,
      first: {
        top: 20,
        score: 0.1,
      },
      second: {
        top: 50,
        score: 0.3,
      },
      third: {
        top: 100,
        score: 0.7,
      },
      fourth: {
        score: 2,
      },
    },
    remote: {
      weight: 1,
      allWeekAndFullRemote: 0,
      allWeek: 0.1,
      fullRemote: 0.1,
      partOfWeek: 0.2,
      notAllowed: 0.5,
    },
    salary: {
      weight: 1,
      bonusWeight: 0.5,
      scorePerStep: 0.15,
    },
  };

  const experienceYearsScore = () => {
    const req = reqProfile.yearsExperienceRange;

    const highestMax = 20;

    const max = req.max ?? highestMax;
    const min = req.min;

    const diff = max - min;

    return (1 - diff / highestMax) * (settings.experienceYears.weight ?? 1);
  };

  const educationScore = () => {
    const minYears = reqProfile.minimunYearsOfEducation;

    return (minYears / settings.education.devider) * settings.education.weight;
  };

  const roleSpecificityScore = () => {
    const specificityProportion = getAverageRoleSpecificityProportion(
      reqProfile.roleSpecificityRoleIds,
      v.allModules,
      v.baseInfo?.roleId ?? null
    );

    return specificityProportion * (settings.roleSpecificity.weight ?? 0.4);
  };

  const staffScore = () => {
    const isRequired = reqProfile.requireStaffResponsibility;

    return isRequired ? settings.staff.weight ?? 0.4 : 0;
  };

  const taskScore = () => {
    return pipe(
      O.fromNullable(v.baseInfo?.roleId),
      O.chain(tryGetThingFromRecord(v.rolesWithSkillsAndTasksByToleId)),
      O.map(role =>
        reqProfile.selectedRequiredTasksGroups
          .filter(x => x.isRequired)
          .map(x =>
            pipe(
              x.taskIds,
              A.map(taskId =>
                getCommonnessScore(taskId, role.tasksTop100, settings.tasks)
              ),
              A.reduce({} as Record<number, number[]>, (acc, x) => {
                const key = x;
                const value = acc[x] ?? [];
                return {
                  ...acc,
                  [key]: [...value, x],
                };
              }),
              x => Object.values(x),
              A.filter(A.isNonEmpty),
              sortListBy([
                {
                  sortBy: x => x[0],
                },
              ]),
              A.head,
              O.map(x => x[0] / x.length),
              O.getOrElseW(() => 0)
            )
          )
      ),
      O.map(xs =>
        pipe(
          xs,
          A.reduce(0, (acc, x) => acc + x),
          sum => sum * xs.length * (settings.tasks.weight ?? 1)
        )
      ),
      O.getOrElseW(() => 0)
    );
  };

  const skillScore = () => {
    return pipe(
      O.fromNullable(v.baseInfo?.roleId),
      O.chain(tryGetThingFromRecord(v.rolesWithSkillsAndTasksByToleId)),
      O.map(role =>
        reqProfile.selectedSkillGroups
          .filter(x => x.isRequired)
          .map(x => {
            console.log("selectedSkillGroups", x);
            return x;
          })
          .map(x =>
            pipe(
              x.skillIds,
              A.map(skillId =>
                getCommonnessScore(skillId, role.skillsTop100, settings.skills)
              ),
              A.reduce({} as Record<number, number[]>, (acc, x) => {
                const key = x;
                const value = acc[x] ?? [];
                return {
                  ...acc,
                  [key]: [...value, x],
                };
              }),
              x => Object.values(x),
              A.filter(A.isNonEmpty),
              sortListBy([
                {
                  sortBy: x => x[0],
                },
              ]),
              A.head,
              O.map(x => x[0] / x.length),
              O.getOrElseW(() => 0)
            )
          )
      ),
      O.map(xs =>
        pipe(
          xs,
          A.reduce(0, (acc, x) => acc + x),
          sum => sum * xs.length * (settings.skills.weight ?? 1)
        )
      ),
      O.getOrElseW(() => 0)
    );
  };

  const languageScore = () => {
    const langIds = reqProfile.requiredLanguageIds;
    const commonness = (langId: string) =>
      langId === "5035c3d7-77d2-43ff-9c35-d979865b6ff8" // engelska
        ? settings.lang.en
        : langId === "b5a5356a-e1f3-4772-9c20-b49acc5a4a92" // svenska
        ? settings.lang.sv
        : settings.lang.other;

    return pipe(
      langIds,
      A.map(commonness),
      A.reduce(0, (acc, x) => acc + x),
      sum => {
        const lengthSquared = langIds.length ** settings.lang.power;

        return sum * (lengthSquared / 2) * (settings.lang.weight ?? 1);
      }
    );
  };

  const remoteScore = () => {
    const baseInfo = v.baseInfo;
    if (baseInfo === null) {
      return 0;
    }
    const wfh = baseInfo.workFromHome ?? WorkFromHome.FourDays;
    return (
      (wfh === WorkFromHome.AllWeek && baseInfo.acceptsFullRemote
        ? settings.remote.allWeekAndFullRemote
        : wfh === WorkFromHome.AllWeek
        ? settings.remote.allWeek
        : baseInfo.isFullRemote
        ? settings.remote.fullRemote
        : [
            WorkFromHome.OneDay,
            WorkFromHome.TwoDays,
            WorkFromHome.ThreeDays,
            WorkFromHome.FourDays,
          ].includes(wfh)
        ? settings.remote.partOfWeek
        : settings.remote.notAllowed) * (settings.remote.weight ?? 1)
    );
  };

  const salaryScore = (allRoles: GetRolesDto[]) => {
    return () => {
      const baseInfo = v.baseInfo;
      if (baseInfo === null) {
        return 0;
      }

      const salary =
        reqProfile.salaryObject.baseSalary +
        (reqProfile.salaryObject.bonus ?? 0) * settings.salary.bonusWeight;

      if (salary === 0) {
        return 0;
      }
      const role = allRoles.find(x => x.id === baseInfo.roleId);

      const averageSalary = role?.salaryData?.salaryAverage ?? 55000;
      const salaryStep = Math.max(role?.salaryData?.step ?? 5000, 1);

      const top = averageSalary + salaryStep * 3;

      if (salary >= top) {
        return 0;
      }

      const stepsFromTop = (top - salary) / salaryStep;

      return (
        stepsFromTop *
        settings.salary.scorePerStep *
        (settings.salary.weight ?? 1)
      );
    };
  };

  return pipe(
    getScoreLazy([
      staffScore,
      experienceYearsScore,
      educationScore,
      roleSpecificityScore,
      languageScore,
      remoteScore,
      salaryScore(v.allRoles),
      taskScore,
      skillScore,
    ]),
    getNumberRounded(2)
  );
};

const getScoreLazy = (arr: (() => number)[]): number => {
  return pipe(
    arr,
    A.reduce(0, (acc, x) => (acc >= 100 ? acc : acc + x()))
  );
};

export const maxForExperienceRange = 21;

export const getNewBaseInfoWithAppliedTip = (
  oldBaseInfo: JobbOfferBaseInfo,
  tip: WantMoreCandidatesTip
): JobbOfferBaseInfo => {
  switch (tip.subType) {
    case "RemoteAcceptFullRemote":
      return {
        ...oldBaseInfo,
        acceptsFullRemote: true,
      };
    case "RemoteMoreFlexible":
      return {
        ...oldBaseInfo,
        workFromHome: WorkFromHome.FourDays,
      };
    case "DecreaseEducation":
    case "DecreaseMinExperienceYears":
    case "IncreaseMaxExperienceYears":
    case "Language":
    case "RemoveOneSkill":
    case "RemoveSkillGroup":
    case "ReplaceWithSkillGroup":
    case "RoleSpecificity":
    case "RoleSpecificity2":
    case "SalaryIncrease":
    case "Staff":
    case "Task":
    case "RemoveTaskGroup":
    case "ReplaceWithTaskGroup":
      return oldBaseInfo;
  }
};

export const getNewRequirementsProfileWithAppliedTip = (
  oldProfile: RobotRequirementProfileDto
) => (tip: WantMoreCandidatesTip): RobotRequirementProfileDto => {
  switch (tip.subType) {
    case "DecreaseEducation":
      return {
        ...oldProfile,
        minimunYearsOfEducation: tip.newEducationYears,
      };
    case "DecreaseMinExperienceYears":
      return {
        ...oldProfile,
        yearsExperienceRange: {
          ...oldProfile.yearsExperienceRange,
          min: tip.newMinYears,
        },
      };
    case "IncreaseMaxExperienceYears":
      return {
        ...oldProfile,
        yearsExperienceRange: {
          ...oldProfile.yearsExperienceRange,
          max: tip.newMaxYears,
        },
      };
    case "Language":
      return {
        ...oldProfile,
        requiredLanguageIds: oldProfile.requiredLanguageIds.filter(
          x => x !== tip.languageId
        ),
      };

    case "RemoveOneSkill":
      return {
        ...oldProfile,
        selectedSkillGroups: oldProfile.selectedSkillGroups.map(x => {
          if (
            isEqualSkillGroups(
              { skillIds: x.skillIds },
              { skillIds: [tip.skillId] }
            )
          ) {
            return {
              ...x,
              isRequired: false,
            };
          }
          return x;
        }),
      };
    case "Task": {
      return {
        ...oldProfile,
        selectedRequiredTasksGroups: oldProfile.selectedRequiredTasksGroups.map(
          x => {
            if (
              isEqualTaskGroups(
                { taskIds: x.taskIds },
                { taskIds: [tip.taskId] }
              )
            ) {
              return {
                ...x,
                isRequired: false,
              };
            }
            return x;
          }
        ),
      };
    }
    case "RemoveSkillGroup":
      return {
        ...oldProfile,
        selectedSkillGroups: oldProfile.selectedSkillGroups.map(x => {
          if (
            isEqualSkillGroups(
              { skillIds: x.skillIds },
              { skillIds: tip.skillIds }
            )
          ) {
            return {
              ...x,
              isRequired: false,
            };
          }
          return x;
        }),
      };
    case "RemoveTaskGroup":
      return {
        ...oldProfile,
        selectedRequiredTasksGroups: oldProfile.selectedRequiredTasksGroups.map(
          x => {
            if (
              isEqualTaskGroups(
                { taskIds: x.taskIds },
                { taskIds: tip.taskIds }
              )
            ) {
              return {
                ...x,
                isRequired: false,
              };
            }
            return x;
          }
        ),
      };
    case "ReplaceWithSkillGroup": {
      return {
        ...oldProfile,
        selectedSkillGroups: pipe(
          oldProfile.selectedSkillGroups,
          A.map(x => {
            if (
              isEqualSkillGroups(
                { skillIds: x.skillIds },
                { skillIds: [tip.skillId] }
              )
            ) {
              return {
                ...x,
                isRequired: false,
              };
            }
            return x;
          }),
          upsertSkillGroup<SkillGroupReq>({
            skillIds: tip.skillIds,
            skillGroupIds: [tip.skillGroupId],
            isRequired: true,
          })
        ),
      };
    }
    case "ReplaceWithTaskGroup": {
      return {
        ...oldProfile,
        selectedRequiredTasksGroups: pipe(
          oldProfile.selectedRequiredTasksGroups,
          A.map(x => {
            if (
              isEqualTaskGroups(
                { taskIds: x.taskIds },
                { taskIds: [tip.taskId] }
              )
            ) {
              return {
                ...x,
                isRequired: false,
              };
            }
            return x;
          }),
          upsertTaskGroup<TaskGroupReq>({
            taskIds: tip.taskIds,
            taskGroupIds: [tip.taskGroupId],
            isRequired: true,
          })
        ),
      };
    }
    case "RoleSpecificity": {
      return {
        ...oldProfile,
      };
    }
    case "RoleSpecificity2": {
      return {
        ...oldProfile,
        roleSpecificityRoleIds: [
          ...new Set([...oldProfile.roleSpecificityRoleIds, tip.addedRoleId]),
        ],
      };
    }
    case "SalaryIncrease": {
      return {
        ...oldProfile,
        salaryObject: {
          ...oldProfile.salaryObject,
          baseSalary: tip.newSalary,
        },
      };
    }
    case "Staff": {
      return {
        ...oldProfile,
        requireStaffResponsibility: false,
      };
    }
    case "RemoteAcceptFullRemote":
    case "RemoteMoreFlexible":
      return oldProfile;
  }
};

const listsAreEqual = (a: string[], b: string[]): boolean =>
  arraysAreEqual(a, b, (a, b) => a === b);

export const tipIsEqual = (
  a: WantMoreCandidatesTip,
  b: WantMoreCandidatesTip
): boolean => {
  switch (a.subType) {
    case "DecreaseEducation":
      return (
        b.subType === a.subType && a.newEducationYears === b.newEducationYears
      );
    case "DecreaseMinExperienceYears":
      return b.subType === a.subType && a.newMinYears === b.newMinYears;
    case "IncreaseMaxExperienceYears":
      return b.subType === a.subType && a.newMaxYears === b.newMaxYears;
    case "Language":
      return b.subType === a.subType && a.languageId === b.languageId;
    case "RemoteAcceptFullRemote":
      return b.subType === a.subType;
    case "RemoteMoreFlexible":
      return b.subType === a.subType;
    case "RemoveOneSkill":
      return b.subType === a.subType && b.skillId === a.skillId;
    case "RemoveSkillGroup":
      return b.subType === a.subType && listsAreEqual(a.skillIds, b.skillIds);
    case "RemoveTaskGroup":
      return b.subType === a.subType && listsAreEqual(a.taskIds, b.taskIds);
    case "ReplaceWithSkillGroup":
      return (
        b.subType === a.subType &&
        b.skillId === a.skillId &&
        listsAreEqual(a.skillIds, b.skillIds)
      );
    case "RoleSpecificity":
      return b.subType === a.subType;
    case "RoleSpecificity2":
      return b.subType === a.subType && b.addedRoleId === a.addedRoleId;
    case "SalaryIncrease":
      return b.subType === a.subType && b.newSalary === a.newSalary;
    case "Staff":
      return b.subType === a.subType;
    case "Task":
      return b.subType === a.subType && b.taskId === a.taskId;
    case "ReplaceWithTaskGroup":
      return (
        b.subType === a.subType &&
        b.taskId === a.taskId &&
        listsAreEqual(a.taskIds, b.taskIds)
      );
  }
};

export const getSearchTags = (v: {
  recruitmentList: RecruitmentListItem[];
  recruitmentId: string | null;
  searchTagsByParams: Record<string, ThingToLoad<SearchTagResultDto[]>>;
}) => {
  const roleId = v.recruitmentList.find(r => r.id === v.recruitmentId)?.roleId;

  if (!roleId) {
    return [];
  }

  return (
    getThingOrNullFromRecord(
      v.searchTagsByParams,
      getSearchTagCacheKey({
        recruitmentRoleId: roleId,
      })
    ) ?? []
  );
};

export const removeSkillGroupsIfNotInPreferred = (
  preferredSkillIds: string[],
  selectedSkillGroups: SkillGroupReq[]
) => {
  return selectedSkillGroups.filter(x =>
    x.skillIds.some(id => preferredSkillIds.includes(id))
  );
};

export const removeTaskGroupsIfNotInPreferred = (
  preferredTaskIds: string[],
  selectedTaskGroups: TaskGroupReq[]
) => {
  return selectedTaskGroups.filter(x =>
    x.taskIds.some(id => preferredTaskIds.includes(id))
  );
};
