/* eslint-disable no-use-before-define */
import { z } from "zod";
import { ListObject } from "./ListObject";
import { RoleResponsibility } from "./RoleResponsibility";
import { WorkFromHome } from "./WorkFromHome";
import { ListObjectNullable } from "./ListObjectNullable";
import { JobbOfferBaseInfo } from "./JobbOfferBaseInfo";
import { TalentExpectations } from "./TalentExpectations";
import { UpdateExperienceRequest } from "./UpdateExperienceRequest";
import { TalentTasksAndSkills } from "./TalentTasksAndSkills";
import { TalentExperience } from "./TalentExperience";
import { TalentProfileDto } from "./TalentProfileDto";

export interface JobbOfferPerk {
  text: string;
  description: string;
}

export type TextRules = Array<(v: string | undefined) => true | string>;

export interface SocialMediaLinks {
  twitterUrl: string | null;
  youtubeUrl: string | null;
  linkedInUrl: string | null;
  instagramUrl: string | null;
  facebookUrl: string | null;
}

export interface JobbOfferInterviewItem {
  question: string;
  answer: string;
}

export enum JobbOfferInterviewType {
  uploadedVideo = 1,
  youtubeVideo = 2,
  text = 3,
  noInterview = 4,
}

export interface InterviewBase {
  videoUrl: string | null;
  uploadInProgress: boolean;
  youtubeUrl: string | null;
  textInterview: JobbOfferInterviewItem[];
}

export interface Interview extends InterviewBase {
  interviewType: JobbOfferInterviewType | null;
}

export interface TalentDepartment {
  name: string;
  imageUrls: string[];
  description: string;
}

export interface JobOfferDepartmentToAdd extends TalentDepartment, removable {}

export interface removable {
  isRemoved: boolean;
}

export interface JobOfferDepartment extends JobOfferDepartmentToAdd {
  id: string;
}

export interface TalentJobOfferVisual {
  ingress: string;
  announcementTitle: string;
  imageUrl: string | null;
  perks: JobbOfferPerk[];
  manager?: TalentCoWorker | null;
  department: TalentDepartment | null;
  coWorkers: TalentCoWorker[];
  skills: ListObjectNullable[];
  taskIds: string[];
  bannerImageUrl: string | null;
  textInterview: JobbOfferInterviewItem[] | null;
  includeInterview: boolean;
  defaultBannerColor: string | null;
}

export interface JobOfferVisaulToUpdate {
  ingress: string | null;
  announcementTitle: string | null;
  imageUrl: string | null;
  perks: JobbOfferPerk[];
  useSeparateInterview: boolean;
  manager: CoWorkerDto | null;
  department: JobOfferDepartment | null;
  coWorkers: CoWorkerDto[];
  skills: ListObjectNullable[];
  taskIds: string[];
  etag?: string;
  bannerImageUrl: string | null;
  recruitmentImagePackId: string | null;
  textInterview: JobbOfferInterviewItem[] | null;
  includeInterview: boolean;
}

export interface TrelloBoardTalentStatus {
  invited: Date | null;
  accepted: Date | null;
  removed: Date | null;
  declined: Date | null;
}

export type JobOfferTalentInfo = {
  firstName: string | null;
  lastName: string | null;
  contactEmail: string | null;
  phoneNumber: string | null;
  salary: number;
  expectations?: TalentExpectations;
  inviterId?: string;
  tasksAndSkills?: {
    skills: ListObjectNullable[];
    roles: { taskIds: string[] }[];
  };
};

export interface JobbOfferVisualForView extends TrelloBoardTalentStatus {
  recruitmentId: string;
  companyName: string;
  companyId: string;
  logoUrl: string;
  baseInfo: JobbOfferBaseInfo;
  visual: TalentJobOfferVisual;
  branchId?: string | null;
  salaryMatched: boolean;
  numberOfNewMessages: number;
  lastRead: Date | null;
  isRemovedBeforeAnswered: boolean;
  recruitmentCloseDate: Date | null;
  isHired: boolean;
  aIMatchDescription?: Record<string, string> | null;
}

export interface TalentJobOffer {
  recruitmentId: string;
  companyId: string;
  companyName: string;
  logoUrl: string;
  baseInfo: JobbOfferBaseInfo;
  visual: TalentJobOfferVisual;
  branchId: string | null;
  recruitmentCloseDate: string | null;
}

export interface GetJobbOfferListDto extends TrelloBoardTalentStatus {
  recruitmentId: string;
  salaryMatched: boolean;
  locationMatched: boolean | null;
  salaryWithinThemargin: boolean | null;
  answerBefore?: Date;
  newMessages: number | null;
  lastRead: string | null;
  isRemovedBeforeAnswered: boolean;
  jobOffer: TalentJobOffer;
  isHired: boolean;
  aIMatchDescription?: Record<string, string> | null;
}

export enum JobOfferRequirementType {
  Task = 1,
  Skill = 2,
  Responsibility = 3,
  WorkRole = 4,
  YearsOfWorkExperience = 5,
  EducationLevel = 7,
  School = 8,
  EducationDomain = 9,
  Salary = 10,
  Language = 11,
  BranchNew = 12,
  MaxYearsOfExperience = 13,
  EducationDomainsNew = 14,
  SchoolsNew = 15,
}

export interface RequirementGroupHelperDto {
  requirements: { type: JobOfferRequirementType }[];
}

export interface BranchRequirement {
  branchId: string;
  minNumberOfYears: number;
}

export interface JobOfferRequirementProfileToAdd {
  baseSalary: number;
  variableCompensation: number | null;
  skills: ListObject[];
  taskIds: string[];
  responsibilities: ExperienceResponsibility[];
  roleIds: string[];
  yearsOfWorkExperience: number | null;
  maxYearsOfWorkExperience: number | null;
  branchIds: string[];
  branches: BranchRequirement[];
  educationLevel: number | null;
  schools: ListObject[];
  educationDomainIds: string[];
  languageIds: string[];
  etag?: string;
}

export interface RequirementGroupDto {
  id: string;
  requirmentIds: string[];
}

export interface JobOfferRequirementProfileToSave
  extends JobOfferRequirementProfileToAdd {
  requirementGroups: GetTalentsForSourcingRequirementGroupDto[];
}

export interface OpportunitySourcingRole {
  responsibilities: RoleResponsibility[];
  roleId: string;
  workFromHome: WorkFromHome;
  trips: boolean;
  longitude: number | null;
  latitude: number | null;
}

export interface OpportunitySourcing {
  sourcing: JobOfferRequirementProfileToSave;
  role: OpportunitySourcingRole;
  isPaused: boolean;
}

export type RuleValidationFunction = (v: any) => true | string;

export type RuleValidationFunctionGeneric<T> = (v: T) => true | string;

export type CompetitionAnswerBreakit = {
  email: string;
  competitionType: "BreakitCompetitionSpring2023";
  answer: {
    name: string;
    companyName: string;
    answer1: string;
    answer2: string;
    answer3: string;
    knockoutAnswer: number;
  };
};

export type CompetitionAnswerSept2023 = {
  email: string;
  competitionType: "CompetitionAnswerSept2023";
  answer: {
    companyName: string;
    answer1: string;
    answer2: string;
    answer3: string;
    knockoutAnswer: number;
  };
};
export type AddCompetitionAnswerDto = {
  email: string;
  competitionType: string;
  answer: any;
};

export interface CompanyProfileListItem {
  shortDescription: string;
  longDescription: string;
}

export interface CompanyProfileListItemWithIcon extends CompanyProfileListItem {
  icon: string;
}

export interface CompanyProfileListItemWithImage
  extends CompanyProfileListItem {
  imageUrl: string;
}

export interface CompanyProfileMilestone
  extends CompanyProfileListItemWithImage {
  year: number;
}

export interface UpdateMileStonesDto {
  milestonesVisible: boolean;
  milestoneList: CompanyProfileMilestone[];
}

export interface TalentCoWorker {
  name: string;
  role: string;
  imageUrl: string;
  interview: Interview;
  socialMedia: SocialMediaLinks;
  isManager: boolean;
}

export interface CoWokerToAdd extends TalentCoWorker, removable {}

export interface CoWorkerDto extends CoWokerToAdd {
  id: string;
}

export interface CompanyProfileTeamMember {
  name: string;
  role: string;
  imageUrl: string;
  videoUrl: string | null;
  showVideo: boolean;
  interview: JobbOfferInterviewItem[];
  socialMedia: SocialMediaLinks;
}

export interface UpdateStartImageDto {
  text: string | null;
  imageUrl: string | null;
}

export interface UpdatePerksDto {
  perksImageUrl: string | null;
  perksVisible: boolean;
  perkList: CompanyProfileListItemWithIcon[];
}

export interface UpdatePeopleStatisticsDto {
  noOfEmployees: number | null;
  noOfCountries: number | null;
  noOfMarkets: number | null;
}

export interface UpdateCompanyProfileRolesDto {
  visible: boolean;
  roleList: CompanyProfileListItem[];
}

export interface UpdateInShortDto {
  icon: string;
  number: string;
  prefix: string | null;
  suffix: string | null;
  disableThousandsSeparator: boolean;
  text: string;
}

export interface UpdateCompanyValuesDto {
  valuesVisible: boolean;
  valuesImageUrl: string | null;
  valueList: CompanyProfileListItemWithIcon[];
}

export interface CompanyProfileAtAGlance {
  message: UpdateStartImageDto | null;
  inShort: UpdateInShortDto[];
  socialMedia: SocialMediaLinks | null;
}

export interface CompanyProfileCompany {
  companyVideoUrl: string | null;
  companyVideoType: CompanyVideoType;
  businessAreas: CompanyProfileListItemWithImage[];
  values: UpdateCompanyValuesDto | null;
  milestones: UpdateMileStonesDto | null;
}

export interface CompanyProfilePeople {
  statistics: UpdatePeopleStatisticsDto | null;
  team: CoWorkerDto[];
  teamVisible: boolean;
  offices: CompanyProfileOffice[] | null;
  perks: UpdatePerksDto | null;
  roles: UpdateCompanyProfileRolesDto | null;
}

export interface CompanyProfileBase {
  logoUrl: string | null;
  logoIncludesName: boolean;
  companyName: string | null;
  ingress: string | null;
  branchId?: string | null;
  industryId: string | null;
  businessAreasType: BusinessAreasType;
}

export interface CompanyProfileDto extends CompanyProfileBase {
  atAGlance: CompanyProfileAtAGlance | null;
  company: CompanyProfileCompany | null;
  people: CompanyProfilePeople | null;
  companyEmail?: string;
  isStartUpScaleUp?: boolean;
  isPublicSector?: boolean;
  isConsultingCompany?: boolean;
}

export interface CompanyProfileOffice {
  id: string;
  name: string;
  officeImage: string | null;
  description: string;
  longitude: number;
  latitude: number;
}

export type GetTalentsForSourcingRequirementDto =
  | {
      type: JobOfferRequirementType.Task;
      value: string;
    }
  | {
      type: JobOfferRequirementType.Skill;
      value: string;
    }
  | {
      type: JobOfferRequirementType.Responsibility;
      value: {
        responsibility: RoleResponsibility;
        level: number;
      };
    }
  | {
      type: JobOfferRequirementType.WorkRole;
      value: string[];
    }
  | {
      type: JobOfferRequirementType.BranchNew;
      value: { id: string; value: number };
    }
  | {
      type: JobOfferRequirementType.EducationDomain;
      value: string;
    }
  | {
      type: JobOfferRequirementType.EducationLevel;
      value: number;
    }
  | {
      type: JobOfferRequirementType.Language;
      value: string;
    }
  | {
      type: JobOfferRequirementType.Salary;
      value: { baseSalary: number; bonus: number };
    }
  | {
      type: JobOfferRequirementType.School;
      value: string;
    }
  | {
      type: JobOfferRequirementType.YearsOfWorkExperience;
      value: number;
    }
  | {
      type: JobOfferRequirementType.MaxYearsOfExperience;
      value: number;
    }
  | {
      type: JobOfferRequirementType.EducationDomainsNew;
      value: string[];
    }
  | {
      type: JobOfferRequirementType.SchoolsNew;
      value: string[];
    };

export interface SavedManualSourcing {
  id: string;
  recruitmentId: string;
  requirementGroups: Omit<GetTalentsForSourcingRequirementGroupDto, "id">[];
  title: string;
  openedDate: Date;
  openedByUserId: string;
  changedDate: Date;
  changedByUserId: string;
  createdDate: Date;
}

export type SavedManualSourcingFromServer = DateReplacer<SavedManualSourcing>;

export interface GetTalentsForSourcingRequirementGroupDto {
  requirements: GetTalentsForSourcingRequirementDto[];
  id: string;
  isMustHave: boolean;
}

export interface GetTalentsForSourcingDto {
  responsibilities: RoleResponsibility[];
  workFromHome: WorkFromHome;
  acceptsFullRemote: boolean;
  trips: boolean;
  requirementGroups: GetTalentsForSourcingRequirementGroupDto[];
  longitude: number | null;
  latitude: number | null;
  areaId: string;
  roleId: string;
}

export interface TalentForSearchCard extends TalentForFiltering {
  matchingRequirements: string[];
  percent: number;
}

export interface TalentForFiltering {
  id: string;
  yearsOfExperience: number;
  responsibilities: number[];
  skills: string[];
  languageIds: string[];
  tasks: string[];
  branchExperience: { [key: string]: number };
  workRoles: string[];
  yearsOfEducation: number;
  schools: string[];
  schoolDomains: string[];
  salary: number;
  requireStableSalary: boolean;
  latestEmploymentRoleIds: string[];
  numberOfRolePreferences: number;
}

export interface WorkTitleDto extends ListObjectNullable {
  roles: string[];
  pickFromSelectedGroups: boolean;
}

export interface RoleWithTitles {
  roleId: string;
  titles: string[];
  employmentIds: string[];
}

export enum EducationDegrees {
  HigherVocational = 1,
  AdvancedHigherVocational = 2,
  UniversityDiploma = 3,
  Bachelor = 4,
  Master1Year = 5,
  Master2Year = 6,
  Licentiate = 7,
  Doctorate = 8,
  NoDegree = 9,
}

export interface HigherVocationalEducationBase {
  degree:
    | EducationDegrees.HigherVocational
    | EducationDegrees.AdvancedHigherVocational;
  domain: string;
  school: string;
  haveStudiedAbroad: false;
  abroadSchools: [];
}

export interface StudyAbroadEducation {
  haveStudiedAbroad: boolean;
  abroadSchools: ListObjectNullable[];
}

export interface NoDegreeStandaloneCourses {
  degree: EducationDegrees.NoDegree;
  numberOfYears: 0;
  domain: string;
  schools: ListObjectNullable[];
  haveStudiedAbroad: false;
  abroadSchools: [];
}

export interface NoDegreeEducationBase extends StudyAbroadEducation {
  degree: EducationDegrees.NoDegree;
  numberOfYears: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  domainId: string;
  schools: ListObjectNullable[];
}

export interface UniversityWithDegreeEducationBase
  extends StudyAbroadEducation {
  degree:
    | EducationDegrees.UniversityDiploma
    | EducationDegrees.Bachelor
    | EducationDegrees.Master1Year
    | EducationDegrees.Master2Year
    | EducationDegrees.Licentiate
    | EducationDegrees.Doctorate;
  domainId: string;
  schools: ListObjectNullable[];
}

export type EducationToAdd =
  | NoDegreeEducationBase
  | NoDegreeStandaloneCourses
  | UniversityWithDegreeEducationBase
  | HigherVocationalEducationBase;

export type EducationSaved = EducationToAdd & {
  id: string;
};

export interface EducationToView {
  id: string;
  school: string;
  degree: EducationDegrees;
  numberOfYears: number | null;
  domain: string;
  educationSaved: EducationSaved;
  abroadSchool: string | null;
}

export enum EmploymentType {
  Employee = 1,
  Consultant = 2,
  SelfEmployed = 3,
  Intern = 4,
}

export interface EmploymentPeriod {
  fromYear: string;
  toYear: string | null;
}

export type EmploymentPeriodTyped = EmploymentPeriod & {
  employmentType: EmploymentType | null;
};

export interface EmploymentBase extends EmploymentPeriod {
  branchId: string | null;
  industryId: string | null;
  roleId: string;
  companyAnonymousId?: string | null;
  employmentType: EmploymentType | null;
  selectNoCustomers: boolean | null;
}
export interface EmploymentToAdd extends EmploymentBase {
  company: ListObjectNullable;
  title: ListObjectNullable;
  customers: ListObjectNullable[];
}

export interface EmploymentSaved extends EmploymentToAdd {
  id: string;
}

export interface EmploymentAnonymous extends EmploymentBase {
  id: string;
}

export enum SuperSearchItemType {
  Talent = 1,
  RequitmentProcess = 2,
  User = 3,
  offices = 4,
  Department = 5,
  Manager = 6,
}

export interface SuperSearchItem extends ListObject {
  type: SuperSearchItemType;
}

export interface CompanyStatsItem {
  date: Date;
  amount: number;
}

export enum LastVisitedType {
  Sourcing = 1,
  Visual = 2,
  Talent = 3,
  CompanyProfile = 4,
  TrelloBoard = 5,
  OpportunitySourcing = 6,
  Offices = 7,
  Departments = 8,
  CoWorkers = 9,
  Perks = 10,
  Media = 11,
  RecruitmentList = 12,
  Settings = 13,
  MyAccount = 14,
  Lanes = 15,
  TalentPool = 16,
  RecruitmentComponents = 17,
}

export interface CompanyProfileVisitItem {
  visitType: LastVisitedType.CompanyProfile;
}
export interface OfficesVisitItem {
  visitType: LastVisitedType.Offices;
}
export interface LanesVisitItem {
  visitType: LastVisitedType.Lanes;
}
export interface RecruitmentListVisitItem {
  visitType: LastVisitedType.RecruitmentList;
}

export interface SettingsVisitItem {
  visitType: LastVisitedType.Settings;
}
export interface MyAccountVisitItem {
  visitType: LastVisitedType.MyAccount;
}
export interface DepartmentsVisitItem {
  visitType: LastVisitedType.Departments;
}
export interface CoWorkersVisitItem {
  visitType: LastVisitedType.CoWorkers;
}
export interface PersksVisitItem {
  visitType: LastVisitedType.Perks;
}
export interface MediaVisitItem {
  visitType: LastVisitedType.Media;
}
export interface TalentPoolVisitItem {
  visitType: LastVisitedType.TalentPool;
}

export interface VisualVisitItem {
  visitType: LastVisitedType.Visual;
  recruitmentId: string;
  recruitmentTitle: string;
}
export interface TrelloVisitItem {
  visitType: LastVisitedType.TrelloBoard;
  recruitmentId: string;
  recruitmentTitle: string;
}
export interface OpportunitySourcingVisitItem {
  visitType: LastVisitedType.OpportunitySourcing;
  recruitmentId: string;
  recruitmentTitle: string;
}
export interface SourcingVisitItem {
  visitType: LastVisitedType.Sourcing;
  recruitmentId: string;
  recruitmentTitle: string;
}
export interface TalentVisitItem {
  visitType: LastVisitedType.Talent;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
}

export type LastVisitedItem = LastVisitedItemToAdd & { date: Date };

export type LastVisitedItemToAdd =
  | CompanyProfileVisitItem
  | VisualVisitItem
  | SourcingVisitItem
  | TrelloVisitItem
  | OpportunitySourcingVisitItem
  | OfficesVisitItem
  | DepartmentsVisitItem
  | CoWorkersVisitItem
  | PersksVisitItem
  | MediaVisitItem
  | RecruitmentListVisitItem
  | SettingsVisitItem
  | MyAccountVisitItem
  | LanesVisitItem
  | TalentPoolVisitItem
  | TalentVisitItem;

export enum NotificationTypeInternal {
  TagOnTalent = 1,
  TagOnRecruitment = 2,
  TalentAccepted = 3,
  TalentInterested = 4,
  TalentDeclined = 5,
  SharedTalentVisited = 6,
  MessageFromTalent = 7,
  CommentOnSharedTalent = 8,
  PoolTalentInterested = 9,
  NotAnsweredPoolMessage = 11,
  PoolMessageFromTalent = 12,
  TalentPoolTeamComment = 13,
  UnhandledRecruitment = 14,
}

export enum NotificationType {
  Tag = 1,
  TalentAccepted = 2,
  TalentInterested = 3,
  TalentDeclined = 4,
  SharedTalentVisited = 5,
  MessageFromTalent = 6,
  CommentOnSharedTalent = 7,
  PoolTalentInterested = 8,
  NotAnsweredPoolMessage = 10,
  PoolMessageFromTalent = 11,
  TalentPoolTeamComment = 12,
  UnhandledRecruitment = 13,
}

export enum TagLocation {
  RequitmentProcess = 1,
  Talent = 2,
}

export interface NotificationTag {
  sender: string;
  text: string;
  locationType: TagLocation;
  locationId: string;
  locationName: string;
}

export type TagNotificationItem =
  | TalentTagNotification
  | RecruitmentTagNotification;

export type NotificationItem = {
  date: Date;
  isSeen: boolean;
  isRead: boolean;
  id: string;
} & (
  | TagNotificationItem
  | TalentInterestedNotification
  | TalentDeclinedNotification
  | SharedTalentVisited
  | MessageFromTalentNotification
  | CommentOnSharedTalentNotification
  | PoolTalentInterestedNotification
  | NotAnsweredPoolMessageNotification
  | PoolMessageFromTalentNotification
  | TalentPoolTeamCommentNotification
  | TalentAcceptedNotification
  | {
      type: NotificationTypeInternal.UnhandledRecruitment;
      userId: string;
      companyId: string;
      recruitmentId: string;
      date: Date;
      unhandledTalents: ListObject[];
    }
);

export interface TalentTagNotification {
  type: NotificationTypeInternal.TagOnTalent;
  sender: string;
  commentHtml: string;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
}

export interface TalentAcceptedNotification {
  type: NotificationTypeInternal.TalentAccepted;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
}
export interface TalentInterestedNotification {
  type: NotificationTypeInternal.TalentInterested;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
}

export interface PoolMessageFromTalentNotification {
  type: NotificationTypeInternal.PoolMessageFromTalent;
  talentId: string;
  talentName: string;
  message: string;
}
export interface TalentPoolTeamCommentNotification {
  type: NotificationTypeInternal.TalentPoolTeamComment;
  talentId: string;
  talentName: string;
  commentText: string;
  senderId: string;
}

export interface NotAnsweredPoolMessageNotification {
  type: NotificationTypeInternal.NotAnsweredPoolMessage;
  talentId: string;
  talentName: string;
  message: string;
}

export interface MessageFromTalentNotification {
  type: NotificationTypeInternal.MessageFromTalent;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
  text: string;
}

export interface CommentOnSharedTalentNotification {
  type: NotificationTypeInternal.CommentOnSharedTalent;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
  text: string;
  email: string;
}

export interface PoolTalentInterestedNotification {
  type: NotificationTypeInternal.PoolTalentInterested;
  talentId: string;
  talentName: string;
  recruitmentId: string;
}

export type CompanyInterestObject = {
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  email: string;
  phone: string | null;
  linkSource: string | null;
} & (
  | {
      isDone: false;
    }
  | {
      isDone: true;
      // id: string;
      numberOfExpectedRecritments: string | null;
    }
);

export const NumberOfEmployeesSpanArray = [
  "0-1",
  "2-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "100001+",
] as const;

export type NumberOfEmployeesSpan = typeof NumberOfEmployeesSpanArray[number];

export interface TalentDeclinedNotification {
  type: NotificationTypeInternal.TalentDeclined;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
}
export interface SharedTalentVisited {
  type: NotificationTypeInternal.SharedTalentVisited;
  recruitmentId: string;
  recruitmentTitle: string;
  talentId: string;
  talentName: string;
  email: string;
}

export interface RecruitmentTagNotification {
  type: NotificationTypeInternal.TagOnRecruitment;
  sender: string;
  commentHtml: string;
  recruitmentId: string;
  recruitmentTitle: string;
}

export enum CompanyDashboardNewsType {
  Gritify = 1,
}

export interface CompanyDashboardNewsItem {
  imageUrl?: string;
  title: string;
  description: string;
  type: CompanyDashboardNewsType;
}

export interface RecruitmentListItem {
  id: string;
  title: string;
  createdDate: Date;
  startedDate: Date | null;
  closedDate: Date | null;
  imageUrl: string | null;
  recTalentIsOpened: Date | null;
  numberOfAcceptedTalents: number;
  numberOfSavedTalents: number;
  numberOfActiveOpportunityTalent: number;
  numberOfActiveTalents: number;
  numberOfTalentsNotHandled: number;
  teamTailorJobId: string | null;
  officeId: string | null;
  opportunityActive: boolean;
  managerCoworkerId: string | null;
  includeInterview: boolean;
  departmentId: string | null;
  visualCoworkersIds: string[];
  savedTalentIds: string[];
  longitude: number | null;
  latitude: number | null;
  includesTravel: boolean;
  workFromHome: WorkFromHome;
  acceptsFullRemote: boolean | null;
  isFullRemote: boolean;
  rolesResponsibilities: RoleResponsibility[];
  roleId: string | null;
  oldRobotIsActive: boolean | null;
  robotIsBreaked: boolean | null;
  newRobotIsActive: boolean | null;
  manualRobotIsActive: boolean | null;
  succesPrice: JobPrice | null;
  hasReachedRecapDate?: Date | null;
}

export interface GetRecruitmentListItemDto {
  id: string;
  title: string;
  createdDate: string;
  startedDate: string | null;
  closedDate: string | null;
  imageUrl: string | null;
  recTalentIsOpened: string | null;
  numberOfSavedTalents: number;
  numberOfAcceptedTalents: number;
  numberOfActiveOpportunityTalent?: number;
  numberOfActiveTalents?: number;
  numberOfTalentsNotHandled?: number;
  officeId: string;
  opportunityActive?: boolean;
  teamTailorJobId: string | null;
  managerCoworkerId?: string | null;
  includeInterview: boolean;
  departmentId?: string | null;
  visualCoworkersIds?: string[];
  savedTalentIds?: string[] | null;
  longitude: number | null;
  latitude: number | null;
  includesTravel: boolean;
  workFromHome: WorkFromHome | null;
  acceptsFullRemote: boolean | null;
  isFullRemote: boolean;
  rolesResponsibilities: RoleResponsibility[] | null;
  roleId: string;
  newRobotIsActive: boolean | null;
  robotIsBreaked: boolean | null;
  manualRobotIsActive: boolean | null;
  oldRobotIsActive: boolean | null;
  succesPrice?: JobPrice | null;
  hasReachedRecapDate?: string | null;
}

export interface JobPrice {
  listPrice: number;
  discountedPrice: number;
}

export interface UpsertOfficeResponseDto {
  officeId: string;
  areaId: string;
}

export interface ScoreLimits {
  skillLimit: number | null;
  taskLimit: number | null;
  totalLimit: number | null;
}

export interface IdWithDiff {
  id: string;
  diff: number;
}

export interface RobotRequirementProfile {
  skills: {
    skillIds: string[];
    weight: number;
  }[];
  requiredSkillGroups: string[][];
  tasks: IdWithDiff[];
  requiredTaskGroups: string[][];
  salary: number;
  bonus: number;
  minYearsExperience: number;
  maxYearsExperience: number | null;
  languageIdsWithRequired: Record<string, boolean>;
  schoolDomains: IdWithDiff[];
  industries: IdWithDiff[];
  titleWildcards: IdWithDiff[];
  requiredRoleIds: string[];
  minimumYearsOfEducation: number;
  scoreLimits: ScoreLimits;
}

export interface getTalentsFromRobotRequirementsTestDto {
  scoredProfiles: ScoredProfile[];
  robotRequirementProfile: RobotRequirementProfile;
}

export interface TrelloBoardTalentResponse {
  rowVersion: number;
  talents: RecruitmentTalentWithDaysLeftDto[];
}
export interface TrelloBoardTalent extends TrelloBoardTalentStatus {
  id: string | null;
  percent: number | null;
  talentId: string;
  name: string | null;
  rating: number | null;
  lastUpdatedDate: Date;
  isHandledDate: Date | null;
  numberOfComments: number;
  laneId: string | null;
  sortOrder: number;
  declineReasonIds: string[];
  otherReason: string | null;
  contacted: Date | null;
  saved: Date | null;
  recruitmentId: string;
  expectations: TalentExpectations | null;
  messageLang: string | null;
  invitedBySourcingRobot: boolean;
  daysLeftDto: GetRecTalentNumberOfDaysLeftResultDto;
  statusText: string | null;
  isHired: boolean;
  aiTalentPresentation?: Record<string, string> | null;
  declineReasonTypes?: DeclineReasonTypeNew[];
}

export type GetRecTalentNumberOfDaysLeftResultDto =
  | {
      level: "Level0";
    }
  | {
      level: "Level1";
      removeDate: string;
    }
  | {
      level: "Level2";
      removeDate: string;
    }
  | {
      level: "Level3";
      removeDate: string;
    };

export interface RecruitmentTalentWithDaysLeftDto {
  recruitmentTalent: RecruitmentTalentObject;
  daysLeftDto: GetRecTalentNumberOfDaysLeftResultDto;
}

export interface RecruitmentTalentObject {
  id: string;
  talentId: string;
  name: string | null;
  rating: number | null;
  lastUpdatedDate: string;
  lastUpdatedBy: string | null | undefined;
  numberOfComments: number;
  laneId: string | null;
  sortOrder: number;
  declineReasonIds?: string[];
  otherReason?: string | null;
  invited: string | null;
  accepted: string | null;
  removed: string | null;
  declined: string | null;
  saved: string | null;
  contacted: string | null;
  percent?: number | null;
  recruitmentId: string;
  expectations?: TalentExpectations | null;
  messageLang: string | null;
  invitedBySourcingRobot: boolean;
  isHandledDate: string | null;
  statusText: string | null;
  isHired: boolean;
  aiTalentPresentation?: Record<string, string> | null;
}

export interface RecruitmentEventListItem {
  date: Date;
  id: string;
}

export enum LogTypes {
  State = 1,
  Status = 2,
  Role = 3,
  Visual = 4,
  RecruitmentProfile = 5,
  Rating = 6,
  TalentSaved = 7,
  TalentInvited = 8,
  TalentAccepted = 9,
  TalentDeclined = 10,
  TalentRemoved = 11,
  TalentInterested = 12,
  TalentShared = 13,
  Team = 14,
  RecruitmentOwnerGroup = 15,
  TalentAnonymized = 16,
  Opportunity = 17,
  OpportunityPaused = 18,
  TalentInvitedByRobot = 19,
  Unknown = 20,
  StatusText = 21,
  TalentRemoveDueToUnhandled = 22,
  AddedToTeamTailor = 23,
  AddedToJobylon = 24,
}

export type RecruitmentLogObject = {
  recruitmentId: string;
  date: Date;
  id: string;
} & (
  | {
      logType: LogTypes.State;
      talentId: string;
      teamMember: string;
      fromLane: string;
      toLane: string;
    }
  | {
      logType: LogTypes.Role;
      teamMember: string;
      old: JobbOfferBaseInfo;
      new: JobbOfferBaseInfo;
    }
  | {
      logType: LogTypes.Visual;
      teamMember: string;
      old: JobOfferVisaulToUpdate;
      new: JobOfferVisaulToUpdate;
    }
  | {
      logType: LogTypes.Rating;
      teamMember: string;
      talentId: string;
      rating: number;
    }
  | {
      logType: LogTypes.TalentSaved;
      teamMember: string;
      talentId: string;
    }
  | {
      logType: LogTypes.TalentInvited;
      teamMember: string;
      talentId: string;
    }
  | {
      logType: LogTypes.TalentAccepted;
      talentId: string;
    }
  | {
      logType: LogTypes.TalentInterested;
      talentId: string;
    }
  | {
      logType: LogTypes.TalentDeclined;
      talentId: string;
    }
  | {
      logType: LogTypes.TalentRemoved;
      teamMember: string;
      talentId: string;
    }
  | {
      logType: LogTypes.TalentShared;
      teamMember: string;
      talentId: string;
      emailAdresses: string[];
    }
  | {
      logType: LogTypes.Team;
      teamMember: string;
      targetTeamMemberId: string;
      teamType: "added" | "removed";
    }
  | {
      logType: LogTypes.RecruitmentOwnerGroup;
      teamMember: string;
      oldGroupId: string;
      newGroupId: string;
    }
  | {
      logType: LogTypes.TalentAnonymized;
      talentId: string;
      teamMember: string;
    }
  | {
      logType: LogTypes.Opportunity;
      teamMember: string;
      oldRequirments: GetTalentsForSourcingRequirementGroupDto[];
      newRequirements: GetTalentsForSourcingRequirementGroupDto[];
    }
  | {
      logType: LogTypes.OpportunityPaused;
      teamMember: string;
      isPaused: boolean;
    }
  | {
      logType: LogTypes.TalentInvitedByRobot;
      talentId: string;
    }
  | {
      logType: LogTypes.Unknown;
    }
  | {
      logType: LogTypes.StatusText;
      talentId: string;
      oldStatusText: string | null;
      newStatusText: string | null;
      teamMember: string;
    }
  | {
      logType: LogTypes.TalentRemoveDueToUnhandled;
      talentId: string;
    }
  | {
      logType: LogTypes.AddedToTeamTailor;
      talentId: string;
      teamMember: string;
      teamTailorJobId: string | null;
    }
  | {
      logType: LogTypes.AddedToJobylon;
      talentId: string;
      teamMember: string;
      jobylonJobId: string | null;
    }
);

export interface JobOfferCardDto extends TrelloBoardTalentStatus {
  id: string;
  companyId: string;
  imageUrl: string | null;
  defaultBannerColor: string | null;
  title: string;
  companyName: string;
  roleId: string | null;
  city: string;
  logoUrl: string;
  numberOfNewMessages: number;
  lastRead: Date | null;
  answerBefore: Date | null;
}

export type RecruitmentTeamMemberType =
  | "Full"
  | "SemiFull"
  | "Restricted"
  | "SuperAdmin";

export interface RecruitmentTeamMemberToAdd extends removable {
  userType: RecruitmentTeamMemberType;
  email: string;
  recruitmentIds: string[];
  lang: string;
}

export interface RecruitmentTeamMember extends RecruitmentTeamMemberToAdd {
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  invitedByUserId: string | null;
}

export interface GetRecruitmentTeamMemberDto {
  userId: string | null;
  userType: RecruitmentTeamMemberType;
  firstName: string | null;
  lastName: string | null;
  email: string;
  recruitmentIds?: string[];
  isRemoved?: boolean;
  lang: string;
  invitedByUserId: string | null;
}

export interface TalentAnonymous {
  id: string;
  expectations: TalentExpectations;
  experience: TalentExperienceAnonymous;
  tasksAndSkills: TalentTasksAndSkills;
  pausedTo: Date | null;
  lang: string | null;
  talentIsDeleted: boolean;
  isHiredCopy: boolean;
}

export interface GetSharedTalentDto {
  title: string;
  senderName: string;
  email: string;
  message: string | null;
  talent: TalentProfileForCompanyDto;
}

export interface TalentProfileToView extends TalentAnonymous {
  experience: TalentExperience;
  firstName: string;
  lastName: string;
  contactEmail?: string | null;
  phoneNumber?: string | null;
}

export interface TalentExperienceAnonymous extends UpdateExperienceRequest {
  employments: EmploymentAnonymous[];
  education: EducationSaved[];
}

export interface TalentLocation {
  id: string;
  text: string;
  locationType: "Region" | "City" | "Area" | "Remote";
  sortOrder: number;
  featuredSortOrder: number | null;
}

export interface TalentLocationTreeNode {
  id: string;
  label: string;
  children?: TalentLocationTreeNode[];
  isDisabled: boolean;
}

export interface CommentSender {
  firstName: string;
  lastName: string;
  userId: string;
}

export interface TeamComment {
  id: string;
  comment: string;
  sender: CommentSender;
  date: Date;
  sharedToEmails: string[];
}

export interface GetTeamCommentDto {
  id: string;
  comment: string;
  senderId: string | null;
  senderEmail: string | null;
  date: Date;
  sharedToEmails?: string[] | null;
}

export interface TalentMessage extends Omit<TeamComment, "sharedToEmails"> {
  fromTalent: boolean;
}

export interface GetTalentMessageDto {
  fromTalent: boolean;
  id: string;
  comment: string;
  sender: CommentSender;
  date: string;
}

export const CreateVisualStepTypeArray = [
  "Title",
  "Ingress",
  "Tasks",
  "Skills",
  "Perks",
  "Interview",
  "Team",
] as const;

export type CreateVisualStepType = typeof CreateVisualStepTypeArray[number];

export interface Address {
  streetAddress: string;
  zipCode: string;
  city: string;
  country: string;
}

export interface Office extends OfficeToUpdate {
  areaId: string;
}

export interface OfficeToUpdate extends OfficeToCreate {
  id: string;
}

export interface TalentOffice {
  name: string;
  officeImage: string | null;
  description: string | null;
  longitude: number;
  latitude: number;
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string;
}

export interface OfficeToCreate extends TalentOffice, removable {}

export interface TrelloBoardLaneDto extends TrelloBoardLaneBase {
  talents: TrelloBoardTalent[];
  canMoveToLane: boolean;
  isFixedLane: boolean;
  laneType: LaneType;
}

export interface TrelloBoardLaneBase extends ListObject {}

export interface TrelloBoardLaneFromServer extends ListObject {
  sortOrder: number;
}

export interface GoogleAddress extends ListObject {
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string;
  longitude: number;
  latitude: number;
  locationType: string;
}

export interface UpdateTalentCardsDto {
  recruitmentId: string;
  rowVersion: number;
  movement: { sortOrder: number; talentId: string; laneId: string | null }[];
}

export enum BusinessAreasType {
  BusinessAreas = 1,
  Trademarks = 2,
  Products = 3,
  Departments = 4,
  Projects = 5,
  Services = 6,
  Customers = 7,
}

export type CompanyVideoType = "Youtube" | "UploadedVideo";

export interface GetCompanyDto {
  id: string;
  text: string;
  branchId: string | null;
  industryId: string | null;
  logoUrl?: string | null;
}

export interface GetCompanyProfileDto {
  logoUrl: string | null;
  logoIncludesName: boolean;
  ingress: string | null;
  companyName: string;
  branchId?: string | null;
  industryId: string | null;
  message: UpdateStartImageDto;
  inShort: UpdateInShortDto[];
  socialMedia: SocialMediaLinks;
  companyVideoUrl: string | null;
  companyVideoType: CompanyVideoType;
  businessAreas: CompanyProfileListItemWithImage[];
  businessAreasType: BusinessAreasType;
  valuesVisible: boolean;
  valuesImageUrl: string | null;
  values: CompanyProfileListItemWithIcon[];
  milestonesVisible: boolean;
  milestones: CompanyProfileMilestone[];
  statistics: UpdatePeopleStatisticsDto;
  perksImageUrl: string | null;
  perksVisible: boolean;
  perks: CompanyProfileListItemWithIcon[];
  rolesVisible: boolean;
  roles: CompanyProfileListItem[];
  teamVisible: boolean;
}

export interface PublishedCompoanyProfileDto extends GetCompanyProfileDto {
  team: CoWorkerDto[];
  offices: OfficeToUpdate[];
  companyEmail?: string;
  isStartUpScaleUp?: boolean;
  isPublicSector?: boolean;
  isConsultingCompany?: boolean;
}

export interface GetWorkingProfileDto extends GetCompanyProfileDto {
  coWorkerIds: string[];
  officeIds: string[];
}

export interface SkillRecommendation {
  skillId: string;
  text: string;
  count: number;
}

export interface ImageLibraryPhoto {
  url: string;
  loading: boolean;
  fileName: string;
  uniqueName: string | null;
  deleted: Date | null;
}

export interface GetRolesDto extends ListObject {
  jobCategory: string | null;
  description: string | null;
  branchExperience: boolean;
  showSkillsInVisual: boolean;
  excludeBonusQuestion?: boolean;
  robotStepperModules?: RobotStepperModule[];
  robotIsPublished: boolean;
  showStaffReason?: boolean;
  preferenceRoleIds?: string[];
  excludeStaffPreference?: boolean;
  salaryData?: {
    salaryAverage: number;
    step: number;
  };
  askIfStaffIsIncluded?: boolean;
}

export interface GetRoleWithSkillsAndTasksDto {
  skillsTop100: string[];
  tasksTop100: string[];
}

export interface StepperModuleHint {
  accentText: { [key: string]: string };
  mainText: { [key: string]: string };
}

export type SubRoleStepValue = {
  subRoleId: string;
  moduleId: string;
  roleId: string;
};

export type RobotStepperModuleType = RobotStepperModule["moduleType"];

export type RobotStepperModule = {
  hint: StepperModuleHint | null;
  title: Record<string, string>;
  preventDuplicateId?: string | null;
  isDepricated?: boolean | null;
} & (
  | {
      moduleType: "SubRolePickSingle";
      moduleId: string;
      subRoleIds: string[];
      subTitle: Record<string, string>;
    }
  | {
      moduleType: "SubRolePickMultiple";
      moduleId: string;
      subRoleIds: string[];
      oneIsRequired: boolean;
      subTitle: Record<string, string>;
    }
  | {
      moduleType: "RoleSpecificity";
      subTitle: Record<string, string>;
      roleIsSpecificText?: Record<string, string>;
      roleIsNotSpecificText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
      roleIds: string[];
      moduleId: string;
    }
  | {
      moduleType: "StaffResponsibility";
      subTitle: Record<string, string>;
      staffIsRequiredText?: Record<string, string>;
      staffIsNotRequiredText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
      moduleId: string;
    }
  | {
      moduleType: "IndustryGroupRequired";
      subTitle: Record<string, string>;
      groupName?: Record<string, string>;
      groupIsImportantText?: Record<string, string>;
      groupIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
      industryIds: string[];
      moduleId: string;
    }
  | {
      moduleType: "DomainGroupRequired";
      subTitle: Record<string, string>;
      groupName?: Record<string, string>;
      groupIsImportantText?: Record<string, string>;
      groupIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
      domainIds: string[];
      moduleId: string;
    }
  // ------TASKS-------
  // --------REQUIRED--------
  | {
      moduleType: "TasksPickMultipleRequired";
      moduleId: string;
      taskIds: string[];
      subTitle: Record<string, string>;
    }
  | {
      moduleType: "TasksPickMultipleInVisualRequired";
      moduleId: string;
      taskIds: string[];
      subTitle: Record<string, string>;
      subTitleSingleTask: Record<string, string>;
      singleTaskIsImportantText?: Record<string, string>;
      singleTaskIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
    }
  // --------NOT REQUIRED--------
  | {
      moduleType: "TasksPickMultiple";
      moduleId: string;
      taskIds: string[];
      subTitle: Record<string, string>;
    }
  | {
      moduleType: "TasksPickMultipleInVisual";
      moduleId: string;
      taskIds: string[];
      subTitle: Record<string, string>;
      subTitleSingleTask: Record<string, string>;
      singleTaskIsImportantText?: Record<string, string>;
      singleTaskIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
    }
  // --------SKILLS--------
  // --------REQUIRED--------
  | {
      moduleType: "SkillsPickMultipleRequired";
      moduleId: string;
      skillIds: string[];
      subTitle: Record<string, string>;
    }
  | {
      moduleType: "SkillsPickMultipleInVisualRequired";
      moduleId: string;
      skillIds: string[];
      subTitle: Record<string, string>;
      subTitleSingleSkill: Record<string, string>;
      singleSkillIsImportantText?: Record<string, string>;
      singleSkillIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
    }
  | {
      moduleType: "SkillsPickMultipleInVisualRequiredV2";
      moduleId: string;
      skillIdBySkillIdInVisualDict: Record<string, string>;
      subTitle: Record<string, string>;
      subTitleSingleSkill: Record<string, string>;
      singleSkillIsImportantText?: Record<string, string>;
      singleSkillIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
    }
  | {
      moduleType: "SkillGroupRequired";
      groupName?: Record<string, string>;
      subTitle: Record<string, string>;
      groupIsImportantText?: Record<string, string>;
      groupIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
      onlyShowIfExistsInVisual?: boolean;
      skillIds: string[];
      moduleId: string;
      resultRowText: Record<string, string>;
    }
  | {
      moduleType: "MultipleSkillGroupsRequired";
      subTitle: Record<string, string>;
      skillGroups: {
        skillIds: string[];
        groupName: Record<string, string>;
        id: string;
        groupDescription: Record<string, string>;
      }[];
      moduleId: string;
      noOptionTitle?: string | null;
      noOptionDescription?: string | null;
    }
  // --------NOT REQUIRED--------
  | {
      moduleType: "SkillsPickMultiple";
      moduleId: string;
      skillIds: string[];
      subTitle: Record<string, string>;
    }
  | {
      moduleType: "SkillGroup";
      groupName?: Record<string, string>;
      subTitle: Record<string, string>;
      groupIsImportantText?: Record<string, string>;
      groupIsNotImportantText?: Record<string, string>;
      buttonOrderIsReversed?: boolean;
      skillIds: string[];
      moduleId: string;
      resultRowText: Record<string, string>;
    }
  | {
      moduleType: "MultipleSkillGroups";
      subTitle: Record<string, string>;
      skillGroups: {
        skillIds: string[];
        groupName: Record<string, string>;
        id: string;
        groupDescription: Record<string, string>;
      }[];
      moduleId: string;
      noOptionTitle?: string | null;
      noOptionDescription?: string | null;
    }

  // --------REMOVED--------
  | {
      moduleType: "Salary";
      bonusHint?: StepperModuleHint | null;
    }
  | {
      moduleType: "ExperienceYears";
    }
  | {
      moduleType: "Language";
    }
  | {
      moduleType: "Education";
      subTitle: Record<string, string>;
    }
  | {
      moduleType: "Exit";
      moduleId: string;
      askAboutFeedback: boolean;
      feedBackText: Record<string, string>;
      subTitle: Record<string, string>;
    }
);

export interface SourcingRobotRequirementsObject {
  recruitmentId: string;
  isStopped: boolean;
  isBreaked: boolean;
  requirements: SourcingRobotRequirement[];
  isManual: boolean;
  shouldBeRunInitially: boolean;
}
export interface ExitedSourcingRobot {
  recruitmentId: string;
  requirements: SourcingRobotRequirement[];
}

export type SourcingRobotRequirement =
  | {
      requirementType: "SubRole";
      roleId: string;
      moduleId: string;
      subRoleIds: string[];
    }
  | {
      requirementType: "SkillsPreferred";
      skillIds: string[];
    }
  | {
      requirementType: "SkillGroupRequiredNew";
      skillIds: string[];
      isRequired: boolean;
      skillGroupIds: string[];
    }
  | {
      requirementType: "Exit";
      moduleId: string;
      feedbackText: string | null;
    }
  | {
      requirementType: "TaskGroupRequired";
      taskIds: string[];
      isRequired: boolean;
      taskGroupIds: string[];
    }
  | {
      requirementType: "TasksPreferred";
      taskIds: string[];
    }
  | {
      requirementType: "Salary";
      salary: number;
      bonus: number;
    }
  | {
      requirementType: "ExperienceYears";
      max: number | null;
      min: number;
    }
  | {
      requirementType: "SearchTags";
      searchTagCodes: string[];
    }
  | {
      requirementType: "Language";
      languageIdsWithRequired: { [key: string]: boolean };
    }
  | {
      requirementType: "Education";
      minimumYearsOfEducation: number;
    }
  | {
      requirementType: "RoleSpecificity";
      moduleId: string;
      roleIsSpecific: boolean;
    }
  | {
      requirementType: "RoleSpecificity2";
      roleIds: string[];
    }
  | {
      requirementType: "StaffResponsibility";
      staffIsRequired: boolean;
    }
  | {
      requirementType: "StaffResponsibilityPreferred";
    }
  | {
      requirementType: "IndustryGroupRequired";
      moduleId: string;
      groupIsImportant: boolean;
    }
  | {
      requirementType: "DomainGroupRequired";
      moduleId: string;
      groupIsImportant: boolean;
    };

export interface TalentRoleWithTasks {
  roleId: string;
  taskIds: string[];
}

export interface ExperienceResponsibility {
  responsibility: RoleResponsibility;
  level: number;
}

export interface CompletionListItem {
  id: CompletionListType;
  text: string;
  isDone: boolean;
}

export type CompletionListType =
  | "EMPLOYMENTS"
  | "TASKS"
  | "SKILLS"
  | "EDUCATION"
  | "LANGUAGES";

export interface CompanyListObject extends ListObject {
  branchId?: string | null;
  industryId?: string | null;
}

export enum CompanyRecommendationType {
  NonConsultantFirms = 1,
  ConsultantFirms = 2,
  InternFirms = 3,
  CustomerFirms = 4,
}
export interface GetRecommendedCompaniesDto extends ListObject {
  branchId: string;
  recommended: CompanyRecommendationType[];
}

export interface EducationDomainListObject extends ListObject {
  category: string;
}

export interface ListItem {
  value: number;
  text: string;
}

export interface GritifyNewsItem {
  id: string;
  date: Date;
  title: string;
  description: string;
  imageUrl: string | null;
  icon: string;
}

export interface GetNotificationsDto {
  companyId: string;
  notificationType: NotificationType;
  userId: string;
  senderId: string | null;
  locationType: TagLocation;
  talentId: string | null;
  recruitmentId: string | null;
  talentName: string | null;
  email: string | null;
  text: string | null;
  date: string;
  unhandledTalents: ListObject[];
  isRead: boolean;
  isSeen: boolean;
  id: string;
}

export interface GetNotificationsResponse {
  token: string | null;
  notifications: GetNotificationsDto[];
}

export enum VisualCompletenessType {
  AnnouncementTitle,
  Image,
  Ingress,
  Manager,
  InterviewText,
  Tasks,
}

export interface VisualCompletenessListItem {
  type: VisualCompletenessType;
  complete: boolean;
}

export interface GoogleLatLng {
  lat: number;
  lng: number;
}

export type SnackBarMessageType =
  | "error"
  | "success"
  | "warning"
  | "notification";

export enum TrelloBoardActiveTabType {
  Active = 0,
  Removed = 1,
  Declined = 2,
}

export enum PublishProfileValidationError {
  Logo = 1,
  CoverImage = 2,
  BusinessAreas = 3,
  InShort = 4,
  Team = 5,
  Offices = 6,
  PeopleStatistics = 7,
  CompanyIngress = 8,
}

export interface PublishProfileResponse {
  successfull: boolean;
  validationErrors: PublishProfileValidationError[] | null;
}

export type CompanyVideoProgressState =
  | "Error"
  | "Finished"
  | "NoVideo"
  | "Canceling"
  | "Processing"
  | "Queued"
  | "Scheduled";

export interface CompanyVideoProgress {
  state: CompanyVideoProgressState;
  progress: number;
  forId: string | null;
}

export enum ImageEntityType {
  CoWorker = 1,
  Office = 2,
  Department = 3,
  CompanyProfile = 4,
  CompanyLogo = 5,
  JobOffer = 6,
}

export enum ProfileDiffType {
  CoverImage,
  LogoIncludesName,
  Ingress,
  BusinessAreas,
  CompanyValuesList,
  CompanyValuesVisible,
  CompanyValuesImage,
  milestonesList,
  milestonesVisible,
  team,
  offices,
  inNumbers,
  rolesList,
  rolesVisible,
  companyVideo,
  socialMedia,
  businessAreaType,
  teamVisible,
}

export interface UserDetails {
  firstName: string | null;
  lastName: string | null;
  lang: string;
  companies: ListObject[];
  defaultCompanyId: string | null;
}

export enum UnsavedChangesResult {
  Cancel = 1,
  DiscardChanges = 2,
  SaveChanges = 3,
}

export interface CompanyVideoDTO {
  videoUrl: string | null;
  id: string;
  name: string;
  isRemoved: boolean;
  progress: number;
  state: CompanyVideoProgressState;
}

export interface CompanyVideoDTODB {
  videoUrl: string | null;
  id: string;
  name: string;
  isRemoved?: boolean;
  progress?: number;
  state?: CompanyVideoProgressState;
}

export interface YoutubeVideoDto {
  url: string;
  name: string;
}

export interface CompareTalentDto {
  talentId: string;
  tasks: { [key: string]: boolean };
  skills: { [key: string]: boolean };
  languagesById: { [key: string]: boolean };
  monthsByRole: { [key: string]: number };
  monthsByBranch: { [key: string]: number | null };
  employmentStrings: {
    companyName: string;
    titleName: string;
    employmentId: string;
  }[];
  totalNumberOfYears: number;
  salary: number;
  salaryCurrency: string;
  requireNoTrips: boolean;
  requireWorkFromHome: WorkFromHome;
  requireResponsibilityResult: boolean;
  requireResponsibilityStaff: boolean;
  requireResponsibilityProject: boolean;
  requiredRoleNames: string;
  requiredCityNames: string;
  requireStableSalary: boolean;
  languages: string[];
  resultResponsibility: ExperienceResponsibility | null;
  staffResponsibility: ExperienceResponsibility | null;
  projectResponsibility: ExperienceResponsibility | null;
  educations: EducationSaved[];
  talentName: string | null;
  employments: (EmploymentSaved | EmploymentAnonymous)[];
  rating: number | null;
}

export enum LaneType {
  Review = 2,
  Interview = 3,
  Test = 4,
  Custom = 5,
  Hired = 10,
  Inbox = 11,
  ReviewVirtaul = 12,
  References = 13,
  Offers = 14,
}

export interface LaneForLibrary extends LaneForLibraryToAdd {
  id: string;
  initialSortOrder: number;
}
export interface LaneForLibraryToAdd {
  text: string;
  onNew: boolean;
  laneType: LaneType;
  removed: boolean;
  mandatory: boolean;
}

export interface EditRecruitmentLanesLane {
  id: string;
  text: string;
  canRemove: boolean;
  containsTalents: boolean;
  canMove: boolean;
  laneType: LaneType;
}

export interface SignalRTrelloTalent {
  type: "Talent";
  document: RecruitmentTalentObject;
  companyId: string;
}
export interface SignalRTrelloTalentWithDaysLeft {
  type: "TalentWithDaysLeft";
  document: RecruitmentTalentWithDaysLeftDto;
  companyId: string;
}
export interface SignalRCompanyTalent {
  type: "CompanyTalent";
  document: CompanyTalent;
  companyId: string;
}

export interface SignalRTalentMessage {
  type: "Message";
  talentId: string;
  recruitmentId: string;
  document: GetTalentMessageDto;
}
export interface SignalRTalentPoolMessage {
  type: "TalentPoolMessage";
  document: GetPoolMessagesDtoWithStringDates;
}
export interface SignalRTeamComment {
  type: "Comment";
  talentId: string | null;
  recruitmentId: string;
  document: GetTeamCommentDto;
}
export interface SignalRRecruitment {
  type: "Recruitment";
  document: GetRecruitmentListItemDto;
  companyId: string;
}
export interface SignalRNotification {
  type: "Notification";
  document: GetNotificationsDto;
  companyId: string;
}

export interface SignalROffice {
  type: "Office";
  document: Office;
  companyId: string;
}

export interface SignalRDepartment {
  type: "Department";
  document: JobOfferDepartment;
  companyId: string;
}

export interface SignalRCoWorker {
  type: "CoWorker";
  document: CoWorkerDto;
  companyId: string;
}
export interface SignalRPoolMessage {
  type: "TalentPoolMessage";
  document: GetPoolMessagesDtoWithStringDates;
  companyId: string;
}
export interface SignalRLane {
  type: "Lane";
  document: null;
  companyId: string;
}

export interface SignalRCompanyVideo {
  type: "Video";
  document: CompanyVideoDTODB;
  companyId: string;
}

export interface SignalRYoutubeVideo {
  type: "YoutubeVideo";
  document: YoutubeVideoDto;
  companyId: string;
}

export interface SignalRLibraryPhoto {
  type: "Photo";
  document: GetLibraryPhotoDto;
  companyId?: string;
}
export interface SignalRCompanyTeamMember {
  type: "User";
  document: GetRecruitmentTeamMemberDto;
  companyId: string;
}

export interface SignalRWorkingCompanyProfile {
  type: "WorkingProfile";
  document: GetWorkingProfileDto | null;
  companyId: string;
}

export interface SignalPublishedCompanyProfile {
  type: "PublishedProfile";
  document: PublishedCompoanyProfileDto | null;
  companyId: string;
}
export interface SignalRBaseInfo {
  type: "BaseInfo";
  recruitmentId: string;
  document: JobbOfferBaseInfo;
  companyId: string;
}
export interface SignalRVisual {
  type: "Visual";
  recruitmentId: string;
  etag: string;
  document: JobOfferVisaulToUpdate;
}

export interface SignalRTalentJobOffer {
  type: "JobOffer";
  document: GetJobbOfferListDto;
}
export interface SignalRTalentSearchProfile {
  type: "SearchProfile";
  document: {
    isSearchable: boolean;
  };
}
export interface SignalROpportunitySourcing {
  type: "OpportunitySourcing";
  recruitmentId: string;
  etag: string;
}
export interface SignalRPoolTeamMessage {
  type: "TalentPoolTeamComment";
  document: TalentPoolTeamCommentFromBackend;
}

export type SignalRForTalent =
  | SignalRTalentJobOffer
  | SignalRTalentMessage
  | SignalRTalentPoolMessage
  | SignalRTalentSearchProfile;

export enum WorkFromHomeEditBaseInfo {
  OneDay = 1,
  TwoDays = 2,
  ThreeDays = 3,
  FourDays = 4,
  AllWeek = 5,
  FullRemote = 6,
  No = -1,
}

export type SingalRMessage =
  | SignalRTrelloTalent
  | SignalRTrelloTalentWithDaysLeft
  | SignalRTalentMessage
  | SignalRRecruitment
  | SignalRNotification
  | SignalRCompanyTalent
  | SignalROffice
  | SignalRDepartment
  | SignalRCoWorker
  | SignalRLane
  | SignalRCompanyVideo
  | SignalRYoutubeVideo
  | SignalRLibraryPhoto
  | SignalRCompanyTeamMember
  | SignalRWorkingCompanyProfile
  | SignalPublishedCompanyProfile
  | SignalRBaseInfo
  | SignalRVisual
  | SignalROpportunitySourcing
  | SignalRPoolMessage
  | SignalRPoolTeamMessage
  | SignalRTeamComment
  | {
      type: "TalentConversation";
      document: TalentConversationRaw;
    }
  | {
      type: "GetVisualIngressAiHelpChunk";
      document: {
        text: string;
        recruitmentId: string;
      };
    }
  | {
      type: "GetVisualHeadingAiHelpChunk";
      document: {
        text: string;
        recruitmentId: string;
      };
    }
  | {
      type: "GetVisualTasksAiHelpChunk";
      document: {
        text: string;
        recruitmentId: string;
      };
    }
  | {
      type: "GetChatMessageAIHelpChunk";
      document: {
        text: string;
        recruitmentId: string | null;
        talentId: string;
      };
    };

export interface GetLibraryPhotoDto {
  url: string;
  fileName: string;
  uniqueName: string | null;
  deleted?: string;
}

export interface RecruitmentImagePackToAdd {
  companyId: string;
  bannerImageUrl: string | null;
  cardImageUrl: string | null;
  defaultBannerColor: string | null;
}
export interface RecruitmentImagePackObject extends RecruitmentImagePackToAdd {
  id: string;
}

export interface ComparePanelRow {
  rowTitle: string;
  rowData: any;
  getCompareValue: (t: CompareTalentDto) => any;
}

export enum TalentAction {
  Interest = 2,
  Accept = 4,
  Decline = 6,
  NotInterested = 7,
}

export enum RecruitmentCompanyAction {
  Save = 1,
  Invite = 3,
  Remove = 5,
  Decline = 6,
}

export interface AcceptInviteParams {
  action: TalentAction.Accept | TalentAction.Interest;
  companyId: string;
  recruitmentId: string;
  name: string;
}
export interface DeclineInviteParams {
  action: TalentAction.Decline;
  companyId: string;
  recruitmentId: string;
  declineReasonIds: string[];
  declineReasonTypes: string[];
  otherReason: string | null;
  ratingCompanyProcent: number;
  ratingJobProcent: number;
}
export interface NotInterestedTalentActionParams {
  action: TalentAction.NotInterested;
  companyId: string;
  recruitmentId: string;
}

export type CreateTalentActionParams =
  | AcceptInviteParams
  | DeclineInviteParams
  | NotInterestedTalentActionParams;

export interface CompanyObject {
  companyId: string;
  id: string;
  companyName: string;
  branchId: string;
  companyEmail: string | null;
  mainComtactEmail: string;
  blockedCompaniesForSourcing: ListObjectNullable[];
  teamTailorApiKey: string | null;
  blockedTalentIds: string[];
  isStartUpScaleUp?: boolean;
  isPublicSector?: boolean;
  isConsultingCompany?: boolean;
  showNewCompanyStepper?: boolean;
  dontShowATSTips?: boolean;
  lang: string | null;
  jobylonKeys?: {
    feedhash: string;
    appKey: string;
    appId: string;
  } | null;
  jobylonIntegrationRequestObj: JobylonIntegrationRequest | null;
  pricingModel: PricingModels | null;
  accountInfo?: {
    invoiceEmail: string;
    contactName: string;
    orgNumber: string;
    agreeDate: Date;
  } | null;
  pricing?: {
    freeHires: number;
    discountSuccess: number;
    accessFee: number | null;
    fixedPricePerMonth: number | null;
  };
}

export enum PricingModels {
  Explore = 1,
  AccessSuccess = 2,
  FixedPrice = 3,
}

export interface JobylonIntegrationRequest {
  UserId: string;
  RequestedAt: string;
}

export interface UpsertQuarterlyUpdateRegistrationDto {
  email: string;
  registrationType: "FromBanner" | "FromReport" | "FromQrCode";
}

export enum RecruitmentTalentStatus {
  Saved,
  Invited,
  Accepted,
  Removed,
  Declined,
  Hired,
  Other,
}
export enum LibraryResourceType {
  Coworker = 1,
  Office = 2,
  Department = 3,
}

export type CurrancyTypes = "SEK" | "USD" | "EUR";

export interface ExternalTalentExperience {
  employments: EmploymentSaved[];
  education: EducationSaved[];
  hasNoEducation: boolean;
  hasCostResponsibility: boolean;
  costResonsibilityNumber: number | null;
  costResonsibilityCurrancy: CurrancyTypes;
  hasProjectResponsibility: boolean;
  projectResonsibilityNumber: number | null;
  projectResonsibilityCurrancy: CurrancyTypes;
  hasStaffResponsibility: boolean;
  staffResonsibilityInPersons: number | null;
  languageIds: string[];
  branchIds: string[];
}

export interface ScoredProfile {
  talentId: string;
  skillScore: number;
  taskScore: number;
  deomainScore: number;
  titleScore: number;
  totalScore: number;
  preferenceRoleScore: number;
  searchTagScore: number;
}

export interface ExternalTalentProfileDto {
  firstName: string | null;
  lastName: string | null;
  contactEmail?: string | null;
  phoneNumber?: string | null;
  taskLang: string | null;
  experience: ExternalTalentExperience;
  tasksAndSkills: TalentTasksAndSkills;
  isEmployeeToday: boolean;
}

export interface ExternalTalentProfileToAdd extends ExternalTalentProfileDto {
  firstName: string;
  lastName: string;
  contactEmail: string;
  phoneNumber: string | null;
  taskLang: string | null;
  experience: ExternalTalentExperience;
  tasksAndSkills: TalentTasksAndSkills;
  otherQuestionSections: TalentQuestionSection[];
  recruitmentId: string;
  companyId: string;
}

export interface ExternalTalentProfileSaved extends ExternalTalentProfileToAdd {
  id: string;
}

export interface ExternalTalentForFiltering {
  id: string;
  skills: ListObject[];
  distinctTaskIds: string[];
  taskIdsDict: { [key: string]: boolean };
  skillIdsDict: { [key: string]: boolean };
  numberOfWorkYears: number;
  questions: { [key: string]: TalentQuestionUnion };
  isEmployeeToday: boolean;
  responsibility: { [key: string]: boolean };
  educationDegree: EducationDegrees;
  languageIdsDistinct: string[];
  languageIdsDict: { [key: string]: boolean };
  projectMKR: number;
  staffPersons: number;
  costMKR: number;
}
export interface TalentForFilterComponentDto {
  id: string;
  skills: ListObject[];
  distinctTaskIds: string[];
  taskIdsDict: { [key: string]: boolean };
  skillIdsDict: { [key: string]: boolean };
  recruitments: ListObject[];
  recruitmentIdsDict: { [key: string]: boolean };
  numberOfWorkYears: number;
  responsibility: { [key: string]: boolean };
  educationDegree: EducationDegrees;
  languageIdsDistinct: string[];
  languageIdsDict: { [key: string]: boolean };
  projectMKR: number;
  staffPersons: number;
  costMKR: number;
}

export type ResponsibilityTypes = "costs" | "projects" | "staff";

export interface GetWeightedSkillsForRoleDto {
  roleId: string;
  endDate: Date | null;
  startDate: Date;
}

interface TalenQuestionBase {
  id: string;
  questionText: { [key: string]: string };
  description?: { [key: string]: string } | null;
  required: boolean;
}

export interface TalentQuestionOption {
  questionText: { [key: string]: string };
  value: string;
  goToSectionType?: GoToSectionType | null;
}

export interface TalentQuestionShortAnswer extends TalenQuestionBase {
  type: "ShortAnswer";
  stringValue: string | null;
}
export interface TalentQuestionLongAnswer extends TalenQuestionBase {
  type: "LongAnswer";
  stringValue: string | null;
}
export interface TalentQuestionLinearScale extends TalenQuestionBase {
  type: "LinearScale";
  numberValue: number | null;
  minNumber: 1 | 0;
  maxNumber: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  labelMin: string | null;
  labelMax: string | null;
}
export interface TalentQuestionMultipleChoice extends TalenQuestionBase {
  type: "MultipleChoice";
  options: TalentQuestionOption[];
  otherOption: boolean;
  optionAnswer: string | null;
  otherAnswer: string | null;
  otherOptionGoToSectionType?: GoToSectionType | null;
  goToSection?: boolean;
}
export interface TalentQuestionCheckboxes extends TalenQuestionBase {
  type: "Checkboxes";
  options: TalentQuestionOption[];
  otherOption: boolean;
  optionAnswers: string[];
  otherAnswer: string | null;
}

export interface GoToSectionTypeSection {
  type: "GoToSection";
  sectionId: string;
}
export interface GoToSectionTypeSubmit {
  type: "SubmitForm";
}

export type GoToSectionType = GoToSectionTypeSection | GoToSectionTypeSubmit;

export interface TalentQuestionSection {
  id: string;
  title: { [key: string]: string };
  subTitle: { [key: string]: string } | null;
  questions: TalentQuestionUnion[];
  goToSectionType?: GoToSectionType | null;
}

export type TalentQuestionUnion =
  | TalentQuestionShortAnswer
  | TalentQuestionLongAnswer
  | TalentQuestionMultipleChoice
  | TalentQuestionLinearScale
  | TalentQuestionCheckboxes;

export interface ExternalTalentQuestionsObject {
  id: string;
  recruitmentId: string;
  questionSections: TalentQuestionSection[];
}

export interface ExternalTalentFilterTasks {
  type: "Tasks";
  useAnd: boolean;
  ids: string[];
}
export interface ExternalTalentFilterSkills {
  type: "Skills";
  useAnd: boolean;
  ids: string[];
}
export interface ExternalTalentFilterEducationDegrees {
  type: "EducationDegrees";
  idsNumberDict: { [key: number]: boolean | undefined };
}
export interface ExternalTalentFilterLanguages {
  type: "Languages";
  useAnd: boolean;
  ids: string[];
}
export interface ExternalTalentFilterResponsibilities {
  type: "Responsibilities";
  useAnd: boolean;
  ids: string[];
}

export interface ExternalTalentFilterEmplyeeTodayQuestion {
  type: "EmployeeTodayQuestion";
  ids: string[];
}
export interface ExternalTalentFilterMultiQuestion {
  type: "MultipleChoiceQuestion";
  questionId: string;
  idsStringDict: { [key: string]: boolean | undefined };
}
export interface ExternalTalentFilterLinearScaleQuestion {
  type: "LinearScaleQuestion";
  questionId: string;
  idsNumberDict: { [key: number]: boolean | undefined };
}

export interface TalentFilterRangeDto {
  min: number;
  max: number;
}
export interface ExternalTalentFilterWorkYears extends TalentFilterRangeDto {
  type: "WorkYears";
}
export interface ExternalTalentFilterProject extends TalentFilterRangeDto {
  type: "Project";
}
export interface ExternalTalentFilterCost extends TalentFilterRangeDto {
  type: "Cost";
}
export interface ExternalTalentFilterStaff extends TalentFilterRangeDto {
  type: "Staff";
}

export type ExternalTalentFilter =
  | ExternalTalentFilterTasks
  | ExternalTalentFilterSkills
  | ExternalTalentFilterMultiQuestion
  | ExternalTalentFilterLinearScaleQuestion
  | ExternalTalentFilterEmplyeeTodayQuestion
  | ExternalTalentFilterResponsibilities
  | ExternalTalentFilterEducationDegrees
  | ExternalTalentFilterLanguages
  | ExternalTalentFilterProject
  | ExternalTalentFilterCost
  | ExternalTalentFilterStaff
  | ExternalTalentFilterWorkYears;

export type TalentFilterItemTileDto =
  | {
      type: "Task";
      id: string;
      text: string;
    }
  | {
      type: "Skill";
      id: string;
      text: string;
    }
  | (TalentFilterRangeDto & { type: "WorkYears"; totalMax: number; id: string })
  | {
      type: "Recruitment";
      id: string;
      text: string;
    };
export type RecruitmentFilterItemTileDto =
  | {
      type: "Role";
      id: string;
      text: string;
    }
  | {
      type: "Office";
      id: string;
      text: string;
    }
  | {
      type: "OfficeRemote";
    }
  | {
      type: "Status";
      filter: RecruitmentFilterItem & { type: "Status" };
    }
  | {
      type: "TitleContains";
      filter: RecruitmentFilterItem & { type: "TitleContains" };
    };

export type RecruitmentStatusType = "Active" | "Removed";

export type RecruitmentFilterItem =
  | {
      type: "Roles";
      roleIds: string[];
    }
  | {
      type: "Offices";
      officeIds: ({ isRemote: false; officeId: string } | { isRemote: true })[];
    }
  | { type: "Status"; statuses: RecruitmentStatusType[] }
  | { type: "RobotStatus"; statuses: ("Active" | "Paused" | "NotStarted")[] }
  | {
      type: "Users";
      users: ({ type: "User"; userId: string } | { type: "NoUser" })[];
    }
  | { type: "TitleContains"; text: string };

export const recruitmentFilterOptionsArray = [
  "ActiveRecruitments",
  "ActiveRobots",
  "ClosedRecruitments",
  "MyRecruitments",
] as const;

export type RecruitmentFilterOptions = typeof recruitmentFilterOptionsArray[number];

export const recruitmentSortOptionsArray = [
  "CreatedDate",
  "Interested",
  "Active",
  "Office",
  "Price",
  "State",
] as const;

export type RecruitmentSortOptions = typeof recruitmentSortOptionsArray[number];

export type TalentFilterItem =
  | {
      type: "Tasks";
      useAnd: boolean;
      ids: string[];
    }
  | {
      type: "Skills";
      useAnd: boolean;
      ids: string[];
    }
  | {
      type: "Recruitment";
      useAnd: boolean;
      ids: string[];
    }
  | {
      type: "Responsibilities";
      useAnd: boolean;
      ids: string[];
    }
  | {
      type: "EducationDegrees";
      idsNumberDict: { [key: number]: boolean | undefined };
    }
  | {
      type: "Languages";
      useAnd: boolean;
      ids: string[];
    }
  | ((
      | {
          type: "Project";
        }
      | {
          type: "Cost";
        }
      | {
          type: "Staff";
        }
      | {
          type: "WorkYears";
        }
    ) &
      TalentFilterRangeDto);

export interface CheckboxFilterItem {
  text: string;
  id: string;
  numberOfTalents: number;
}

export interface RangeFilerDetails {
  min: number;
  max: number;
  unit: string;
  tiles: number[];
}

export interface QuestionToFilterBase {
  title: string;
  questionId: string;
  items: CheckboxFilterItem[];
}

export interface QuestionToFilterMulti extends QuestionToFilterBase {
  type: "Multi";
}
export interface QuestionToFilterLinearScale extends QuestionToFilterBase {
  type: "LinearScale";
  minLabel: string | null;
  maxLabel: string | null;
}

export type QuestionToFilter =
  | QuestionToFilterMulti
  | QuestionToFilterLinearScale;

export interface LangObject {
  id: string;
  textDict: Record<string, string>;
}

export interface PublicTalentContactInfoDto {
  firstName: string;
  lastName: string;
  contactEmail: string;
  phoneNumber: string;
  understandRespondImportance: boolean;
  inviterId: string;
}

export interface PublicJobOfferDto {
  companyProfile: PublishedCompoanyProfileDto;
  jobOffer: GetJobbOfferListDto;
  talentInfo: PublicTalentContactInfoDto;
  talentIsInPool: boolean;
  emailPreferences: {
    column1: string[];
    column2: string[];
  };
}
export interface GetPublicTalentJobOfferDto {
  companyProfile: PublishedCompoanyProfileDto;
  jobOffer: GetJobbOfferListDto;
  otherJobOffers: GetJobbOfferListDto[];
  emailPreferences: {
    column1: string[];
    column2: string[];
  };
  firstName: string;
  lastName: string;
  contactEmail: string;
  phoneNumber: string;
  salary?: number | null;
  understandRespondImportance: boolean;
  inviterId: string;
  isInPool: boolean;
  expectations?: TalentExpectations;
}

export enum EmploymentTypeInternal {
  Employee = 1,
  SelfEmployed = 2,
  Intern = 3,
}
export interface TodoListItemCompanyProfile {
  type: "CompanyProfile";
}

export interface TodoListItemAddRecruitmentImage {
  type: "AddRecruitmentImage";
}

export interface TodoListItemAddLogo {
  type: "AddLogo";
}

export type CompanyTodoListItem = (
  | TodoListItemCompanyProfile
  | TodoListItemAddLogo
) & {
  durationInMinutes: number;
};

export enum RequiredCompanyProfileItem {
  ShortDescription,
  InNumbers,
}

export enum CompanyProfileSection {
  ShortDescription,
  BusinessAreas,
  InNumbers,
  CompanyValues,
  History,
  Coworkers,
  Locations,
  Roles,
  SocialMedia,
}

export enum EmploymentStepType {
  ConnectWithLinkedIn,
  LinkedInUrl,
  LinkedInFetchFailed,
  SelectLinkedInEmployment,
  EmploymentType,
  CurrentEmploymentYesNo,
  ConsultantYesNo,
  Employer,
  CustomersYesNo,
  ConsultingCustomers,
  Industry,
  Title,
  Role,
  Tasks,
  EmploymentStartYear,
  EmploymentStartMonth,
  EmploymentEndYear,
  EmploymentEndMonth,
}
export enum TalentPreferenceStepType {
  Salary,
  Location,
}
export enum EducationStepType {
  EducationDegree,
  EducationNumberOfYears,
  SchoolListObjects,
  SchoolString,
  EducationDomain,
  EducationDomainString,
  StudyAbroadYesOrNo,
  AbroadSchool,
}

export enum StepType {
  LinkedInYesOrNo,
  LinkedInUrl,
  LinkedInFetchInfo,
  EmploymentType,
  ConsultantYesNo,
  Employer,
  CustomersYesNo,
  ConsultingCustomers,
  Industry,
  Title,
  Role,
  Tasks,
  EmploymentStartYear,
  EmploymentStartMonth,
  EmploymentEndYear,
  EmploymentEndMonth,
  EmploymentTypeOrNotWorking,
  NumberOfYearsWorkExperience,
  Skills,
  ResponsibilityQuestion,
  StaffResponsibilityAnswer,
  ProjectResponsibilityAnswer,
  BudgetResponsibilityAnswer,
  Languages,
  AnyStudies,
  EducationDegree,
  EducationNumberOfYears,
  // ExamYear,
  // NoExamYear,
  // HighschoolEndYear,
  SchoolListObjects,
  SchoolString,
  EducationDomain,
  EducationDomainString,
  StudyAbroadYesOrNo,
  AbroadSchool,
  RolePreferences,
  OtherPreferences,
}

export interface TalentExperienceSectionDto {
  name: string;
  steps: StepType[];
  percent: number;
}
export interface TalentExpectationsMatchListItem {
  text: string;
  isMatched: boolean;
  showSalaryInfo?: boolean;
}

export interface BranchLookupDto {
  id: string;
  text: string;
  selectableForSelfEmployed: boolean;
}

export type IndustryLookupDto = {
  id: string;
  text: string;
  linkedInId: string;
  parentId?: string;
  titleRecommendations?: string[];
  selectableForSelfEmployed?: boolean;
};

export type TalentPoolTalent = {
  talentId: string;
  id: string;
  dateCreated: Date;
  preferenceRoleIds: string[];
  requireWorkFromHome: WorkFromHome;
  accepted: Date | null;
  declined: Date | null;
  invited: Date | null;
  removed: Date | null;
  requireNoTrips: boolean;
  requireNoConsultants: boolean;
  requireNoStartups: boolean;
  requireNoPublicSectors: boolean;
  responsibilityMusts: RoleResponsibility[];
  locationIds: string[];
  salary: number;
  requireStableSalary: boolean;
  responsibilityExperience: Record<RoleResponsibility, number> | null;
  skillIds: string[];
  taskIds: string[];
  highestEducationDegree: EducationDegrees | null;
  yearsOfExperience: number;
  phoneNumber?: string | null;
  languageIds: string[];
  latestRoleId: string | null;
  firstName: string | null;
  lastName: string | null;
  number: number;
  rejectedCompanyIds: string[];
  rating: number | null;
  latestEmployments: EmploymentSaved[];
  declinedInRecruitmentId: string | null;
};

export type TalentInviteFailReason =
  | "RecruitmentIsClosed"
  | "TalentIsPaused"
  | "NoLogo"
  | "CompanyProfileIsNotDone"
  | "RecruitmentIsNotStarted"
  | "RecruitmentDoesNotExist";

export type TalentRecruitmentMissmatch =
  | {
      type: "Role";
    }
  | {
      type: "Responsibility";
    }
  | {
      type: "Location";
    }
  | {
      type: "Saved";
      talentId: string | null;
    }
  | {
      type: "WorkFromHome";
      workFromHome: WorkFromHome;
    }
  | {
      type: "Travel";
    }
  | { type: TalentInviteFailReason };

export type LocationDto = { id: string } & (
  | {
      type: "Remote";
    }
  | {
      type: "Other";
      polygon: [number, number][];
    }
);

export interface GetLocationsForRecruitmentMatchingDto {
  id: string;
  locationType: string;
  geometry: {
    coordinates: [number, number][][];
  };
}

export interface GetFriendInvitationDataDto {
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  recruitmentTitle: string | null;
  inviterEmail: string;
  talentId: string;
}

export interface TalentUserObject {
  inviterId: string;
  invitedByTalentId: string | null;
  denySms: boolean;
}

export interface GetTalentProfileForTalentDto {
  talentUser: TalentUserObject | null;
  talentProfileObject: TalentProfileDto;
}

export type GetTalentPoolsForTalentDtoWithDates = {
  accepted: Date | null;
  declined: Date | null;
  removed: Date | null;
  invited: Date | null;
  companyId: string;
  cardImageUrl: string | null;
  bannerImageUrl: string | null;
  defaultBannerColor: string | null;
  companyName: string | null;
  logoUrl: string | null;
  branchId: string | null;
  isRemoveInformed: boolean;
};

export type DateReplacer<T> = {
  [P in keyof T]: T[P] extends Date
    ? string
    : T[P] extends Date | null
    ? string | null
    : DateReplacer<T[P]>;
};

export type Replacer<T, Search, Replace> = {
  [P in keyof T]: T[P] extends Search
    ? Replace
    : Replacer<T[P], Search, Replace>;
};

export type GetTalentPoolsForTalentDto = DateReplacer<
  GetTalentPoolsForTalentDtoWithDates
>;

export type GetPoolTalentsForCompanyDto = DateReplacer<
  GetPoolTalentsForCompanyDtoWithDates
>;

export type GetPoolTalentsForCompanyDtoWithDates = {
  accepted: Date | null;
  declined: Date | null;
  dateCreated: Date;
  companyId: string;
  talentId: string;
  number: number;
  invited: string | null;
  removed: string | null;
  firstName: string | null;
  lastName: string | null;
  taskIds: string[];
  skillIds: string[];
  yearsOfExperience: number;
  latestEmployments: EmploymentSaved[];
  requirements: {
    salary: number;
    salaryCurrency: string;
    requireStableSalary: boolean;
    requireNoTrips: boolean;
    requireWorkFromHome: WorkFromHome;
    locationIds: string[];
    responsibilityMustIds: RoleResponsibility[];
    roleIds: string[];
    rejectedCompanyIds: string[];
    requireNoConsultants: boolean;
    requireNoStartups: boolean;
    requireNoPublicSectors: boolean;
  } | null;
  rating: number | null;
  declinedInRecruitmentId: string | null;
};

export interface TalentPoolListCardDto {
  numberOfNewMessages: number;
  imageUrl: string;
  defaultBannerColor: string | null;
  companyName: string;
  logoUrl: string;
  id: string;
  branchId: string;
  accepted: Date | null;
  invited: Date | null;
}

export interface TalentPoolWrapperCardDto {
  logoUrl: string;
  imageUrl: string | null;
  defaultBannerColor: string | null;
  companyName: string;
  branchName: string;
  declined: Date | null;
}

// public string id { get; set; }
// public string locationType { get; set; }
// public PolygonGeometry geometry { get; set; }

// public class PolygonGeometry
// {
//     public string type { get; set; }
//     public List<List<List<decimal>>> coordinates { get; set; }
// }
export enum DeclineReasonType {
  NotInterestingCompany = 1,
  DontUnderstand = 2,
  NotOptimalNextStep = 3,
  LikeMyCurrentJob = 4,
  AlreadyChangedJobsRecently = 5,
}

export enum DeclineFollowUpActionType {
  RejectCompany = 1,
  InviteFriend = 2,
  TalentPool = 3,
  UpdateProfileExperience = 4,
  UpdateSalary = 5,
}
export interface UpdateTalentUserDto {
  denySms: boolean;
}
export interface GetDeclineReasonsDto {
  id: string;
  text: string;
  reasonType: DeclineReasonType | null;
}
export type GetNumberOfTalentsTestSearchDto = {
  roleId: string;
} & (
  | {
      type: "Remote";
    }
  | {
      type: "FromOffice";
      areaId: string;
      latitude: number;
      longitude: number;
    }
  | {
      type: "FromGoogle";
      latitude: number;
      longitude: number;
    }
);

export type InviteUserRequestDto = {
  user: RecruitmentTeamMemberToAdd;
  message: string | null;
} & (
  | ((
      | {
          type: "FromSearch";
          addressString: string;
        }
      | {
          type: "FromSearchRemote";
          isRemote: true;
        }
    ) & {
      numberOfTalents: number;
      roleName: string;
    })
  | {
      type: "RegularInvite";
    }
);

// export type GetPoolMessagesDtoWithStringDates = {
//   id: string;
//   date: string;
//   talentId: string;
//   companyId: string;
//   messageText: string;
// } & (
//   | {
//       type: "FromTalent";
//     }
//   | {
//       type: "FromCompany";
//       companySender: {
//         userId: string;
//         firstName: string;
//         lastName: string;
//       };
//     }
// );

export type GetPoolMessagesDto = {
  id: string;
  date: Date;
  talentId: string;
  companyId: string;
  messageText: string;
} & (
  | {
      type: "FromTalent";
    }
  | {
      type: "FromCompany";
      companySender: {
        userId: string;
        firstName: string;
        lastName: string;
      };
    }
);

export type GetPoolMessagesDtoWithStringDates = {
  id: string;
  date: string;
  talentId: string;
  companyId: string;
  messageText: string;
  companySender: {
    userId: string;
    firstName: string;
    lastName: string;
  } | null;
};

export interface GetPublicTalentPoolDataDto {
  talentPool: GetTalentPoolsForTalentDtoWithDates;
  companyProfile: PublishedCompoanyProfileDto;
  email: string | null;
  phoneNumber: string | null;
}

export type PoolTalentLog = {
  id: string;
  talentId: string;
  companyId: string;
  date: Date;
} & (
  | {
      logType: "PoolTalentInvited";
      userId: string;
    }
  | {
      logType: "PoolTalentRemoved";
      userId: string;
    }
  | {
      logType: "PoolTalentRating";
      userId: string;
      oldRating: number | null;
      newRating: number | null;
    }
  | {
      logType: "PoolTalentAcceptedLog";
    }
  | {
      logType: "PoolTalentDeclinedLog";
    }
);

export interface GetTokenByShortTokenDto {
  recruitmentId: string;
  token: string;
}

export interface GetSortedTalentsDto {
  talentId: string;
  salaryScore: number;
  skillScore: number;
  taskScore: number;
  historyScore: number;
  experienceScore: number;
  matchingScore: number;
  roleScore: number;
  subRoleScore: number;
  totalScore: number;
  skillLimit: number;
  matchingSubRole?: string[];
}

export interface SourcingRobotDefinitionDto {
  sortedTaskIds: string[];
  bonusPercentage: number;
  languageIds: string[];
  maxSalaryMinYear: number;
  maxSalary: number;
  minYear: number;
  maxYear: number | null;
  minYearsOfEducation: number;
  isStopped: boolean;
  skillPrioGroups: { prio: number; skillIds: string[] }[];
}

function getTypeFromReasonListItem(v: RejectReasonListItem) {
  return v.rejectReasonType;
}

export type RejectReasonType = ReturnType<typeof getTypeFromReasonListItem>;

export interface RejectReasonBaseData {
  talentId: string;
  recruitmentId: string;
  date: Date;
}

export type RejectReasonListItem = RejectReasonBaseData &
  (
    | { rejectReasonType: "RejectReasonCandidateDeclined" }
    | { rejectReasonType: "RejectReasonWrongCompetence" }
    | { rejectReasonType: "RejectReasonNoLongerNeeded" }
    | { rejectReasonType: "RejectReasonCantContactCandidate" }
    | { rejectReasonType: "RejectReasonIncorrectPreferences" }
    | { rejectReasonType: "RejectReasonIncorrectCompetence" }
    | { rejectReasonType: "RejectReasonIncorrectCultureFit" }
    | {
        rejectReasonType: "RejectReasonOther";
        reasonText: string | null;
      }
  );

export type RemoveActionType =
  | "WithMessage"
  | "CandidateDeclined"
  | "WithoutMessage";

export type TalentProfileForCompanyDto = {
  isHiredCopy: boolean;
} & (
  | {
      isAnonymous: false;
      talentProfile: TalentProfileToView;
    }
  | {
      isAnonymous: true;
      talentProfile: TalentAnonymous;
    }
);
export type TalentPoolTeamCommentFromBackend = DateReplacer<
  TalentPoolTeamComment
>;

export type TalentPoolTeamComment = {
  id: string;
  companyId: string;
  talentId: string;
  commentText: string;
  senderUserId: string;
  date: Date;
  mentionIds: string[];
};

export type WorkFromHomeOrPartOfWeek =
  | "Never"
  | "FullRemote"
  | "AllWeek"
  | "PartOfTheWeek";

export interface GetTeamTailorTalentIdsDto {
  talentId: string;
  teamTailorId: string;
  recruitmentIds: string[];
}

export type TalentConversationRaw = DateReplacer<TalentConversation>;

export type TalentConversation = {
  talentId: string;
  companyId: string;
  recruitmentIdOrNull: string | null;
  talentName: string;
  lastMessageDateTimeUtc: Date;
  lastMessage: string;
  isRead: boolean;
  lastIsFromTalent: boolean;
  lastMessageId: string;
  id: string;
};

export type TalentConversations =
  | { type: "Loaded"; conversations: TalentConversation[] }
  | { type: "NotLoaded" }
  | { type: "Loading" };

export type talentExpectations = {
  salary: number;
  salaryCurrency: string;
  requireStableSalary: boolean;
  requireNoTrips: boolean;
  requireNoConsultants: boolean;
  requireNoStartups: boolean;
  requireNoPublicSectors: boolean;
  requireWorkFromHome: number;
  locationIds: string[];
  responsibilityMustIds: number[];
  roleIds: string[];
  rejectedCompanyIds: string[];
};

export interface GetHelpMessageTalentDataDto {
  lang: string | null;
  experienceLanguageIds: string[];
  nonAnonymouseProfileDetails: {
    firstName: string;
    lastName: string;
    phoneNumer: string | null;
  } | null;
}

export interface CompanyTalent {
  companyId: string;
  talentId: string;
  teamTailorObject: {
    teamTailorId: string;
    addedFromRecruitmentIds: string[];
  } | null;
  jobylonObject: {
    jobylonApplicationId: string;
    addedFromRecruitmentIds: string[];
  } | null;
  hiredTalentInfoObject: {
    recruitmentId: string;
    date: string;
    hiredTalentProfileObject: TalentProfileDto;
  } | null;
  lang: string | null;
  experienceLanguageIds: string[];
  nonAnonymouseProfileDetails: {
    firstName: string;
    lastName: string;
    phoneNumer: string | null;
    latestEmployments: EmploymentSaved[];
    highestEducation: EducationSaved | null;
    filterProps: {
      taskIds: string[];
      skillIds: string[];
      yearsOfExperience: number;
      requirements: talentExpectations;
    } | null;
  } | null;
  recruitmentsByRecId: Record<
    string,
    {
      name: string | null;
      status:
        | "RobotHidden"
        | "Declined"
        | "Removed"
        | "Hired"
        | "Accepted"
        | "Invited"
        | "Saved"
        | "Other";
      dateForStatus: string;
      hasAccepted: boolean;
      laneId: string | null;
    }
  >;
}

export type CompanyTalentRecuritmentStatus = CompanyTalent["recruitmentsByRecId"]["recId"]["status"];

export type CompanyTalentGetDto = Pick<
  CompanyTalent,
  | "talentId"
  | "lang"
  | "recruitmentsByRecId"
  | "nonAnonymouseProfileDetails"
  | "experienceLanguageIds"
  | "teamTailorObject"
  | "jobylonObject"
> & {
  isHired: boolean;
};

export type OtherRecruitment = CompanyTalent["recruitmentsByRecId"]["recId"] & {
  recruitmentId: string;
  title: string;
};

export interface GetMatchingProfilesUnfilteredDto {
  skillIds?: string[];
  talentId?: string;
  taskIds?: string[];
  yearsOfExperience?: number;
  salary?: number;
  requireStableSalary?: boolean;
  latestEmploymentBranchIds?: string[];
  currentEmploymentYears?: number;
  latestEmploymentRoleIds?: string[];
  roleExperience?: Record<string, number>;
  taskExperienceInMonths?: Record<string, number>;
  schoolDomains?: string[];
  titlesByExperienceInMonths?: Record<string, number>;
  languageIds?: string[];
  yearsOfEducation?: number;
  requireNoTrips?: boolean;
  requireWorkFromHome?: WorkFromHome;
  branchExperience?: Record<string, number>;
  responsibilities: number[];
}

export type ThingToLoad<T> =
  | {
      type: "notFetched";
    }
  | {
      type: "loading";
      value?: T;
    }
  | {
      type: "loaded";
      value: T;
    };

export const DeclineReasonTypeArray = [
  "CompanyDoesNotRespond",
  "BadExperience",
  "CompanySize",
  "NoPublicSector",
  "NoConsult",
  "NoDevelopmentOpportunities",
  "BadLocation",
  "SeniorityDoesNotFit",
  "MyCompetenceDoesNotFit",
  "InterestForJobTasks",
  "WantOtherRole",
  "StaffResponsibility",
  "Salary",
  "NotTheRightTime",
  "RecentlyNewJob",
  "SelectedOtherOffer",
  "Other",
] as const;

export type DeclineReasonTypeNew = typeof DeclineReasonTypeArray[number];

export type DeclineReasonSingle =
  | {
      type: "Other";
      otherText: string;
    }
  | {
      type: DeclineReasonTypeNew;
    };

export type CompanyGDPRTexts = {
  companyId: string;
  textDict: Record<string, string>;
  useCustomTexts: boolean;
};

export type DeclineReasonModalResult =
  | {
      type: "Saved";
      ratingCompanyProcent: number;
      ratingJobProcent: number;
      selectedReasons: DeclineReasonSingle[];
    }
  | {
      type: "Cancel";
    }
  | {
      type: "NotValid";
    };

export const CandidateListViewsArray = [
  "All",
  "PoolTalents",
  "Available",
  "Active",
] as const;

export type CandidateListViews = typeof CandidateListViewsArray[number];

export const createRecruitmentTotalStepsArrary = [
  // "Title",
  // "Role",
  // "Office",

  "Background",
  "RoleSpecificity",
  "SearchTags",
  "StaffRequiredAndTasksPreferred",
  "SkillsPreferred",
  "Languages",
  "AbsoluteRequirements",

  "Salary",
  "Perks",

  "WorkingConditions",
  "TasksInVsual",
  "SkillsInVisual",
  "Team",
  "HeadingAndDescription",
  "Recap",
] as const;

export type CreateRecruitmentTotalSteps = typeof createRecruitmentTotalStepsArrary[number];

export const getCompanyInformationWithiScraperSchema = z.union([
  z.string(),
  z.object({
    details: z.object({
      company_id: z.number(),
      urls: z.object({
        li_url: z.string(),
      }),
      images: z.object({
        logo: z.string().nullable(),
        cover: z.string().nullable(),
      }),
    }),
  }),
]);

export type GetCompanyInformationWithiScraperDto = z.infer<
  typeof getCompanyInformationWithiScraperSchema
>;

export const linkedInCompanySchema = z.object({
  company_id: z.string(),
  linkedin_url: z.string(),
  location_country: z.string().nullable(),
  name: z.string(),
  industries: z.array(z.string()),
  logo_url: z.string().nullable(),
  description: z.string().nullable(),
});

export type linkedInCompany = z.infer<typeof linkedInCompanySchema>;

export const opennesSettingsSchema = z.object({
  experienceYears: z.object({
    weight: z.number(),
  }),
  education: z.object({
    weight: z.number(),
    devider: z.number(),
  }),
  roleSpecificity: z.object({
    weight: z.number(),
  }),
  staff: z.object({
    weight: z.number(),
  }),
  tasks: z.object({
    weight: z.number(),
    first: z.object({
      top: z.number(),
      score: z.number(),
    }),
    second: z.object({
      top: z.number(),
      score: z.number(),
    }),
    third: z.object({
      top: z.number(),
      score: z.number(),
    }),
    fourth: z.object({
      score: z.number(),
    }),
  }),
  lang: z.object({
    weight: z.number(),
    power: z.number(),
    en: z.number(),
    sv: z.number(),
    other: z.number(),
  }),
  skills: z.object({
    weight: z.number(),
    first: z.object({
      top: z.number(),
      score: z.number(),
    }),
    second: z.object({
      top: z.number(),
      score: z.number(),
    }),
    third: z.object({
      top: z.number(),
      score: z.number(),
    }),
    fourth: z.object({
      score: z.number(),
    }),
  }),
  remote: z.object({
    weight: z.number(),
    allWeekAndFullRemote: z.number(),
    allWeek: z.number(),
    fullRemote: z.number(),
    partOfWeek: z.number(),
    notAllowed: z.number(),
  }),
  salary: z.object({
    weight: z.number(),
    bonusWeight: z.number(),
    scorePerStep: z.number(),
  }),
});

export type OpennessSettings = z.infer<typeof opennesSettingsSchema>;

export type WantMoreCandidatesTip = {
  percent: number | "Infinity";
} & (
  | {
      subType: "DecreaseMinExperienceYears";
      newMinYears: number;
      oldMinYears: number;
    }
  | {
      subType: "IncreaseMaxExperienceYears";
      oldMaxYears: number | null;
      newMaxYears: number | null;
    }
  | {
      subType: "DecreaseEducation";
      oldEducationYears: number;
      newEducationYears: number;
    }
  | {
      subType: "RoleSpecificity";
      requiredRoleIds: string[];
      oldRoleId: string;
    }
  | {
      subType: "RoleSpecificity2";
      addedRoleId: string;
    }
  | {
      subType: "Task";
      taskId: string;
    }
  | {
      subType: "ReplaceWithTaskGroup";
      taskId: string;
      taskIds: string[];
      groupName: Record<string, string> | null;
      taskGroupId: string;
    }
  | {
      subType: "RemoveOneSkill";
      skillId: string;
    }
  | {
      subType: "RemoveSkillGroup";
      skillIds: string[];
      groupName: Record<string, string> | null;
    }
  | {
      subType: "RemoveTaskGroup";
      taskIds: string[];
      groupName: Record<string, string> | null;
    }
  | {
      subType: "ReplaceWithSkillGroup";
      skillId: string;
      skillIds: string[];
      groupName: Record<string, string> | null;
      skillGroupId: string;
    }
  | {
      subType: "Staff";
    }
  | {
      subType: "Language";
      languageId: string;
    }
  | {
      subType: "RemoteMoreFlexible";
    }
  | {
      subType: "RemoteAcceptFullRemote";
    }
  | {
      subType: "SalaryIncrease";
      newSalary: number;
      oldSalary: number;
    }
);

export type UpsertDeclineWantMoreTipDto = {
  recruitmentId: string;
  tip: WantMoreCandidatesTip;
};

export enum ApplyForCompanyAccountPublicStatus {
  NoStatus = 0,
  NotExistingCompanyListObject = 1,
  AlreadyCustomer = 2,
  NoLinkedInOrWebsite = 3,
  DomainsDoNotMatch = 4,
  Approved = 99,
}

export interface SearchTagResultDto {
  code: string;
  name: Record<string, string>;
  description?: Record<string, string>;
}

export interface TaskDetailsDto {
  id: string;
  name: string;
  description: string | null;
  roleIds: string[];
}

export interface SkillGroupObject {
  id: string;
  friendlyName: string;
  name: Record<string, string>;
  skillIds: string[];
  isRequired: boolean;
}

export interface TaskGroupObject {
  id: string;
  friendlyName: string;
  name: Record<string, string>;
  taskIds: string[];
  isRequired: boolean;
}

export interface SkillGroupGroup {
  id: string;
  skillGroupIds: string[];
}

export interface TaskGroupGroup {
  id: string;
  taskGroupIds: string[];
}

export type ChipListBaseItem = {
  key: string;
  text: string;
  isSelected: boolean;
  isSemiSelected: boolean;
};
