import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61a6868a = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _3f53131e = () => interopDefault(import('..\\pages\\aboutgritify.vue' /* webpackChunkName: "pages/aboutgritify" */))
const _2f6ea8a4 = () => interopDefault(import('..\\pages\\adredirect.vue' /* webpackChunkName: "pages/adredirect" */))
const _035b69f8 = () => interopDefault(import('..\\pages\\companyStart\\index.vue' /* webpackChunkName: "pages/companyStart/index" */))
const _025efc2c = () => interopDefault(import('..\\pages\\competition.vue' /* webpackChunkName: "pages/competition" */))
const _87aa678c = () => interopDefault(import('..\\pages\\cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _7af73c86 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _16f4f0f6 = () => interopDefault(import('..\\pages\\event.vue' /* webpackChunkName: "pages/event" */))
const _5e1de4fe = () => interopDefault(import('..\\pages\\finishProfile.vue' /* webpackChunkName: "pages/finishProfile" */))
const _59c0eeaa = () => interopDefault(import('..\\pages\\hm.vue' /* webpackChunkName: "pages/hm" */))
const _b6f53d54 = () => interopDefault(import('..\\pages\\hrdigi.vue' /* webpackChunkName: "pages/hrdigi" */))
const _727b9ce1 = () => interopDefault(import('..\\pages\\ingenjorsjobb\\index.vue' /* webpackChunkName: "pages/ingenjorsjobb/index" */))
const _745c3a20 = () => interopDefault(import('..\\pages\\it-jobb\\index.vue' /* webpackChunkName: "pages/it-jobb/index" */))
const _e53ccc0c = () => interopDefault(import('..\\pages\\pressreleases.vue' /* webpackChunkName: "pages/pressreleases" */))
const _021304a5 = () => interopDefault(import('..\\pages\\privacypolicy.vue' /* webpackChunkName: "pages/privacypolicy" */))
const _65e77d7c = () => interopDefault(import('..\\pages\\rekrytera-ingenjorer.vue' /* webpackChunkName: "pages/rekrytera-ingenjorer" */))
const _ae37abfa = () => interopDefault(import('..\\pages\\rekrytera-jurister.vue' /* webpackChunkName: "pages/rekrytera-jurister" */))
const _44d910de = () => interopDefault(import('..\\pages\\rekrytera-utvecklare.vue' /* webpackChunkName: "pages/rekrytera-utvecklare" */))
const _eaad9568 = () => interopDefault(import('..\\pages\\rekryteringsforetag\\index.vue' /* webpackChunkName: "pages/rekryteringsforetag/index" */))
const _9be40900 = () => interopDefault(import('..\\pages\\selected.vue' /* webpackChunkName: "pages/selected" */))
const _2025acf6 = () => interopDefault(import('..\\pages\\sharedtalent.vue' /* webpackChunkName: "pages/sharedtalent" */))
const _d9309940 = () => interopDefault(import('..\\pages\\sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _54b506d1 = () => interopDefault(import('..\\pages\\talent\\index.vue' /* webpackChunkName: "pages/talent/index" */))
const _16de2de1 = () => interopDefault(import('..\\pages\\talentStart\\index.vue' /* webpackChunkName: "pages/talentStart/index" */))
const _46758c64 = () => interopDefault(import('..\\pages\\termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _73d42daa = () => interopDefault(import('..\\pages\\blog\\kompetensbaserad-rekrytering.vue' /* webpackChunkName: "pages/blog/kompetensbaserad-rekrytering" */))
const _0128e36a = () => interopDefault(import('..\\pages\\blog\\lyckat-samarbete-mellan-gritify-och-dagens-industri.vue' /* webpackChunkName: "pages/blog/lyckat-samarbete-mellan-gritify-och-dagens-industri" */))
const _30f337b8 = () => interopDefault(import('..\\pages\\blog\\rekrytera-fordomsfritt.vue' /* webpackChunkName: "pages/blog/rekrytera-fordomsfritt" */))
const _028a431a = () => interopDefault(import('..\\pages\\blog\\rekrytera-utvecklare.vue' /* webpackChunkName: "pages/blog/rekrytera-utvecklare" */))
const _06745818 = () => interopDefault(import('..\\pages\\blog\\skriva-jobbannons.vue' /* webpackChunkName: "pages/blog/skriva-jobbannons" */))
const _4c76c52c = () => interopDefault(import('..\\pages\\blog\\vad-ar-employer-branding.vue' /* webpackChunkName: "pages/blog/vad-ar-employer-branding" */))
const _7dd54d28 = () => interopDefault(import('..\\pages\\blog\\vad-kostar-en-rekrytering.vue' /* webpackChunkName: "pages/blog/vad-kostar-en-rekrytering" */))
const _0f2441b5 = () => interopDefault(import('..\\pages\\companyStart\\applyforcompanyaccount.vue' /* webpackChunkName: "pages/companyStart/applyforcompanyaccount" */))
const _1eebcf6b = () => interopDefault(import('..\\pages\\companyStart\\createAccount\\index.vue' /* webpackChunkName: "pages/companyStart/createAccount/index" */))
const _36aa5f57 = () => interopDefault(import('..\\pages\\companyStart\\directaccess.vue' /* webpackChunkName: "pages/companyStart/directaccess" */))
const _2b96318c = () => interopDefault(import('..\\pages\\companyStart\\fordomsfrihet.vue' /* webpackChunkName: "pages/companyStart/fordomsfrihet" */))
const _4658748a = () => interopDefault(import('..\\pages\\companyStart\\friends.vue' /* webpackChunkName: "pages/companyStart/friends" */))
const _f5199906 = () => interopDefault(import('..\\pages\\companyStart\\passiveCandidates.vue' /* webpackChunkName: "pages/companyStart/passiveCandidates" */))
const _342ec928 = () => interopDefault(import('..\\pages\\companyStart\\pricing.vue' /* webpackChunkName: "pages/companyStart/pricing" */))
const _7b0e1948 = () => interopDefault(import('..\\pages\\ingenjorsjobb\\goteborg.vue' /* webpackChunkName: "pages/ingenjorsjobb/goteborg" */))
const _3177dd09 = () => interopDefault(import('..\\pages\\ingenjorsjobb\\malmo.vue' /* webpackChunkName: "pages/ingenjorsjobb/malmo" */))
const _46b82926 = () => interopDefault(import('..\\pages\\ingenjorsjobb\\stockholm.vue' /* webpackChunkName: "pages/ingenjorsjobb/stockholm" */))
const _71888226 = () => interopDefault(import('..\\pages\\it-jobb\\goteborg.vue' /* webpackChunkName: "pages/it-jobb/goteborg" */))
const _f663b9d0 = () => interopDefault(import('..\\pages\\it-jobb\\malmo.vue' /* webpackChunkName: "pages/it-jobb/malmo" */))
const _703a91fc = () => interopDefault(import('..\\pages\\it-jobb\\stockholm.vue' /* webpackChunkName: "pages/it-jobb/stockholm" */))
const _6474ff11 = () => interopDefault(import('..\\pages\\rekryteringsforetag\\goteborg.vue' /* webpackChunkName: "pages/rekryteringsforetag/goteborg" */))
const _285452be = () => interopDefault(import('..\\pages\\rekryteringsforetag\\IT.vue' /* webpackChunkName: "pages/rekryteringsforetag/IT" */))
const _49a57574 = () => interopDefault(import('..\\pages\\rekryteringsforetag\\malmo.vue' /* webpackChunkName: "pages/rekryteringsforetag/malmo" */))
const _7a295658 = () => interopDefault(import('..\\pages\\rekryteringsforetag\\stockholm.vue' /* webpackChunkName: "pages/rekryteringsforetag/stockholm" */))
const _7ab8e213 = () => interopDefault(import('..\\pages\\talent\\accountsettings\\index.vue' /* webpackChunkName: "pages/talent/accountsettings/index" */))
const _bfb86d9a = () => interopDefault(import('..\\pages\\talent\\answerScore.vue' /* webpackChunkName: "pages/talent/answerScore" */))
const _0f1c62b7 = () => interopDefault(import('..\\pages\\talent\\contactGritify.vue' /* webpackChunkName: "pages/talent/contactGritify" */))
const _7ff19e8c = () => interopDefault(import('..\\pages\\talent\\createProfile.vue' /* webpackChunkName: "pages/talent/createProfile" */))
const _3ec92518 = () => interopDefault(import('..\\pages\\talent\\declineJobOffer.vue' /* webpackChunkName: "pages/talent/declineJobOffer" */))
const _544ff4b2 = () => interopDefault(import('..\\pages\\talent\\editBlacklist.vue' /* webpackChunkName: "pages/talent/editBlacklist" */))
const _2d307cc3 = () => interopDefault(import('..\\pages\\talent\\editContactInfo.vue' /* webpackChunkName: "pages/talent/editContactInfo" */))
const _6a988606 = () => interopDefault(import('..\\pages\\talent\\editName.vue' /* webpackChunkName: "pages/talent/editName" */))
const _7afa2bae = () => interopDefault(import('..\\pages\\talent\\editNotificationSettings.vue' /* webpackChunkName: "pages/talent/editNotificationSettings" */))
const _be4079a6 = () => interopDefault(import('..\\pages\\talent\\editStatus.vue' /* webpackChunkName: "pages/talent/editStatus" */))
const _35daa246 = () => interopDefault(import('..\\pages\\talent\\indexOld.vue' /* webpackChunkName: "pages/talent/indexOld" */))
const _2d58a04b = () => interopDefault(import('..\\pages\\talent\\inviteFriends.vue' /* webpackChunkName: "pages/talent/inviteFriends" */))
const _43af0a2b = () => interopDefault(import('..\\pages\\talent\\inviteFriendsCmp.vue' /* webpackChunkName: "pages/talent/inviteFriendsCmp" */))
const _d8abd420 = () => interopDefault(import('..\\pages\\talent\\jobOffer.vue' /* webpackChunkName: "pages/talent/jobOffer" */))
const _10e41dca = () => interopDefault(import('..\\pages\\talent\\myExperience\\index.vue' /* webpackChunkName: "pages/talent/myExperience/index" */))
const _3a4400aa = () => interopDefault(import('..\\pages\\talent\\myPreferences.vue' /* webpackChunkName: "pages/talent/myPreferences" */))
const _565b4075 = () => interopDefault(import('..\\pages\\talent\\pool\\index.vue' /* webpackChunkName: "pages/talent/pool/index" */))
const _0d61fc94 = () => interopDefault(import('..\\pages\\talent\\process\\index.vue' /* webpackChunkName: "pages/talent/process/index" */))
const _679a9766 = () => interopDefault(import('..\\pages\\talent\\requests\\index.vue' /* webpackChunkName: "pages/talent/requests/index" */))
const _b7c05554 = () => interopDefault(import('..\\pages\\talentStart\\createProfileStart.vue' /* webpackChunkName: "pages/talentStart/createProfileStart" */))
const _571349b0 = () => interopDefault(import('..\\pages\\companyStart\\createAccount\\verify.vue' /* webpackChunkName: "pages/companyStart/createAccount/verify" */))
const _58398760 = () => interopDefault(import('..\\pages\\companyStart\\talentBarometer\\H12024.vue' /* webpackChunkName: "pages/companyStart/talentBarometer/H12024" */))
const _6a783667 = () => interopDefault(import('..\\pages\\companyStart\\talentBarometer\\Q12024.vue' /* webpackChunkName: "pages/companyStart/talentBarometer/Q12024" */))
const _774d64ca = () => interopDefault(import('..\\pages\\companyStart\\talentBarometer\\register.vue' /* webpackChunkName: "pages/companyStart/talentBarometer/register" */))
const _78d6e4b2 = () => interopDefault(import('..\\pages\\talent\\accountsettings\\language.vue' /* webpackChunkName: "pages/talent/accountsettings/language" */))
const _5ddfca5c = () => interopDefault(import('..\\pages\\talent\\myExperience\\educations.vue' /* webpackChunkName: "pages/talent/myExperience/educations" */))
const _10067710 = () => interopDefault(import('..\\pages\\talent\\myExperience\\employments.vue' /* webpackChunkName: "pages/talent/myExperience/employments" */))
const _338385d7 = () => interopDefault(import('..\\pages\\talent\\myExperience\\tasks.vue' /* webpackChunkName: "pages/talent/myExperience/tasks" */))
const _40b124d6 = () => interopDefault(import('..\\pages\\talent\\process\\history.vue' /* webpackChunkName: "pages/talent/process/history" */))
const _e39a5b62 = () => interopDefault(import('..\\pages\\talent\\requests\\history.vue' /* webpackChunkName: "pages/talent/requests/history" */))
const _746e3638 = () => interopDefault(import('..\\pages\\talent\\contactRequest\\_recruitmentId.vue' /* webpackChunkName: "pages/talent/contactRequest/_recruitmentId" */))
const _234752bc = () => interopDefault(import('..\\pages\\talent\\declineContactRequest\\_recruitmentId.vue' /* webpackChunkName: "pages/talent/declineContactRequest/_recruitmentId" */))
const _b8f2d834 = () => interopDefault(import('..\\pages\\talent\\pool\\_companyId.vue' /* webpackChunkName: "pages/talent/pool/_companyId" */))
const _2ee18ec9 = () => interopDefault(import('..\\pages\\talent\\process\\_processId\\index.vue' /* webpackChunkName: "pages/talent/process/_processId/index" */))
const _3485a9f5 = () => interopDefault(import('..\\pages\\talent\\requests\\_recruitmentId\\index.vue' /* webpackChunkName: "pages/talent/requests/_recruitmentId/index" */))
const _65678d98 = () => interopDefault(import('..\\pages\\talent\\process\\_processId\\company.vue' /* webpackChunkName: "pages/talent/process/_processId/company" */))
const _0b538d40 = () => interopDefault(import('..\\pages\\talent\\requests\\_recruitmentId\\company.vue' /* webpackChunkName: "pages/talent/requests/_recruitmentId/company" */))
const _04b81a41 = () => interopDefault(import('..\\pages\\company\\_companyId\\index.vue' /* webpackChunkName: "pages/company/_companyId/index" */))
const _6dfd8b1e = () => interopDefault(import('..\\pages\\inviteFriend\\_inviterId.vue' /* webpackChunkName: "pages/inviteFriend/_inviterId" */))
const _8d212fc4 = () => interopDefault(import('..\\pages\\jobs\\_externalFormId.vue' /* webpackChunkName: "pages/jobs/_externalFormId" */))
const _195c9094 = () => interopDefault(import('..\\pages\\rec\\_smsToken.vue' /* webpackChunkName: "pages/rec/_smsToken" */))
const _4cc88748 = () => interopDefault(import('..\\pages\\company\\_companyId\\account.vue' /* webpackChunkName: "pages/company/_companyId/account" */))
const _64cadc3c = () => interopDefault(import('..\\pages\\company\\_companyId\\companyComponents.vue' /* webpackChunkName: "pages/company/_companyId/companyComponents" */))
const _3391d50d = () => interopDefault(import('..\\pages\\company\\_companyId\\companyProfile.vue' /* webpackChunkName: "pages/company/_companyId/companyProfile" */))
const _22a6d938 = () => interopDefault(import('..\\pages\\company\\_companyId\\coworkers.vue' /* webpackChunkName: "pages/company/_companyId/coworkers" */))
const _8ff18488 = () => interopDefault(import('..\\pages\\company\\_companyId\\coworkers\\_coworkerId.vue' /* webpackChunkName: "pages/company/_companyId/coworkers/_coworkerId" */))
const _4a747752 = () => interopDefault(import('..\\pages\\company\\_companyId\\notifications.vue' /* webpackChunkName: "pages/company/_companyId/notifications" */))
const _60e63666 = () => interopDefault(import('..\\pages\\company\\_companyId\\offices.vue' /* webpackChunkName: "pages/company/_companyId/offices" */))
const _75c0b440 = () => interopDefault(import('..\\pages\\company\\_companyId\\offices\\_officeId.vue' /* webpackChunkName: "pages/company/_companyId/offices/_officeId" */))
const _476e857e = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitmentComponents.vue' /* webpackChunkName: "pages/company/_companyId/recruitmentComponents" */))
const _070af06c = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\index.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/index" */))
const _3b480a04 = () => interopDefault(import('..\\pages\\company\\_companyId\\settings.vue' /* webpackChunkName: "pages/company/_companyId/settings" */))
const _714fbdfe = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\index.vue' /* webpackChunkName: "pages/company/_companyId/settings/index" */))
const _22b6a136 = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\agreement.vue' /* webpackChunkName: "pages/company/_companyId/settings/agreement" */))
const _44fc2851 = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\betaFeatures.vue' /* webpackChunkName: "pages/company/_companyId/settings/betaFeatures" */))
const _688bc75a = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\blockedCompanies.vue' /* webpackChunkName: "pages/company/_companyId/settings/blockedCompanies" */))
const _78f1d066 = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\gdprSettings.vue' /* webpackChunkName: "pages/company/_companyId/settings/gdprSettings" */))
const _752b19c3 = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\integrationSettings.vue' /* webpackChunkName: "pages/company/_companyId/settings/integrationSettings" */))
const _77e30a5a = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\mediaSettings.vue' /* webpackChunkName: "pages/company/_companyId/settings/mediaSettings" */))
const _8e49f4ea = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\recruitmentSettings.vue' /* webpackChunkName: "pages/company/_companyId/settings/recruitmentSettings" */))
const _928ccf98 = () => interopDefault(import('..\\pages\\company\\_companyId\\settings\\users.vue' /* webpackChunkName: "pages/company/_companyId/settings/users" */))
const _3ebf9c49 = () => interopDefault(import('..\\pages\\company\\_companyId\\talentPool.vue' /* webpackChunkName: "pages/company/_companyId/talentPool" */))
const _2b6be925 = () => interopDefault(import('..\\pages\\company\\_companyId\\teams.vue' /* webpackChunkName: "pages/company/_companyId/teams" */))
const _1f30e122 = () => interopDefault(import('..\\pages\\company\\_companyId\\teams\\_teamId.vue' /* webpackChunkName: "pages/company/_companyId/teams/_teamId" */))
const _5bb8e372 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\index.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/index" */))
const _e5af9122 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\admin.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/admin" */))
const _ae303f14 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\communication.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/communication" */))
const _275a9405 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\compare.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/compare" */))
const _4275f90c = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\editVisual.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/editVisual" */))
const _305f5ab4 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\externalTalents\\index.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/externalTalents/index" */))
const _4086319a = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\settings.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/settings" */))
const _0f1f164c = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\sourcing\\index.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/sourcing/index" */))
const _5ad2f6e2 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\sourcingRobotBeta.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/sourcingRobotBeta" */))
const _b001f6f8 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\sourcingRobotTest.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/sourcingRobotTest" */))
const _58074cdf = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\sourcingRobotTest3.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/sourcingRobotTest3" */))
const _0b25329a = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\candidate\\_talentId.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/candidate/_talentId" */))
const _407bb276 = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\externalTalents\\_externalTalentId.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/externalTalents/_externalTalentId" */))
const _effa6a0c = () => interopDefault(import('..\\pages\\company\\_companyId\\recruitments\\_recruitmentId\\sourcing\\_sourcingId.vue' /* webpackChunkName: "pages/company/_companyId/recruitments/_recruitmentId/sourcing/_sourcingId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _61a6868a,
    name: "about___sv___default"
  }, {
    path: "/aboutgritify",
    component: _3f53131e,
    name: "aboutgritify___sv___default"
  }, {
    path: "/adredirect",
    component: _2f6ea8a4,
    name: "adredirect___sv___default"
  }, {
    path: "/companyStart",
    component: _035b69f8,
    name: "companyStart___sv___default"
  }, {
    path: "/competition",
    component: _025efc2c,
    name: "competition___sv___default"
  }, {
    path: "/cookies",
    component: _87aa678c,
    name: "cookies___sv___default"
  }, {
    path: "/en",
    component: _7af73c86,
    name: "index___en"
  }, {
    path: "/event",
    component: _16f4f0f6,
    name: "event___sv___default"
  }, {
    path: "/finishProfile",
    component: _5e1de4fe,
    name: "finishProfile___sv___default"
  }, {
    path: "/hm",
    component: _59c0eeaa,
    name: "hm___sv___default"
  }, {
    path: "/hrdigi",
    component: _b6f53d54,
    name: "hrdigi___sv___default"
  }, {
    path: "/ingenjorsjobb",
    component: _727b9ce1,
    name: "ingenjorsjobb"
  }, {
    path: "/it-jobb",
    component: _745c3a20,
    name: "it-jobb"
  }, {
    path: "/pressreleases",
    component: _e53ccc0c,
    name: "pressreleases___sv___default"
  }, {
    path: "/privacypolicy",
    component: _021304a5,
    name: "privacypolicy___sv___default"
  }, {
    path: "/rekrytera-ingenjorer",
    component: _65e77d7c,
    name: "rekrytera-ingenjorer"
  }, {
    path: "/rekrytera-jurister",
    component: _ae37abfa,
    name: "rekrytera-jurister"
  }, {
    path: "/rekrytera-utvecklare",
    component: _44d910de,
    name: "rekrytera-utvecklare"
  }, {
    path: "/rekryteringsforetag",
    component: _eaad9568,
    name: "rekryteringsforetag"
  }, {
    path: "/selected",
    component: _9be40900,
    name: "selected___sv___default"
  }, {
    path: "/sharedtalent",
    component: _2025acf6,
    name: "sharedtalent___sv___default"
  }, {
    path: "/sitemap",
    component: _d9309940,
    name: "sitemap___sv___default"
  }, {
    path: "/sv",
    component: _7af73c86,
    name: "index___sv"
  }, {
    path: "/talent",
    component: _54b506d1,
    name: "talent___sv___default"
  }, {
    path: "/talentStart",
    component: _16de2de1,
    name: "talentStart___sv___default"
  }, {
    path: "/termsofuse",
    component: _46758c64,
    name: "termsofuse___sv___default"
  }, {
    path: "/blog/kompetensbaserad-rekrytering",
    component: _73d42daa,
    name: "blog-kompetensbaserad-rekrytering___sv___default"
  }, {
    path: "/blog/lyckat-samarbete-mellan-gritify-och-dagens-industri",
    component: _0128e36a,
    name: "blog-lyckat-samarbete-mellan-gritify-och-dagens-industri___sv___default"
  }, {
    path: "/blog/rekrytera-fordomsfritt",
    component: _30f337b8,
    name: "blog-rekrytera-fordomsfritt___sv___default"
  }, {
    path: "/blog/rekrytera-utvecklare",
    component: _028a431a,
    name: "blog-rekrytera-utvecklare___sv___default"
  }, {
    path: "/blog/skriva-jobbannons",
    component: _06745818,
    name: "blog-skriva-jobbannons___sv___default"
  }, {
    path: "/blog/vad-ar-employer-branding",
    component: _4c76c52c,
    name: "blog-vad-ar-employer-branding___sv___default"
  }, {
    path: "/blog/vad-kostar-en-rekrytering",
    component: _7dd54d28,
    name: "blog-vad-kostar-en-rekrytering___sv___default"
  }, {
    path: "/companyStart/applyforcompanyaccount",
    component: _0f2441b5,
    name: "companyStart-applyforcompanyaccount___sv___default"
  }, {
    path: "/companyStart/createAccount",
    component: _1eebcf6b,
    name: "companyStart-createAccount___sv___default"
  }, {
    path: "/companyStart/directaccess",
    component: _36aa5f57,
    name: "companyStart-directaccess___sv___default"
  }, {
    path: "/companyStart/fordomsfrihet",
    component: _2b96318c,
    name: "companyStart-fordomsfrihet___sv___default"
  }, {
    path: "/companyStart/friends",
    component: _4658748a,
    name: "companyStart-friends___sv___default"
  }, {
    path: "/companyStart/passiveCandidates",
    component: _f5199906,
    name: "companyStart-passiveCandidates___sv___default"
  }, {
    path: "/companyStart/pricing",
    component: _342ec928,
    name: "companyStart-pricing___sv___default"
  }, {
    path: "/en/about",
    component: _61a6868a,
    name: "about___en"
  }, {
    path: "/en/aboutgritify",
    component: _3f53131e,
    name: "aboutgritify___en"
  }, {
    path: "/en/adredirect",
    component: _2f6ea8a4,
    name: "adredirect___en"
  }, {
    path: "/en/companyStart",
    component: _035b69f8,
    name: "companyStart___en"
  }, {
    path: "/en/competition",
    component: _025efc2c,
    name: "competition___en"
  }, {
    path: "/en/cookies",
    component: _87aa678c,
    name: "cookies___en"
  }, {
    path: "/en/event",
    component: _16f4f0f6,
    name: "event___en"
  }, {
    path: "/en/finishProfile",
    component: _5e1de4fe,
    name: "finishProfile___en"
  }, {
    path: "/en/hm",
    component: _59c0eeaa,
    name: "hm___en"
  }, {
    path: "/en/hrdigi",
    component: _b6f53d54,
    name: "hrdigi___en"
  }, {
    path: "/en/pressreleases",
    component: _e53ccc0c,
    name: "pressreleases___en"
  }, {
    path: "/en/privacypolicy",
    component: _021304a5,
    name: "privacypolicy___en"
  }, {
    path: "/en/selected",
    component: _9be40900,
    name: "selected___en"
  }, {
    path: "/en/sharedtalent",
    component: _2025acf6,
    name: "sharedtalent___en"
  }, {
    path: "/en/sitemap",
    component: _d9309940,
    name: "sitemap___en"
  }, {
    path: "/en/talent",
    component: _54b506d1,
    name: "talent___en"
  }, {
    path: "/en/talentStart",
    component: _16de2de1,
    name: "talentStart___en"
  }, {
    path: "/en/termsofuse",
    component: _46758c64,
    name: "termsofuse___en"
  }, {
    path: "/ingenjorsjobb/goteborg",
    component: _7b0e1948,
    name: "ingenjorsjobb-goteborg"
  }, {
    path: "/ingenjorsjobb/malmo",
    component: _3177dd09,
    name: "ingenjorsjobb-malmo"
  }, {
    path: "/ingenjorsjobb/stockholm",
    component: _46b82926,
    name: "ingenjorsjobb-stockholm"
  }, {
    path: "/it-jobb/goteborg",
    component: _71888226,
    name: "it-jobb-goteborg"
  }, {
    path: "/it-jobb/malmo",
    component: _f663b9d0,
    name: "it-jobb-malmo"
  }, {
    path: "/it-jobb/stockholm",
    component: _703a91fc,
    name: "it-jobb-stockholm"
  }, {
    path: "/rekryteringsforetag/goteborg",
    component: _6474ff11,
    name: "rekryteringsforetag-goteborg"
  }, {
    path: "/rekryteringsforetag/IT",
    component: _285452be,
    name: "rekryteringsforetag-IT"
  }, {
    path: "/rekryteringsforetag/malmo",
    component: _49a57574,
    name: "rekryteringsforetag-malmo"
  }, {
    path: "/rekryteringsforetag/stockholm",
    component: _7a295658,
    name: "rekryteringsforetag-stockholm"
  }, {
    path: "/sv/about",
    component: _61a6868a,
    name: "about___sv"
  }, {
    path: "/sv/aboutgritify",
    component: _3f53131e,
    name: "aboutgritify___sv"
  }, {
    path: "/sv/adredirect",
    component: _2f6ea8a4,
    name: "adredirect___sv"
  }, {
    path: "/sv/companyStart",
    component: _035b69f8,
    name: "companyStart___sv"
  }, {
    path: "/sv/competition",
    component: _025efc2c,
    name: "competition___sv"
  }, {
    path: "/sv/cookies",
    component: _87aa678c,
    name: "cookies___sv"
  }, {
    path: "/sv/event",
    component: _16f4f0f6,
    name: "event___sv"
  }, {
    path: "/sv/finishProfile",
    component: _5e1de4fe,
    name: "finishProfile___sv"
  }, {
    path: "/sv/hm",
    component: _59c0eeaa,
    name: "hm___sv"
  }, {
    path: "/sv/hrdigi",
    component: _b6f53d54,
    name: "hrdigi___sv"
  }, {
    path: "/sv/pressreleases",
    component: _e53ccc0c,
    name: "pressreleases___sv"
  }, {
    path: "/sv/privacypolicy",
    component: _021304a5,
    name: "privacypolicy___sv"
  }, {
    path: "/sv/selected",
    component: _9be40900,
    name: "selected___sv"
  }, {
    path: "/sv/sharedtalent",
    component: _2025acf6,
    name: "sharedtalent___sv"
  }, {
    path: "/sv/sitemap",
    component: _d9309940,
    name: "sitemap___sv"
  }, {
    path: "/sv/talent",
    component: _54b506d1,
    name: "talent___sv"
  }, {
    path: "/sv/talentStart",
    component: _16de2de1,
    name: "talentStart___sv"
  }, {
    path: "/sv/termsofuse",
    component: _46758c64,
    name: "termsofuse___sv"
  }, {
    path: "/talent/accountsettings",
    component: _7ab8e213,
    name: "talent-accountsettings___sv___default"
  }, {
    path: "/talent/answerScore",
    component: _bfb86d9a,
    name: "talent-answerScore___sv___default"
  }, {
    path: "/talent/contactGritify",
    component: _0f1c62b7,
    name: "talent-contactGritify___sv___default"
  }, {
    path: "/talent/createProfile",
    component: _7ff19e8c,
    name: "talent-createProfile___sv___default"
  }, {
    path: "/talent/declineJobOffer",
    component: _3ec92518,
    name: "talent-declineJobOffer___sv___default"
  }, {
    path: "/talent/editBlacklist",
    component: _544ff4b2,
    name: "talent-editBlacklist___sv___default"
  }, {
    path: "/talent/editContactInfo",
    component: _2d307cc3,
    name: "talent-editContactInfo___sv___default"
  }, {
    path: "/talent/editName",
    component: _6a988606,
    name: "talent-editName___sv___default"
  }, {
    path: "/talent/editNotificationSettings",
    component: _7afa2bae,
    name: "talent-editNotificationSettings___sv___default"
  }, {
    path: "/talent/editStatus",
    component: _be4079a6,
    name: "talent-editStatus___sv___default"
  }, {
    path: "/talent/indexOld",
    component: _35daa246,
    name: "talent-indexOld___sv___default"
  }, {
    path: "/talent/inviteFriends",
    component: _2d58a04b,
    name: "talent-inviteFriends___sv___default"
  }, {
    path: "/talent/inviteFriendsCmp",
    component: _43af0a2b,
    name: "talent-inviteFriendsCmp___sv___default"
  }, {
    path: "/talent/jobOffer",
    component: _d8abd420,
    name: "talent-jobOffer___sv___default"
  }, {
    path: "/talent/myExperience",
    component: _10e41dca,
    name: "talent-myExperience___sv___default"
  }, {
    path: "/talent/myPreferences",
    component: _3a4400aa,
    name: "talent-myPreferences___sv___default"
  }, {
    path: "/talent/pool",
    component: _565b4075,
    name: "talent-pool___sv___default"
  }, {
    path: "/talent/process",
    component: _0d61fc94,
    name: "talent-process___sv___default"
  }, {
    path: "/talent/requests",
    component: _679a9766,
    name: "talent-requests___sv___default"
  }, {
    path: "/talentStart/createProfileStart",
    component: _b7c05554,
    name: "talentStart-createProfileStart___sv___default"
  }, {
    path: "/companyStart/createAccount/verify",
    component: _571349b0,
    name: "companyStart-createAccount-verify___sv___default"
  }, {
    path: "/companyStart/talentBarometer/H12024",
    component: _58398760,
    name: "companyStart-talentBarometer-H12024___sv___default"
  }, {
    path: "/companyStart/talentBarometer/Q12024",
    component: _6a783667,
    name: "companyStart-talentBarometer-Q12024___sv___default"
  }, {
    path: "/companyStart/talentBarometer/register",
    component: _774d64ca,
    name: "companyStart-talentBarometer-register___sv___default"
  }, {
    path: "/en/blog/kompetensbaserad-rekrytering",
    component: _73d42daa,
    name: "blog-kompetensbaserad-rekrytering___en"
  }, {
    path: "/en/blog/lyckat-samarbete-mellan-gritify-och-dagens-industri",
    component: _0128e36a,
    name: "blog-lyckat-samarbete-mellan-gritify-och-dagens-industri___en"
  }, {
    path: "/en/blog/rekrytera-fordomsfritt",
    component: _30f337b8,
    name: "blog-rekrytera-fordomsfritt___en"
  }, {
    path: "/en/blog/rekrytera-utvecklare",
    component: _028a431a,
    name: "blog-rekrytera-utvecklare___en"
  }, {
    path: "/en/blog/skriva-jobbannons",
    component: _06745818,
    name: "blog-skriva-jobbannons___en"
  }, {
    path: "/en/blog/vad-ar-employer-branding",
    component: _4c76c52c,
    name: "blog-vad-ar-employer-branding___en"
  }, {
    path: "/en/blog/vad-kostar-en-rekrytering",
    component: _7dd54d28,
    name: "blog-vad-kostar-en-rekrytering___en"
  }, {
    path: "/en/companyStart/applyforcompanyaccount",
    component: _0f2441b5,
    name: "companyStart-applyforcompanyaccount___en"
  }, {
    path: "/en/companyStart/createAccount",
    component: _1eebcf6b,
    name: "companyStart-createAccount___en"
  }, {
    path: "/en/companyStart/directaccess",
    component: _36aa5f57,
    name: "companyStart-directaccess___en"
  }, {
    path: "/en/companyStart/fordomsfrihet",
    component: _2b96318c,
    name: "companyStart-fordomsfrihet___en"
  }, {
    path: "/en/companyStart/friends",
    component: _4658748a,
    name: "companyStart-friends___en"
  }, {
    path: "/en/companyStart/passiveCandidates",
    component: _f5199906,
    name: "companyStart-passiveCandidates___en"
  }, {
    path: "/en/companyStart/pricing",
    component: _342ec928,
    name: "companyStart-pricing___en"
  }, {
    path: "/en/talent/accountsettings",
    component: _7ab8e213,
    name: "talent-accountsettings___en"
  }, {
    path: "/en/talent/answerScore",
    component: _bfb86d9a,
    name: "talent-answerScore___en"
  }, {
    path: "/en/talent/contactGritify",
    component: _0f1c62b7,
    name: "talent-contactGritify___en"
  }, {
    path: "/en/talent/createProfile",
    component: _7ff19e8c,
    name: "talent-createProfile___en"
  }, {
    path: "/en/talent/declineJobOffer",
    component: _3ec92518,
    name: "talent-declineJobOffer___en"
  }, {
    path: "/en/talent/editBlacklist",
    component: _544ff4b2,
    name: "talent-editBlacklist___en"
  }, {
    path: "/en/talent/editContactInfo",
    component: _2d307cc3,
    name: "talent-editContactInfo___en"
  }, {
    path: "/en/talent/editName",
    component: _6a988606,
    name: "talent-editName___en"
  }, {
    path: "/en/talent/editNotificationSettings",
    component: _7afa2bae,
    name: "talent-editNotificationSettings___en"
  }, {
    path: "/en/talent/editStatus",
    component: _be4079a6,
    name: "talent-editStatus___en"
  }, {
    path: "/en/talent/indexOld",
    component: _35daa246,
    name: "talent-indexOld___en"
  }, {
    path: "/en/talent/inviteFriends",
    component: _2d58a04b,
    name: "talent-inviteFriends___en"
  }, {
    path: "/en/talent/inviteFriendsCmp",
    component: _43af0a2b,
    name: "talent-inviteFriendsCmp___en"
  }, {
    path: "/en/talent/jobOffer",
    component: _d8abd420,
    name: "talent-jobOffer___en"
  }, {
    path: "/en/talent/myExperience",
    component: _10e41dca,
    name: "talent-myExperience___en"
  }, {
    path: "/en/talent/myPreferences",
    component: _3a4400aa,
    name: "talent-myPreferences___en"
  }, {
    path: "/en/talent/pool",
    component: _565b4075,
    name: "talent-pool___en"
  }, {
    path: "/en/talent/process",
    component: _0d61fc94,
    name: "talent-process___en"
  }, {
    path: "/en/talent/requests",
    component: _679a9766,
    name: "talent-requests___en"
  }, {
    path: "/en/talentStart/createProfileStart",
    component: _b7c05554,
    name: "talentStart-createProfileStart___en"
  }, {
    path: "/sv/blog/kompetensbaserad-rekrytering",
    component: _73d42daa,
    name: "blog-kompetensbaserad-rekrytering___sv"
  }, {
    path: "/sv/blog/lyckat-samarbete-mellan-gritify-och-dagens-industri",
    component: _0128e36a,
    name: "blog-lyckat-samarbete-mellan-gritify-och-dagens-industri___sv"
  }, {
    path: "/sv/blog/rekrytera-fordomsfritt",
    component: _30f337b8,
    name: "blog-rekrytera-fordomsfritt___sv"
  }, {
    path: "/sv/blog/rekrytera-utvecklare",
    component: _028a431a,
    name: "blog-rekrytera-utvecklare___sv"
  }, {
    path: "/sv/blog/skriva-jobbannons",
    component: _06745818,
    name: "blog-skriva-jobbannons___sv"
  }, {
    path: "/sv/blog/vad-ar-employer-branding",
    component: _4c76c52c,
    name: "blog-vad-ar-employer-branding___sv"
  }, {
    path: "/sv/blog/vad-kostar-en-rekrytering",
    component: _7dd54d28,
    name: "blog-vad-kostar-en-rekrytering___sv"
  }, {
    path: "/sv/companyStart/applyforcompanyaccount",
    component: _0f2441b5,
    name: "companyStart-applyforcompanyaccount___sv"
  }, {
    path: "/sv/companyStart/createAccount",
    component: _1eebcf6b,
    name: "companyStart-createAccount___sv"
  }, {
    path: "/sv/companyStart/directaccess",
    component: _36aa5f57,
    name: "companyStart-directaccess___sv"
  }, {
    path: "/sv/companyStart/fordomsfrihet",
    component: _2b96318c,
    name: "companyStart-fordomsfrihet___sv"
  }, {
    path: "/sv/companyStart/friends",
    component: _4658748a,
    name: "companyStart-friends___sv"
  }, {
    path: "/sv/companyStart/passiveCandidates",
    component: _f5199906,
    name: "companyStart-passiveCandidates___sv"
  }, {
    path: "/sv/companyStart/pricing",
    component: _342ec928,
    name: "companyStart-pricing___sv"
  }, {
    path: "/sv/talent/accountsettings",
    component: _7ab8e213,
    name: "talent-accountsettings___sv"
  }, {
    path: "/sv/talent/answerScore",
    component: _bfb86d9a,
    name: "talent-answerScore___sv"
  }, {
    path: "/sv/talent/contactGritify",
    component: _0f1c62b7,
    name: "talent-contactGritify___sv"
  }, {
    path: "/sv/talent/createProfile",
    component: _7ff19e8c,
    name: "talent-createProfile___sv"
  }, {
    path: "/sv/talent/declineJobOffer",
    component: _3ec92518,
    name: "talent-declineJobOffer___sv"
  }, {
    path: "/sv/talent/editBlacklist",
    component: _544ff4b2,
    name: "talent-editBlacklist___sv"
  }, {
    path: "/sv/talent/editContactInfo",
    component: _2d307cc3,
    name: "talent-editContactInfo___sv"
  }, {
    path: "/sv/talent/editName",
    component: _6a988606,
    name: "talent-editName___sv"
  }, {
    path: "/sv/talent/editNotificationSettings",
    component: _7afa2bae,
    name: "talent-editNotificationSettings___sv"
  }, {
    path: "/sv/talent/editStatus",
    component: _be4079a6,
    name: "talent-editStatus___sv"
  }, {
    path: "/sv/talent/indexOld",
    component: _35daa246,
    name: "talent-indexOld___sv"
  }, {
    path: "/sv/talent/inviteFriends",
    component: _2d58a04b,
    name: "talent-inviteFriends___sv"
  }, {
    path: "/sv/talent/inviteFriendsCmp",
    component: _43af0a2b,
    name: "talent-inviteFriendsCmp___sv"
  }, {
    path: "/sv/talent/jobOffer",
    component: _d8abd420,
    name: "talent-jobOffer___sv"
  }, {
    path: "/sv/talent/myExperience",
    component: _10e41dca,
    name: "talent-myExperience___sv"
  }, {
    path: "/sv/talent/myPreferences",
    component: _3a4400aa,
    name: "talent-myPreferences___sv"
  }, {
    path: "/sv/talent/pool",
    component: _565b4075,
    name: "talent-pool___sv"
  }, {
    path: "/sv/talent/process",
    component: _0d61fc94,
    name: "talent-process___sv"
  }, {
    path: "/sv/talent/requests",
    component: _679a9766,
    name: "talent-requests___sv"
  }, {
    path: "/sv/talentStart/createProfileStart",
    component: _b7c05554,
    name: "talentStart-createProfileStart___sv"
  }, {
    path: "/talent/accountsettings/language",
    component: _78d6e4b2,
    name: "talent-accountsettings-language___sv___default"
  }, {
    path: "/talent/myExperience/educations",
    component: _5ddfca5c,
    name: "talent-myExperience-educations___sv___default"
  }, {
    path: "/talent/myExperience/employments",
    component: _10067710,
    name: "talent-myExperience-employments___sv___default"
  }, {
    path: "/talent/myExperience/tasks",
    component: _338385d7,
    name: "talent-myExperience-tasks___sv___default"
  }, {
    path: "/talent/process/history",
    component: _40b124d6,
    name: "talent-process-history___sv___default"
  }, {
    path: "/talent/requests/history",
    component: _e39a5b62,
    name: "talent-requests-history___sv___default"
  }, {
    path: "/en/companyStart/createAccount/verify",
    component: _571349b0,
    name: "companyStart-createAccount-verify___en"
  }, {
    path: "/en/companyStart/talentBarometer/H12024",
    component: _58398760,
    name: "companyStart-talentBarometer-H12024___en"
  }, {
    path: "/en/companyStart/talentBarometer/Q12024",
    component: _6a783667,
    name: "companyStart-talentBarometer-Q12024___en"
  }, {
    path: "/en/companyStart/talentBarometer/register",
    component: _774d64ca,
    name: "companyStart-talentBarometer-register___en"
  }, {
    path: "/en/talent/accountsettings/language",
    component: _78d6e4b2,
    name: "talent-accountsettings-language___en"
  }, {
    path: "/en/talent/myExperience/educations",
    component: _5ddfca5c,
    name: "talent-myExperience-educations___en"
  }, {
    path: "/en/talent/myExperience/employments",
    component: _10067710,
    name: "talent-myExperience-employments___en"
  }, {
    path: "/en/talent/myExperience/tasks",
    component: _338385d7,
    name: "talent-myExperience-tasks___en"
  }, {
    path: "/en/talent/process/history",
    component: _40b124d6,
    name: "talent-process-history___en"
  }, {
    path: "/en/talent/requests/history",
    component: _e39a5b62,
    name: "talent-requests-history___en"
  }, {
    path: "/sv/companyStart/createAccount/verify",
    component: _571349b0,
    name: "companyStart-createAccount-verify___sv"
  }, {
    path: "/sv/companyStart/talentBarometer/H12024",
    component: _58398760,
    name: "companyStart-talentBarometer-H12024___sv"
  }, {
    path: "/sv/companyStart/talentBarometer/Q12024",
    component: _6a783667,
    name: "companyStart-talentBarometer-Q12024___sv"
  }, {
    path: "/sv/companyStart/talentBarometer/register",
    component: _774d64ca,
    name: "companyStart-talentBarometer-register___sv"
  }, {
    path: "/sv/talent/accountsettings/language",
    component: _78d6e4b2,
    name: "talent-accountsettings-language___sv"
  }, {
    path: "/sv/talent/myExperience/educations",
    component: _5ddfca5c,
    name: "talent-myExperience-educations___sv"
  }, {
    path: "/sv/talent/myExperience/employments",
    component: _10067710,
    name: "talent-myExperience-employments___sv"
  }, {
    path: "/sv/talent/myExperience/tasks",
    component: _338385d7,
    name: "talent-myExperience-tasks___sv"
  }, {
    path: "/sv/talent/process/history",
    component: _40b124d6,
    name: "talent-process-history___sv"
  }, {
    path: "/sv/talent/requests/history",
    component: _e39a5b62,
    name: "talent-requests-history___sv"
  }, {
    path: "/en/talent/contactRequest/:recruitmentId?",
    component: _746e3638,
    name: "talent-contactRequest-recruitmentId___en"
  }, {
    path: "/en/talent/declineContactRequest/:recruitmentId?",
    component: _234752bc,
    name: "talent-declineContactRequest-recruitmentId___en"
  }, {
    path: "/en/talent/pool/:companyId",
    component: _b8f2d834,
    name: "talent-pool-companyId___en"
  }, {
    path: "/en/talent/process/:processId",
    component: _2ee18ec9,
    name: "talent-process-processId___en"
  }, {
    path: "/en/talent/requests/:recruitmentId",
    component: _3485a9f5,
    name: "talent-requests-recruitmentId___en"
  }, {
    path: "/sv/talent/contactRequest/:recruitmentId?",
    component: _746e3638,
    name: "talent-contactRequest-recruitmentId___sv"
  }, {
    path: "/sv/talent/declineContactRequest/:recruitmentId?",
    component: _234752bc,
    name: "talent-declineContactRequest-recruitmentId___sv"
  }, {
    path: "/sv/talent/pool/:companyId",
    component: _b8f2d834,
    name: "talent-pool-companyId___sv"
  }, {
    path: "/sv/talent/process/:processId",
    component: _2ee18ec9,
    name: "talent-process-processId___sv"
  }, {
    path: "/sv/talent/requests/:recruitmentId",
    component: _3485a9f5,
    name: "talent-requests-recruitmentId___sv"
  }, {
    path: "/en/talent/process/:processId/company",
    component: _65678d98,
    name: "talent-process-processId-company___en"
  }, {
    path: "/en/talent/requests/:recruitmentId/company",
    component: _0b538d40,
    name: "talent-requests-recruitmentId-company___en"
  }, {
    path: "/sv/talent/process/:processId/company",
    component: _65678d98,
    name: "talent-process-processId-company___sv"
  }, {
    path: "/sv/talent/requests/:recruitmentId/company",
    component: _0b538d40,
    name: "talent-requests-recruitmentId-company___sv"
  }, {
    path: "/en/company/:companyId",
    component: _04b81a41,
    name: "company-companyId___en"
  }, {
    path: "/en/inviteFriend/:inviterId?",
    component: _6dfd8b1e,
    name: "inviteFriend-inviterId___en"
  }, {
    path: "/en/jobs/:externalFormId?",
    component: _8d212fc4,
    name: "jobs-externalFormId___en"
  }, {
    path: "/en/rec/:smsToken?",
    component: _195c9094,
    name: "rec-smsToken___en"
  }, {
    path: "/sv/company/:companyId",
    component: _04b81a41,
    name: "company-companyId___sv"
  }, {
    path: "/sv/inviteFriend/:inviterId?",
    component: _6dfd8b1e,
    name: "inviteFriend-inviterId___sv"
  }, {
    path: "/sv/jobs/:externalFormId?",
    component: _8d212fc4,
    name: "jobs-externalFormId___sv"
  }, {
    path: "/sv/rec/:smsToken?",
    component: _195c9094,
    name: "rec-smsToken___sv"
  }, {
    path: "/talent/contactRequest/:recruitmentId?",
    component: _746e3638,
    name: "talent-contactRequest-recruitmentId___sv___default"
  }, {
    path: "/talent/declineContactRequest/:recruitmentId?",
    component: _234752bc,
    name: "talent-declineContactRequest-recruitmentId___sv___default"
  }, {
    path: "/talent/pool/:companyId",
    component: _b8f2d834,
    name: "talent-pool-companyId___sv___default"
  }, {
    path: "/talent/process/:processId",
    component: _2ee18ec9,
    name: "talent-process-processId___sv___default"
  }, {
    path: "/talent/requests/:recruitmentId",
    component: _3485a9f5,
    name: "talent-requests-recruitmentId___sv___default"
  }, {
    path: "/en/company/:companyId?/account",
    component: _4cc88748,
    name: "company-companyId-account___en"
  }, {
    path: "/en/company/:companyId?/companyComponents",
    component: _64cadc3c,
    name: "company-companyId-companyComponents___en"
  }, {
    path: "/en/company/:companyId?/companyProfile",
    component: _3391d50d,
    name: "company-companyId-companyProfile___en"
  }, {
    path: "/en/company/:companyId?/coworkers",
    component: _22a6d938,
    name: "company-companyId-coworkers___en",
    children: [{
      path: ":coworkerId?",
      component: _8ff18488,
      name: "company-companyId-coworkers-coworkerId___en"
    }]
  }, {
    path: "/en/company/:companyId?/notifications",
    component: _4a747752,
    name: "company-companyId-notifications___en"
  }, {
    path: "/en/company/:companyId?/offices",
    component: _60e63666,
    name: "company-companyId-offices___en",
    children: [{
      path: ":officeId?",
      component: _75c0b440,
      name: "company-companyId-offices-officeId___en"
    }]
  }, {
    path: "/en/company/:companyId?/recruitmentComponents",
    component: _476e857e,
    name: "company-companyId-recruitmentComponents___en"
  }, {
    path: "/en/company/:companyId?/recruitments",
    component: _070af06c,
    name: "company-companyId-recruitments___en"
  }, {
    path: "/en/company/:companyId?/settings",
    component: _3b480a04,
    children: [{
      path: "",
      component: _714fbdfe,
      name: "company-companyId-settings___en"
    }, {
      path: "agreement",
      component: _22b6a136,
      name: "company-companyId-settings-agreement___en"
    }, {
      path: "betaFeatures",
      component: _44fc2851,
      name: "company-companyId-settings-betaFeatures___en"
    }, {
      path: "blockedCompanies",
      component: _688bc75a,
      name: "company-companyId-settings-blockedCompanies___en"
    }, {
      path: "gdprSettings",
      component: _78f1d066,
      name: "company-companyId-settings-gdprSettings___en"
    }, {
      path: "integrationSettings",
      component: _752b19c3,
      name: "company-companyId-settings-integrationSettings___en"
    }, {
      path: "mediaSettings",
      component: _77e30a5a,
      name: "company-companyId-settings-mediaSettings___en"
    }, {
      path: "recruitmentSettings",
      component: _8e49f4ea,
      name: "company-companyId-settings-recruitmentSettings___en"
    }, {
      path: "users",
      component: _928ccf98,
      name: "company-companyId-settings-users___en"
    }]
  }, {
    path: "/en/company/:companyId?/talentPool",
    component: _3ebf9c49,
    name: "company-companyId-talentPool___en"
  }, {
    path: "/en/company/:companyId?/teams",
    component: _2b6be925,
    name: "company-companyId-teams___en",
    children: [{
      path: ":teamId?",
      component: _1f30e122,
      name: "company-companyId-teams-teamId___en"
    }]
  }, {
    path: "/sv/company/:companyId?/account",
    component: _4cc88748,
    name: "company-companyId-account___sv"
  }, {
    path: "/sv/company/:companyId?/companyComponents",
    component: _64cadc3c,
    name: "company-companyId-companyComponents___sv"
  }, {
    path: "/sv/company/:companyId?/companyProfile",
    component: _3391d50d,
    name: "company-companyId-companyProfile___sv"
  }, {
    path: "/sv/company/:companyId?/coworkers",
    component: _22a6d938,
    name: "company-companyId-coworkers___sv",
    children: [{
      path: ":coworkerId?",
      component: _8ff18488,
      name: "company-companyId-coworkers-coworkerId___sv"
    }]
  }, {
    path: "/sv/company/:companyId?/notifications",
    component: _4a747752,
    name: "company-companyId-notifications___sv"
  }, {
    path: "/sv/company/:companyId?/offices",
    component: _60e63666,
    name: "company-companyId-offices___sv",
    children: [{
      path: ":officeId?",
      component: _75c0b440,
      name: "company-companyId-offices-officeId___sv"
    }]
  }, {
    path: "/sv/company/:companyId?/recruitmentComponents",
    component: _476e857e,
    name: "company-companyId-recruitmentComponents___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments",
    component: _070af06c,
    name: "company-companyId-recruitments___sv"
  }, {
    path: "/sv/company/:companyId?/settings",
    component: _3b480a04,
    children: [{
      path: "",
      component: _714fbdfe,
      name: "company-companyId-settings___sv"
    }, {
      path: "agreement",
      component: _22b6a136,
      name: "company-companyId-settings-agreement___sv"
    }, {
      path: "betaFeatures",
      component: _44fc2851,
      name: "company-companyId-settings-betaFeatures___sv"
    }, {
      path: "blockedCompanies",
      component: _688bc75a,
      name: "company-companyId-settings-blockedCompanies___sv"
    }, {
      path: "gdprSettings",
      component: _78f1d066,
      name: "company-companyId-settings-gdprSettings___sv"
    }, {
      path: "integrationSettings",
      component: _752b19c3,
      name: "company-companyId-settings-integrationSettings___sv"
    }, {
      path: "mediaSettings",
      component: _77e30a5a,
      name: "company-companyId-settings-mediaSettings___sv"
    }, {
      path: "recruitmentSettings",
      component: _8e49f4ea,
      name: "company-companyId-settings-recruitmentSettings___sv"
    }, {
      path: "users",
      component: _928ccf98,
      name: "company-companyId-settings-users___sv"
    }]
  }, {
    path: "/sv/company/:companyId?/talentPool",
    component: _3ebf9c49,
    name: "company-companyId-talentPool___sv"
  }, {
    path: "/sv/company/:companyId?/teams",
    component: _2b6be925,
    name: "company-companyId-teams___sv",
    children: [{
      path: ":teamId?",
      component: _1f30e122,
      name: "company-companyId-teams-teamId___sv"
    }]
  }, {
    path: "/talent/process/:processId/company",
    component: _65678d98,
    name: "talent-process-processId-company___sv___default"
  }, {
    path: "/talent/requests/:recruitmentId/company",
    component: _0b538d40,
    name: "talent-requests-recruitmentId-company___sv___default"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId",
    component: _5bb8e372,
    name: "company-companyId-recruitments-recruitmentId___en"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId",
    component: _5bb8e372,
    name: "company-companyId-recruitments-recruitmentId___sv"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/admin",
    component: _e5af9122,
    name: "company-companyId-recruitments-recruitmentId-admin___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/communication",
    component: _ae303f14,
    name: "company-companyId-recruitments-recruitmentId-communication___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/compare",
    component: _275a9405,
    name: "company-companyId-recruitments-recruitmentId-compare___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/editVisual",
    component: _4275f90c,
    name: "company-companyId-recruitments-recruitmentId-editVisual___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/externalTalents",
    component: _305f5ab4,
    name: "company-companyId-recruitments-recruitmentId-externalTalents___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/settings",
    component: _4086319a,
    name: "company-companyId-recruitments-recruitmentId-settings___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/sourcing",
    component: _0f1f164c,
    name: "company-companyId-recruitments-recruitmentId-sourcing___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/sourcingRobotBeta",
    component: _5ad2f6e2,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotBeta___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/sourcingRobotTest",
    component: _b001f6f8,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotTest___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/sourcingRobotTest3",
    component: _58074cdf,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotTest3___en"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/admin",
    component: _e5af9122,
    name: "company-companyId-recruitments-recruitmentId-admin___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/communication",
    component: _ae303f14,
    name: "company-companyId-recruitments-recruitmentId-communication___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/compare",
    component: _275a9405,
    name: "company-companyId-recruitments-recruitmentId-compare___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/editVisual",
    component: _4275f90c,
    name: "company-companyId-recruitments-recruitmentId-editVisual___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/externalTalents",
    component: _305f5ab4,
    name: "company-companyId-recruitments-recruitmentId-externalTalents___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/settings",
    component: _4086319a,
    name: "company-companyId-recruitments-recruitmentId-settings___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/sourcing",
    component: _0f1f164c,
    name: "company-companyId-recruitments-recruitmentId-sourcing___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/sourcingRobotBeta",
    component: _5ad2f6e2,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotBeta___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/sourcingRobotTest",
    component: _b001f6f8,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotTest___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/sourcingRobotTest3",
    component: _58074cdf,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotTest3___sv"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/candidate/:talentId?",
    component: _0b25329a,
    name: "company-companyId-recruitments-recruitmentId-candidate-talentId___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/externalTalents/:externalTalentId",
    component: _407bb276,
    name: "company-companyId-recruitments-recruitmentId-externalTalents-externalTalentId___en"
  }, {
    path: "/en/company/:companyId?/recruitments/:recruitmentId/sourcing/:sourcingId",
    component: _effa6a0c,
    name: "company-companyId-recruitments-recruitmentId-sourcing-sourcingId___en"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/candidate/:talentId?",
    component: _0b25329a,
    name: "company-companyId-recruitments-recruitmentId-candidate-talentId___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/externalTalents/:externalTalentId",
    component: _407bb276,
    name: "company-companyId-recruitments-recruitmentId-externalTalents-externalTalentId___sv"
  }, {
    path: "/sv/company/:companyId?/recruitments/:recruitmentId/sourcing/:sourcingId",
    component: _effa6a0c,
    name: "company-companyId-recruitments-recruitmentId-sourcing-sourcingId___sv"
  }, {
    path: "/company/:companyId",
    component: _04b81a41,
    name: "company-companyId___sv___default"
  }, {
    path: "/inviteFriend/:inviterId?",
    component: _6dfd8b1e,
    name: "inviteFriend-inviterId___sv___default"
  }, {
    path: "/jobs/:externalFormId?",
    component: _8d212fc4,
    name: "jobs-externalFormId___sv___default"
  }, {
    path: "/rec/:smsToken?",
    component: _195c9094,
    name: "rec-smsToken___sv___default"
  }, {
    path: "/company/:companyId?/account",
    component: _4cc88748,
    name: "company-companyId-account___sv___default"
  }, {
    path: "/company/:companyId?/companyComponents",
    component: _64cadc3c,
    name: "company-companyId-companyComponents___sv___default"
  }, {
    path: "/company/:companyId?/companyProfile",
    component: _3391d50d,
    name: "company-companyId-companyProfile___sv___default"
  }, {
    path: "/company/:companyId?/coworkers",
    component: _22a6d938,
    name: "company-companyId-coworkers___sv___default",
    children: [{
      path: ":coworkerId?",
      component: _8ff18488,
      name: "company-companyId-coworkers-coworkerId___sv___default"
    }]
  }, {
    path: "/company/:companyId?/notifications",
    component: _4a747752,
    name: "company-companyId-notifications___sv___default"
  }, {
    path: "/company/:companyId?/offices",
    component: _60e63666,
    name: "company-companyId-offices___sv___default",
    children: [{
      path: ":officeId?",
      component: _75c0b440,
      name: "company-companyId-offices-officeId___sv___default"
    }]
  }, {
    path: "/company/:companyId?/recruitmentComponents",
    component: _476e857e,
    name: "company-companyId-recruitmentComponents___sv___default"
  }, {
    path: "/company/:companyId?/recruitments",
    component: _070af06c,
    name: "company-companyId-recruitments___sv___default"
  }, {
    path: "/company/:companyId?/settings",
    component: _3b480a04,
    children: [{
      path: "",
      component: _714fbdfe,
      name: "company-companyId-settings___sv___default"
    }, {
      path: "agreement",
      component: _22b6a136,
      name: "company-companyId-settings-agreement___sv___default"
    }, {
      path: "betaFeatures",
      component: _44fc2851,
      name: "company-companyId-settings-betaFeatures___sv___default"
    }, {
      path: "blockedCompanies",
      component: _688bc75a,
      name: "company-companyId-settings-blockedCompanies___sv___default"
    }, {
      path: "gdprSettings",
      component: _78f1d066,
      name: "company-companyId-settings-gdprSettings___sv___default"
    }, {
      path: "integrationSettings",
      component: _752b19c3,
      name: "company-companyId-settings-integrationSettings___sv___default"
    }, {
      path: "mediaSettings",
      component: _77e30a5a,
      name: "company-companyId-settings-mediaSettings___sv___default"
    }, {
      path: "recruitmentSettings",
      component: _8e49f4ea,
      name: "company-companyId-settings-recruitmentSettings___sv___default"
    }, {
      path: "users",
      component: _928ccf98,
      name: "company-companyId-settings-users___sv___default"
    }]
  }, {
    path: "/company/:companyId?/talentPool",
    component: _3ebf9c49,
    name: "company-companyId-talentPool___sv___default"
  }, {
    path: "/company/:companyId?/teams",
    component: _2b6be925,
    name: "company-companyId-teams___sv___default",
    children: [{
      path: ":teamId?",
      component: _1f30e122,
      name: "company-companyId-teams-teamId___sv___default"
    }]
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId",
    component: _5bb8e372,
    name: "company-companyId-recruitments-recruitmentId___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/admin",
    component: _e5af9122,
    name: "company-companyId-recruitments-recruitmentId-admin___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/communication",
    component: _ae303f14,
    name: "company-companyId-recruitments-recruitmentId-communication___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/compare",
    component: _275a9405,
    name: "company-companyId-recruitments-recruitmentId-compare___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/editVisual",
    component: _4275f90c,
    name: "company-companyId-recruitments-recruitmentId-editVisual___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/externalTalents",
    component: _305f5ab4,
    name: "company-companyId-recruitments-recruitmentId-externalTalents___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/settings",
    component: _4086319a,
    name: "company-companyId-recruitments-recruitmentId-settings___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/sourcing",
    component: _0f1f164c,
    name: "company-companyId-recruitments-recruitmentId-sourcing___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/sourcingRobotBeta",
    component: _5ad2f6e2,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotBeta___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/sourcingRobotTest",
    component: _b001f6f8,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotTest___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/sourcingRobotTest3",
    component: _58074cdf,
    name: "company-companyId-recruitments-recruitmentId-sourcingRobotTest3___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/candidate/:talentId?",
    component: _0b25329a,
    name: "company-companyId-recruitments-recruitmentId-candidate-talentId___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/externalTalents/:externalTalentId",
    component: _407bb276,
    name: "company-companyId-recruitments-recruitmentId-externalTalents-externalTalentId___sv___default"
  }, {
    path: "/company/:companyId?/recruitments/:recruitmentId/sourcing/:sourcingId",
    component: _effa6a0c,
    name: "company-companyId-recruitments-recruitmentId-sourcing-sourcingId___sv___default"
  }, {
    path: "/",
    component: _7af73c86,
    name: "index___sv___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
