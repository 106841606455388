import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import lookupDataService, {
  getSkillGroups,
  getTaskDetailsById,
  getTaskGroups,
  getBaseTaskRecommendations,
  GetBaseTaskRecommendationsParams,
  getCacheKeyForBaseTaskRecommendations,
  getTaskGroupGroups,
  getSkillGroupGroups,
} from "~/services/lookupDataService";
import { ListObject } from "~/models/ListObject";
import { TaskWithRole } from "~/models/TaskWithRole";
import {
  TalentLocation,
  GetRolesDto,
  EducationDomainListObject,
  BranchLookupDto,
  GetFriendInvitationDataDto,
  LocationDto,
  GetDeclineReasonsDto,
  RobotStepperModule,
  OpennessSettings,
  ThingToLoad,
  IndustryLookupDto,
  WorkTitleDto,
  GetCompanyDto,
  TaskDetailsDto,
  SkillGroupObject,
  TaskGroupObject,
  TaskGroupGroup,
  SkillGroupGroup,
} from "~/models/types";
// import { authStore } from "~/store";
import { profileLookupsStore } from "~/store";
import { RoleResponsibility } from "~/models/RoleResponsibility";
import TokenHelpers from "~/helpers/tokenHelpers";
import PublicApisService from "~/services/publicApisService";

// const apa: RobotStepperModule = {
//   moduleType: "SkillsPickMultipleInVisualRequiredV2",
//   hint: null,
//   moduleId: "skillGroupRequired4",
//   skillIdBySkillIdInVisualDict: {
//     "7269c4c1-6071-42be-89db-db0c7a094a12":
//       "57423819-bf62-4b13-bae8-4b9517674fed",
//   },
//   subTitleSingleSkill: {
//     sv: "Vilka av dessa kompetenser är ett krav?",
//     en: "Which of these skills are a requirement?",
//   },
//   subTitle: {
//     sv: "Är det ett krav att kandidaten har erfarenhet av att leda personal?",
//     en:
//       "Is it a requirement that the candidate has experience of leading staff?",
//   },
//   title: {
//     sv: "Personalansvar",
//     en: "Staff responsibility",
//   },
// };

const getRolesAugmented = (roles: GetRolesDto[]): GetRolesDto[] =>
  // TODO 2215 remove this after testing
  roles.map(x => ({
    ...x,
    // robotIsPublished: false,
    robotStepperModules: [
      ...(x.robotStepperModules ?? []),
      // ...[apa],
      // {
      //   moduleType: "StaffResponsibility",
      //   hint: {
      //     accentText: {
      //       sv: "Exercitation sunt labore anim dolore excepteur ipsum irure.",
      //       en:
      //         "Or is it important that the candidate has experience of leading staff?",
      //     },
      //     mainText: {
      //       sv:
      //         "Pariatur quis elit fugiat elit incididunt voluptate adipisicing aute et deserunt.",
      //       en: " Hejsan på dejsan",
      //     },
      //   },
      //   moduleId: "staffResponsibilityTest1",
      //   subTitle: {
      //     sv: "Är det ett krav att man ska ha erfarenhet av att leda personal?",
      //     en: "Is it a requirement to have experience of leading staff?",
      //   },
      //   title: {
      //     sv: "Personalansvar",
      //     en: "Staff responsibility",
      //   },
      //   buttonOrderIsReversed: false,
      //   isDepricated: false,
      //   staffIsRequiredText: {
      //     sv: "Ja, Superviktigt",
      //     en: "Yes, Important",
      //   },
      //   staffIsNotRequiredText: {
      //     sv: "Nej det är inte viktigt",
      //     en: "No, it is not important",
      //   },
      // },
      // ...(x.robotStepperModules ?? []).map(m => {
      //   if (m.moduleType === "RoleSpecificity") {
      //     const module: RobotStepperModule = {
      //       ...m,
      //       moduleType: "RoleSpecificity",
      //       hint: {
      //         accentText: {
      //           sv:
      //             "Eller är du även intresserad av relevanta kandidater från angränsande roller som exempelvis Managementkonsult m.fl.?",
      //           en:
      //             "Or are you also interested in relevant candidates from adjacent roles such as Management Consulting, and others??",
      //         },
      //         mainText: {
      //           sv: "asdsa",
      //           en: "asda",
      //         },
      //       },
      //       roleIsSpecificText: {
      //         sv: "Superviktigt",
      //         en: "Yes",
      //       },
      //       roleIsNotSpecificText: {
      //         sv: "Jag kan tänka mig andra roller",
      //         en: "No",
      //       },
      //       roleIds: m.roleIds,
      //       title: {
      //         sv: "Nuvarande roll",
      //         en: "Current role",
      //       },
      //     };
      //     return module;
      //   }
      //   return m;
      // }),
      // ...(x.robotStepperModules ?? []).map(m => {
      //   if (m.moduleType === "SubRolePickMultiple") {
      //     return {
      //       ...m,
      //       oneIsRequired: true,
      //     };
      //   }
      //   return m;
      // }),
      // {
      //   moduleType: "RoleSpecificity",
      //   moduleId: "liiingon",
      //   roleIds: ["roll1", "roll2"],
      //   buttonOrderIsReversed: false,
      //   subTitle: {
      //     sv: "Är det viktigt att kandidaterna jobbar med Strategi & M&A idag?",
      //     en:
      //       "Is it important for candidates to currently work with Strategy & M&A?",
      //   },

      //   roleIsSpecificText: {
      //     sv: "Ja",
      //     en: "Yes",
      //   },
      //   roleIsNotSpecificText: {
      //     sv: "Nej",
      //     en: "No",
      //   },
      //   hint: {
      //     accentText: {
      //       sv:
      //         "Eller är du även intresserad av relevanta kandidater från angränsande roller som exempelvis Managementkonsult m.fl.?",
      //       en:
      //         "Or are you also interested in relevant candidates from adjacent roles such as Management Consulting, and others??",
      //     },
      //     mainText: {
      //       sv: "",
      //       en: "",
      //     },
      //   },
      //   title: {
      //     sv: "Nuvarande roll",
      //     en: "Current role",
      //   },
      // },
      // {
      //   moduleType: "RoleSpecificity",
      //   moduleId: "liiingon2",
      //   roleIds: ["roll1", "roll2"],
      //   buttonOrderIsReversed: true,
      //   subTitle: {
      //     sv:
      //       "Hej igen! Är det viktigt att kandidaterna jobbar med Strategi & M&A idag?",
      //     en:
      //       "Hej igen! Är det viktigt att kandidaterna jobbar med Strategi & M&A idag?",
      //   },

      //   roleIsSpecificText: {
      //     sv: "Superviktigt",
      //     en: "Superviktigt",
      //   },
      //   roleIsNotSpecificText: {
      //     sv: "Jag kan tänka mig andra roller",
      //     en: "Jag kan tänka mig andra roller",
      //   },
      //   hint: {
      //     accentText: {
      //       sv:
      //         "Eller är du även intresserad av relevanta kandidater från angränsande roller som exempelvis Managementkonsult m.fl.?",
      //       en:
      //         "Eller är du även intresserad av relevanta kandidater från angränsande ",
      //     },
      //     mainText: {
      //       sv: "",
      //       en: "roller som exempelvis Managementkonsult m.fl.?",
      //     },
      //   },
      //   title: {
      //     sv: "Nuvarande roll",
      //     en: "Current role",
      //   },
      // },
    ],
  }));

@Module({
  name: "profileLookups",
  stateFactory: true,
  namespaced: true,
})
export default class ProfileLookupsStore extends VuexModule {
  workBranches: BranchLookupDto[] = [];
  allIndustries: IndustryLookupDto[] = [];
  rolesAll: GetRolesDto[] = [];

  get allModules(): RobotStepperModule[] {
    return profileLookupsStore.rolesAll.flatMap(
      x => x.robotStepperModules ?? []
    );
  }

  get roles(): (GetRolesDto & { jobCategory: string })[] {
    return this.rolesAll.filter(ProfileLookupsStore.getIsBaseRole);
  }

  static getIsBaseRole(
    v: GetRolesDto
  ): v is GetRolesDto & { jobCategory: string } {
    return v.jobCategory !== null;
  }

  roleGroupSorting: string[] = [];
  cities: TalentLocation[] = [];
  languages: ListObject[] = [];
  educationDomains: EducationDomainListObject[] = [];
  tasks: TaskWithRole[] = [];
  lookupsAreLoaded: string | null = null;
  publiclookupsAreLoaded = false;
  friendInvitationData: GetFriendInvitationDataDto | null = null;

  companiesById: Record<string, ThingToLoad<GetCompanyDto>> = {};

  taskDetailsById: Record<string, ThingToLoad<TaskDetailsDto>> = {};

  @Mutation
  SET_TASK_DETAILS_BY_ID(v: {
    id: string;
    value: ThingToLoad<TaskDetailsDto>;
  }) {
    this.taskDetailsById = {
      ...this.taskDetailsById,
      [v.id]: v.value,
    };
  }

  @Action
  async loadTaskDetailsById(v: { taskId: string; lang: string }) {
    if (!this.taskDetailsById[v.taskId]) {
      profileLookupsStore.SET_TASK_DETAILS_BY_ID({
        id: v.taskId,
        value: {
          type: "loading",
        },
      });

      const token = await TokenHelpers.getToken();
      const task = await getTaskDetailsById({
        lang: v.lang,
        token,
        taskId: v.taskId,
      });

      profileLookupsStore.SET_TASK_DETAILS_BY_ID({
        id: v.taskId,
        value: {
          type: "loaded",
          value: task,
        },
      });
    }
  }

  baseTaskRecommendations: Record<string, ThingToLoad<string[]>> = {};

  @Mutation
  SET_BASE_TASK_RECOMMENDATIONS(v: {
    key: string;
    value: ThingToLoad<string[]>;
  }) {
    this.baseTaskRecommendations = {
      ...this.baseTaskRecommendations,
      [v.key]: v.value,
    };
  }

  @Action
  async loadBaseTaskRecommendations(params: GetBaseTaskRecommendationsParams) {
    const cacheKey = getCacheKeyForBaseTaskRecommendations(params);
    if (!this.baseTaskRecommendations[cacheKey]) {
      profileLookupsStore.SET_BASE_TASK_RECOMMENDATIONS({
        key: cacheKey,
        value: {
          type: "loading",
        },
      });

      const token = await TokenHelpers.getToken();
      const task = await getBaseTaskRecommendations({
        token,
        params,
      });

      profileLookupsStore.SET_BASE_TASK_RECOMMENDATIONS({
        key: cacheKey,
        value: {
          type: "loaded",
          value: task,
        },
      });
    }
  }

  @Mutation
  SET_COMPANY_BY_ID(v: { id: string; value: ThingToLoad<GetCompanyDto> }) {
    this.companiesById = {
      ...this.companiesById,
      [v.id]: v.value,
    };
  }

  @Action
  async loadCompanyById(v: { companyId: string }) {
    if (!this.companiesById[v.companyId]) {
      profileLookupsStore.SET_COMPANY_BY_ID({
        id: v.companyId,
        value: {
          type: "loading",
        },
      });

      const token = await TokenHelpers.getToken();
      const company = await PublicApisService.getCompanyById({
        lang: "en",
        token,
        comapnyId: v.companyId,
      });

      if (!company) {
        profileLookupsStore.SET_COMPANY_BY_ID({
          id: v.companyId,
          value: {
            type: "notFetched",
          },
        });

        return;
      }

      profileLookupsStore.SET_COMPANY_BY_ID({
        id: v.companyId,
        value: {
          type: "loaded",
          value: company,
        },
      });
    }
  }

  skillGroups: ThingToLoad<SkillGroupObject[]> = {
    type: "notFetched",
  };

  @Mutation
  SET_SKILL_GROUPS(v: ThingToLoad<SkillGroupObject[]>) {
    this.skillGroups = v;
  }

  @Action
  async loadSkillGroups() {
    if (this.skillGroups.type !== "notFetched") {
      return;
    }

    profileLookupsStore.SET_SKILL_GROUPS({
      type: "loading",
    });

    const token = await TokenHelpers.getToken();
    const result = await getSkillGroups({
      token,
    });

    profileLookupsStore.SET_SKILL_GROUPS({
      type: "loaded",
      value: result,
    });
  }

  taskGroups: ThingToLoad<TaskGroupObject[]> = {
    type: "notFetched",
  };

  @Mutation
  SET_TASK_GROUPS(v: ThingToLoad<TaskGroupObject[]>) {
    this.taskGroups = v;
  }

  @Action
  async loadTaskGroups() {
    if (this.taskGroups.type !== "notFetched") {
      return;
    }

    profileLookupsStore.SET_TASK_GROUPS({
      type: "loading",
    });

    const token = await TokenHelpers.getToken();
    const result = await getTaskGroups({
      token,
    });

    profileLookupsStore.SET_TASK_GROUPS({
      type: "loaded",
      value: result,
    });
  }

  taskGroupGroups: ThingToLoad<TaskGroupGroup[]> = {
    type: "notFetched",
  };

  @Mutation
  SET_TASK_GROUP_GROUPS(v: ThingToLoad<TaskGroupGroup[]>) {
    this.taskGroupGroups = v;
  }

  @Action
  async loadTaskGroupGroups() {
    if (this.taskGroupGroups.type !== "notFetched") {
      return;
    }

    profileLookupsStore.SET_TASK_GROUP_GROUPS({
      type: "loading",
    });

    const token = await TokenHelpers.getToken();
    const result = await getTaskGroupGroups({
      token,
    });

    profileLookupsStore.SET_TASK_GROUP_GROUPS({
      type: "loaded",
      value: result,
    });
  }

  skillGroupGroups: ThingToLoad<SkillGroupGroup[]> = {
    type: "notFetched",
  };

  @Mutation
  SET_SKILL_GROUP_GROUPS(v: ThingToLoad<SkillGroupGroup[]>) {
    this.skillGroupGroups = v;
  }

  @Action
  async loadSkillGroupGroups() {
    if (this.skillGroupGroups.type !== "notFetched") {
      return;
    }

    profileLookupsStore.SET_SKILL_GROUP_GROUPS({
      type: "loading",
    });

    const token = await TokenHelpers.getToken();
    const result = await getSkillGroupGroups({
      token,
    });

    profileLookupsStore.SET_SKILL_GROUP_GROUPS({
      type: "loaded",
      value: result,
    });
  }

  @Mutation
  SET_FRIEND_INVITATION_DATA(v: GetFriendInvitationDataDto | null) {
    this.friendInvitationData = v;
  }

  @Mutation
  SET_TALENT_PROFILE_LOOKUPS(v: {
    cities: TalentLocation[];
    roles: GetRolesDto[];
    branches: BranchLookupDto[];
    languages: ListObject[];
    educationDomains: EducationDomainListObject[];
    tasks: TaskWithRole[];
    roleGroupSorting: string[];
    allIndustries: IndustryLookupDto[];
  }) {
    this.cities = v.cities;
    this.rolesAll = getRolesAugmented(v.roles);
    this.workBranches = v.branches;
    this.languages = v.languages;
    this.educationDomains = v.educationDomains;
    this.tasks = v.tasks;
    this.roleGroupSorting = v.roleGroupSorting;
    this.allIndustries = v.allIndustries;
  }

  @Mutation
  SET_PUBLIC_LOOKUPS({
    cities,
    roles,
    branches,
    rolesSorting,
    industries,
  }: any) {
    this.cities = cities;
    this.rolesAll = getRolesAugmented(roles);
    this.workBranches = branches;
    this.roleGroupSorting = rolesSorting;
    this.allIndustries = industries;
  }

  @Mutation
  SET_TASKS(v: TaskWithRole[]) {
    this.tasks = v;
  }

  @Mutation
  SET_LOOKUPS_LOADED(v: string | null) {
    this.lookupsAreLoaded = v;
  }

  @Mutation
  SET_PUBLIC_LOOKUPS_LOADED() {
    this.publiclookupsAreLoaded = true;
  }

  declineReasons: GetDeclineReasonsDto[] = [];

  @Mutation
  SET_DECLINE_REASONS(v: GetDeclineReasonsDto[]) {
    this.declineReasons = v;
  }

  loadedLang: string | null = null;
  @Mutation
  SET_LOADED_LANG(v: string) {
    this.loadedLang = v;
  }

  invitationDataIsLoaded = false;

  @Mutation
  SET_INVITATION_DATA_LOADED(v: boolean) {
    this.invitationDataIsLoaded = v;
  }

  opennessSettings: ThingToLoad<OpennessSettings | null> = {
    type: "notFetched",
  };

  @Mutation
  SET_OPENNESS_SETTINGS(v: ThingToLoad<OpennessSettings | null>) {
    this.opennessSettings = v;
  }

  @Action
  async loadOpennessSettings() {
    if (this.opennessSettings.type !== "notFetched") {
      return;
    }

    profileLookupsStore.SET_OPENNESS_SETTINGS({
      type: "loading",
    });

    const token = await TokenHelpers.getToken();
    const result = await lookupDataService.getOpennesSettings({
      token,
    });

    profileLookupsStore.SET_OPENNESS_SETTINGS({
      type: "loaded",
      value: result,
    });
  }

  @Action
  async loadInvitationData(v: {
    inviterId: string;
    recruitmentId: string | null;
  }) {
    if (!this.invitationDataIsLoaded) {
      const dto = await lookupDataService.GetFriendInvitationData({
        inviterId: v.inviterId,
        recruitmentId: v.recruitmentId,
      });

      profileLookupsStore.SET_FRIEND_INVITATION_DATA(dto);
      profileLookupsStore.SET_INVITATION_DATA_LOADED(true);
    }
  }

  @Action
  async loadPublicLookups(lang: string) {
    if (!this.publiclookupsAreLoaded || lang !== this.loadedLang) {
      profileLookupsStore.SET_LOADED_LANG(lang);
      const [cities, roles, branches, rolesSorting, industries]: [
        TalentLocation[],
        GetRolesDto[],
        BranchLookupDto[],
        string[],
        IndustryLookupDto[]
      ] = <any>(
        await Promise.all([
          lookupDataService.getCitiesPublic(lang) as Promise<TalentLocation[]>,
          lookupDataService.getRolesPublic(lang) as Promise<GetRolesDto[]>,
          lookupDataService.getBranchesPublic(lang) as Promise<ListObject[]>,
          lookupDataService.getRoleGroupSorting(lang) as Promise<string[]>,
          lookupDataService.getAllIndustriesPublic(lang) as Promise<
            IndustryLookupDto[]
          >,
        ])
      );

      profileLookupsStore.SET_PUBLIC_LOOKUPS_LOADED();

      const thingsToSet = {
        cities,
        roles,
        branches,
        rolesSorting,
        industries,
      };

      profileLookupsStore.SET_PUBLIC_LOOKUPS(thingsToSet);
    }
  }

  @Action
  async loadTasks(lang: string) {
    const tasks = await lookupDataService.getAllTasksPublic(lang);

    profileLookupsStore.SET_TASKS(tasks);
  }

  locationsForMatching: LocationDto[] = [];
  locationsForMatchingAreLoaded = false;

  @Mutation
  SET_LOCATIONS_FOR_MATCHING(v: LocationDto[]) {
    this.locationsForMatching = v;
  }

  @Mutation
  SET_LOCATIONS_FOR_MATCHING_ARE_LOADED(v: boolean) {
    this.locationsForMatchingAreLoaded = v;
  }

  @Action
  async loadLocationsForRecruitmentMatching() {
    if (!this.locationsForMatchingAreLoaded) {
      const token = await TokenHelpers.getToken();
      const result = await lookupDataService.getLocationsForRecruitmentMatching(
        {
          token,
        }
      );

      profileLookupsStore.SET_LOCATIONS_FOR_MATCHING(result);
      profileLookupsStore.SET_LOCATIONS_FOR_MATCHING_ARE_LOADED(true);
    }
  }

  @Action
  async loadLookupsForProfile(obj: { lang: string; taskLang?: string }) {
    if (this.lookupsAreLoaded !== obj.lang) {
      profileLookupsStore.SET_LOADED_LANG(obj.lang);
      const educationDomainsPromise: Promise<EducationDomainListObject[]> = lookupDataService.getEducationDomainsPublic(
        obj.lang
      );
      const citiesPromise: Promise<TalentLocation[]> = lookupDataService.getCitiesPublic(
        obj.lang
      );
      const rolesPromise: Promise<GetRolesDto[]> = lookupDataService.getRolesPublic(
        obj.lang
      );
      const roleGroupSortPromise: Promise<string[]> = lookupDataService.getRoleGroupSorting(
        obj.lang
      );
      const branchesPromise: Promise<BranchLookupDto[]> = lookupDataService.getBranchesPublic(
        obj.lang
      );
      const industriesPromise: Promise<IndustryLookupDto[]> = lookupDataService.getAllIndustriesPublic(
        obj.lang
      );
      const languagesPromise: Promise<ListObject[]> = lookupDataService.getLanguagesPublic(
        obj.lang
      );
      const tasksPromise: Promise<TaskWithRole[]> = lookupDataService.getAllTasksPublic(
        obj.taskLang || obj.lang
      );
      const declineReasonsPromise: Promise<GetDeclineReasonsDto[]> = lookupDataService.getDeclineReasonsPublic(
        obj.lang
      );

      const [
        cities,
        roles,
        branches,
        languages,
        educationDomains,
        tasks,
        declineReasons,
        roleGroupSorting,
        allIndustries,
      ] = await Promise.all([
        citiesPromise,
        rolesPromise,
        branchesPromise,
        languagesPromise,
        educationDomainsPromise,
        tasksPromise,
        declineReasonsPromise,
        roleGroupSortPromise,
        industriesPromise,
      ]);

      profileLookupsStore.SET_LOOKUPS_LOADED(obj.lang);

      profileLookupsStore.SET_DECLINE_REASONS(declineReasons);

      profileLookupsStore.SET_TALENT_PROFILE_LOOKUPS({
        cities: cities as TalentLocation[],
        roles: roles as GetRolesDto[],
        branches,
        languages,
        educationDomains: educationDomains as EducationDomainListObject[],
        tasks: tasks as TaskWithRole[],
        roleGroupSorting,
        allIndustries,
      });
    }
  }

  titlesByIds: Record<string, ThingToLoad<WorkTitleDto>> = {};

  @Mutation
  SET_TITLE_BY_ID(v: { id: string; value: ThingToLoad<WorkTitleDto> }) {
    this.titlesByIds = {
      ...this.titlesByIds,
      [v.id]: v.value,
    };
  }

  @Action
  async loadTitlesByIds(ids: string[]) {
    const idsToLoad = ids.filter(x => !this.titlesByIds[x]);

    idsToLoad.forEach(id => {
      profileLookupsStore.SET_TITLE_BY_ID({
        id,
        value: {
          type: "loading",
        },
      });
    });

    if (!idsToLoad.length) {
      return;
    }

    const token = await TokenHelpers.getToken();

    const titles = await lookupDataService.getTitlesByIds({
      accessToken: token,
      titleIds: idsToLoad,
    });

    titles.forEach(t => {
      profileLookupsStore.SET_TITLE_BY_ID({
        id: t.id!,
        value: {
          type: "loaded",
          value: t,
        },
      });
    });
  }

  get roleIds(): string[] {
    return this.rolesAll.map(r => r.id);
  }

  get branchIds(): string[] {
    return this.workBranches.map(r => r.id);
  }

  // get test(): {[key: string]: string} {
  //   return Object.keys(this.roleResponsibilityLevels).reduce((acc: {[key: string]: string}, x) => {
  //     return {
  //       ...acc,
  //       ...this.roleResponsibilityLevels[x].reduce((acc2: {[key: string]: string}, y ) => {
  //         return {...acc2, y.text}
  //       }, {})
  //     }

  //     return
  //   }, {});
  // }
  get roleResponsibilityLevelsBaseWithComboId(): {
    responsibility: RoleResponsibility;
    level: number;
    lower: number;
    upper: number | null;
    unit: string;
    comboId: number;
  }[] {
    return this.roleResponsibilityLevelsBase.map(r => {
      return {
        ...r,
        comboId: parseInt(`${r.responsibility}${r.level}`),
      };
    });
  }

  get tasksById(): Record<string, TaskWithRole> {
    return this.tasks.reduce((acc: Record<string, TaskWithRole>, t) => {
      acc[t.id] = t;

      return acc;
    }, {});
  }

  get taskNamesById(): Record<string, string> {
    return this.tasks.reduce((acc: Record<string, string>, t) => {
      acc[t.id] = t.text;

      return acc;
    }, {});
  }

  get roleResponsibilityLevelsBase(): {
    responsibility: RoleResponsibility;
    level: number;
    lower: number;
    upper: number | null;
    unit: string;
  }[] {
    return [
      {
        responsibility: RoleResponsibility.Staff,
        level: 0,
        lower: 1,
        upper: 5,
        unit: "st",
      },
      {
        responsibility: RoleResponsibility.Staff,
        level: 1,
        lower: 6,
        upper: 50,
        unit: "st",
      },
      {
        responsibility: RoleResponsibility.Staff,
        level: 2,
        lower: 51,
        upper: null,
        unit: "st",
      },
      {
        responsibility: RoleResponsibility.Sales,
        level: 0,
        lower: 0,
        upper: 2,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Sales,
        level: 1,
        lower: 3,
        upper: 15,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Sales,
        level: 2,
        lower: 16,
        upper: 100,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Sales,
        level: 3,
        lower: 101,
        upper: 500,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Sales,
        level: 4,
        lower: 501,
        upper: null,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Project,
        level: 0,
        lower: 0,
        upper: 5,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Project,
        level: 1,
        lower: 6,
        upper: 100,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Project,
        level: 2,
        lower: 101,
        upper: 500,
        unit: "Mkr",
      },
      {
        responsibility: RoleResponsibility.Project,
        level: 3,
        lower: 501,
        upper: null,
        unit: "Mkr",
      },
    ];
  }

  get domainsByGroupName(): { [key: string]: EducationDomainListObject[] } {
    return this.educationDomains.reduce(
      (acc: { [key: string]: EducationDomainListObject[] }, domain) => {
        const domainsForCategory = [...(acc[domain.category] || []), domain];

        acc[domain.category] = domainsForCategory;

        return acc;
      },
      {}
    );
  }
}
